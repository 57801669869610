import { fetchData } from '../../utils';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import useError from '../useError';

const useChangePassword = () => {
  const { showError } = useError();

  const oneUseToken = useRef(null);

  useEffect(() => {
    oneUseToken.current = null;
  }, []);

  const isValidToken = async () => {
    try {
      const urlParams = new URLSearchParams(
        decodeURIComponent(window.location.search),
      );

      const { token } = await fetchData(
        '/client/password/recovery/validate-code',
        {
          method: 'post',
          body: JSON.stringify({
            token: urlParams.get('token') || '',
            recoveryCode: urlParams.get('code') || '',
          }),
        },
      );
      oneUseToken.current = token;

      return true;
    } catch {
      return false;
    }
  };

  const recoverAccountChangingPassword = useCallback(
    async (body) => {
      try {
        await fetchData('/client/password/recovery/save-password', {
          method: 'post',
          body: JSON.stringify({
            token: oneUseToken.current,
            password: body.newPassword,
          }),
        });
      } catch (e) {
        showError();
        throw e;
      }
    },
    [showError],
  );

  const changePassword = useCallback(
    async ({ body }) => {
      try {
        const data = await fetchData('/client/password', {
          method: 'put',
          body: JSON.stringify(body),
        });

        return data;
      } catch (e) {
        showError();
        throw e;
      }
    },
    [showError],
  );

  return useMemo(() => {
    return {
      changePassword,
      isValidToken,
      recoverAccountChangingPassword,
    };
  }, [changePassword, isValidToken, recoverAccountChangingPassword]);
};

export default useChangePassword;
