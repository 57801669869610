import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ValidationError } from '../../utils';
import fetchData from '../../utils/fetchData';
import pMemoize from 'p-memoize';
import useError from '../useError';

const memoFetchData = pMemoize(fetchData, { maxAge: 1000 });

type Props = {
  children: ReactNode;
};

type SignDrAgreementContextType = {
  modalInfo: string | undefined;
  fetchSignDrAgreementModalInfo: () => void;
  fetchSignDrAgreement: () => Promise<void>;
};

const SignDrAgreementContext = createContext<SignDrAgreementContextType>(
  {} as SignDrAgreementContextType,
);

const useSignDrAgreement = (): SignDrAgreementContextType =>
  useContext(SignDrAgreementContext);

const SignDrAgreementProvider = ({ children }: Props): JSX.Element => {
  const [modalInfo, setModalInfo] = useState<string | undefined>();
  const { showError } = useError();

  const fetchSignDrAgreement = useCallback(async (): Promise<any> => {
    try {
      await memoFetchData('/client/loans/latest/debt-restructuring/signature', {
        method: 'POST',
      });
    } catch (e) {
      showError(e as ValidationError);
      throw e;
    }
  }, [showError]);

  const fetchSignDrAgreementModalInfo = useCallback(async (): Promise<any> => {
    try {
      const modalInfo = await fetchData(
        `/client/loans/latest/debt-restructuring/agreement/html`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'text/html',
            ...(sessionStorage.getItem('token') && {
              Authorization: `Basic ${sessionStorage.getItem('token')}`,
            }),
          },
        },
        false,
        true,
      );
      setModalInfo(modalInfo);
    } catch (e) {
      showError(e as ValidationError);
    }
  }, [showError]);

  const SignDrAgreementContextValue = useMemo(
    () => ({
      modalInfo,
      fetchSignDrAgreementModalInfo,
      fetchSignDrAgreement,
    }),
    [fetchSignDrAgreementModalInfo, fetchSignDrAgreement, modalInfo],
  );

  return (
    <SignDrAgreementContext.Provider value={SignDrAgreementContextValue}>
      {children}
    </SignDrAgreementContext.Provider>
  );
};

export { useSignDrAgreement as default, SignDrAgreementProvider };
