import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ValidationError } from '../../utils';
import fetchData from '../../utils/fetchData';
import useError from '../useError';

type LoanExtensionFee = {
  term: number;
  unit: string;
  fee: number;
  interest: number;
  feeWithoutInterest: number;
};

type Offer = {
  agreementEndDate: string;
  annualPercentageRate: number;
  capitalizableCommission: number;
  currentPrincipal: number;
  currentPrincipalWithInterest: number;
  currentPrincipalWithInterestAndInitialCommission: number;
  discountAmount: number;
  discountPercent: number;
  discounts: number[];
  extensionFees: number[];
  firstPaymentDate: string;
  firstPaymentInterest: number;
  initialCommission: number;
  interestRate: number;
  isAdditionalAmount: boolean;
  loanApplicationEnhancements: number[];
  loanExtensionFees: LoanExtensionFee[];
  monthlyPayment: number;
  newAmountUsedDays: number;
  newAmountWithInterestAndInitialCommission: number;
  newCapitalizedInterest: number;
  newInitialCommission: number;
  newInterest: number;
  newInterestBeforeDiscount: number;
  newPrincipal: number;
  newPrincipalApr: number;
  newPrincipalWithAllCapitalizableFees: number;
  term: number;
  totalCostOfCredit: number;
  totalInterest: number;
  totalPrincipal: number;
  totalRepayableAmount: number;
  totalRoundedAmount: number;
  unit: 'DAYS' | 'MONTHS';
};

type Props = {
  children: ReactNode;
};

type GetOfferContextType = {
  fetchGetOffer: (
    productNumber: string,
    amount: number,
    term: number,
  ) => Promise<any | void>;
  offer: Offer | null;
};

const GetOfferContext = createContext<GetOfferContextType>(
  {} as GetOfferContextType,
);

const useOffer = (): GetOfferContextType => useContext(GetOfferContext);

const OfferProvider = ({ children }: Props): JSX.Element => {
  const [offer, setOffer] = useState<Offer | null>(null);
  const { showError } = useError();

  const fetchGetOffer = useCallback(
    async (
      productNumber: string,
      amount: number,
      term: number,
    ): Promise<any | void> => {
      try {
        const fetchedOffer = await fetchData(
          `/application/first-loan-offer/product-number/${productNumber}?amount=${amount}&term=${term}`,
        );
        setOffer(fetchedOffer);
        return fetchedOffer;
      } catch (e) {
        showError(e as ValidationError);
        // throw e;
      }
    },
    [showError],
  );

  const GetOfferContextValue = useMemo(
    () => ({
      fetchGetOffer,
      offer,
    }),
    [fetchGetOffer, offer],
  );

  return (
    <GetOfferContext.Provider value={GetOfferContextValue}>
      {children}
    </GetOfferContext.Provider>
  );
};

export { useOffer, OfferProvider };
