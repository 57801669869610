import * as R from '../Router/routes';
import { AsyncFAQ } from '../Router/Router';
import { ReactComponent as CalendarIcon } from '../assets/icons/calendar_menu.svg';
import { ClientDebtRestructuringData } from 'hooks/webapi/useClientDebtRestructuring';
import { ReactComponent as DashboardIcon } from '../assets/icons/dashboard.svg';
import { ReactComponent as HelpIcon } from '../assets/icons/question_mark.svg';
import { LatestLoan } from 'hooks/webapi/useLatestLoan';
import { ReactComponent as MoneyIcon } from '../assets/icons/money.svg';
import { ReactComponent as ProfileIcon } from '../assets/icons/profile.svg';
import { ReactComponent as RepaymentIcon } from '../assets/icons/repayment.svg';
import { useActiveLoan } from '../pages/ActiveLoan/useActiveLoan';
import { useClient, useLatestLoan } from './webapi';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useApplicationProposals from './webapi/useApplicationProposals';
import useClientDebtRestructuring from './webapi/useClientDebtRestructuring';

function preloadFAQ() {
  AsyncFAQ.preload();
}

type Props = {
  menuDisabled?: boolean;
  shouldFetchMenu?: boolean;
};

export const isRepaymentRouteDisabled = (
  latestLoan: LatestLoan | null | undefined,
  debtRestructuringData: ClientDebtRestructuringData | undefined | null,
) => {
  return (
    !latestLoan ||
    latestLoan?.debtCollectLoanAgreement ||
    debtRestructuringData?.status === 'ACTIVE'
  );
};

const useMenuItems = (
  { shouldFetchMenu, menuDisabled }: Props = {
    shouldFetchMenu: true,
    menuDisabled: false,
  },
) => {
  const { t } = useTranslation();
  const { latestLoan, fetchLatestLoan } = useLatestLoan();
  const { isOverdue, isBrokenAgreement } = useActiveLoan();
  const { applicationProposals, fetchApplicationProposals } =
    useApplicationProposals();
  const { debtRestructuringData } = useClientDebtRestructuring();
  const { client, fetchClient } = useClient();

  const repaymentUrl =
    isOverdue && !isBrokenAgreement ? R.FULL_REPAYMENT : R.REPAYMENT;

  useEffect(() => {
    (async () => {
      try {
        if (shouldFetchMenu) {
          await Promise.all([
            latestLoan === undefined ? fetchLatestLoan() : null,
            client === undefined ? fetchClient() : null,
          ]);

          await fetchApplicationProposals();
        }
      } catch {}
    })();
  }, []);

  const menuItems = useMemo(
    () => [
      {
        title: t('menu:dashboard'),
        url: latestLoan ? R.ACTIVE_LOAN : R.REPEATED_LOAN,
        icon: <DashboardIcon />,
        dataTest: 'dashboard',
        disabled: menuDisabled,
      },
      {
        title: t('menu:additional_amount'),
        url: R.ADDITIONAL_AMOUNT,
        icon: <MoneyIcon />,
        disabled:
          menuDisabled ||
          !client?.additionalAmount ||
          latestLoan?.debtCollectLoanAgreement,
        dataTest: 'additional-amount',
      },
      ...(latestLoan !== null && latestLoan?.productCode === 'MINI'
        ? [
            {
              title: t('menu:extention'),
              url: `${R.EXTENSIONS}?PID=${latestLoan?.productNumber}`,
              disabled:
                menuDisabled ||
                !latestLoan?.isExtensionPossible ||
                latestLoan?.debtCollectLoanAgreement,
              icon: <CalendarIcon />,
              dataTest: 'extention',
            },
          ]
        : []),
      {
        title: t('menu:repayment'),
        url: repaymentUrl,
        icon: <RepaymentIcon />,
        disabled:
          menuDisabled ||
          isRepaymentRouteDisabled(latestLoan, debtRestructuringData),
        dataTest: 'repayment',
      },
      {
        title: t('menu:help'),
        url: R.FAQ,
        icon: <HelpIcon />,
        onMouseEnter: { preloadFAQ },
        dataTest: 'faq',
        disabled: menuDisabled,
      },
      {
        disabled: menuDisabled,
        title: t('menu:profile'),
        url: R.PROFILE,
        icon: <ProfileIcon />,
        dataTest: 'profile',
      },
    ],
    [applicationProposals, client?.additionalAmount, latestLoan, menuDisabled],
  );

  return menuItems;
};

export default useMenuItems;
