import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ValidationError } from '../../utils';
import fetchData from '../../utils/fetchData';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

type ClientSettings = {
  acceptNews: boolean;
  acceptMarketingEmails: boolean;
  acceptMarketingSms: boolean;
  acceptMarketingCalls: boolean;
  acceptDataSharing: boolean;
};

type AcceptNewsOptions = {
  body: {
    acceptNews: boolean;
  };
};

type AcceptNewsResponse = {
  identifiedBy: string;
  invoiceByMail: boolean;
  acceptNews: boolean;
  marketingCommunicationChannelControlEnabled: boolean;
  acceptMarketingEmails: boolean;
  acceptMarketingSms: boolean;
  acceptMarketingCalls: boolean;
  acceptProfiling: boolean;
  acceptDataSharing: boolean;
  acceptInternalDataSharing: boolean;
  language: string;
  identified: boolean;
  invoiceAddress: object;
  _links: object;
  _embedded: object;
};

type ClientSettingsOptions = {
  body: {
    acceptNews: boolean;
    acceptMarketingEmails: boolean;
    acceptMarketingSms: boolean;
    acceptMarketingCalls: boolean;
    acceptDataSharing: boolean;
  };
};

type ClientSettingsResponse = {
  identifiedBy: string;
  invoiceByMail: boolean;
  acceptNews: boolean;
  marketingCommunicationChannelControlEnabled: boolean;
  acceptMarketingEmails: boolean;
  acceptMarketingSms: boolean;
  acceptMarketingCalls: boolean;
  acceptProfiling: boolean;
  acceptDataSharing: boolean;
  acceptInternalDataSharing: boolean;
  language: string;
  identified: boolean;
  invoiceAddress: object;
  acceptNewsGrantedDate: string;
  _links: object;
  _embedded: object;
};

type ClientSettingsContextType = {
  clientSettings: ClientSettings | undefined;
  fetchClientSettings: () => Promise<ClientSettings | void>;
  updateAcceptNews: ({
    body,
  }: AcceptNewsOptions) => Promise<AcceptNewsResponse | void>;
  updateClientSettings: ({
    body,
  }: ClientSettingsOptions) => Promise<ClientSettingsResponse | void>;
};

const ClientSettingsContext = createContext<ClientSettingsContextType>(
  {} as ClientSettingsContextType,
);

const useClientSettings = (): ClientSettingsContextType =>
  useContext(ClientSettingsContext);

const ClientSettingsProvider = ({ children }: Props): JSX.Element => {
  const [clientSettings, setClientSettings] = useState<
    ClientSettings | undefined
  >();
  const { showError } = useError();

  const fetchClientSettings =
    useCallback(async (): Promise<ClientSettings | void> => {
      try {
        const fetchedClientSettings = await fetchData('/client/settings');
        setClientSettings(fetchedClientSettings);
        return fetchedClientSettings;
      } catch (e) {
        showError();
        throw e;
      }
    }, [showError]);

  const updateAcceptNews = useCallback(
    async ({ body }: AcceptNewsOptions): Promise<AcceptNewsResponse | void> => {
      try {
        const data = await fetchData('/client/settings/accept-news', {
          method: 'put',
          body: JSON.stringify(body),
        });

        setClientSettings(data);
        return data;
      } catch (e) {
        if (!(e instanceof ValidationError)) {
          showError();
          throw e;
        }
        // Don't throw error otherwise
      }
    },
    [showError],
  );

  const updateClientSettings = useCallback(
    async ({
      body,
    }: ClientSettingsOptions): Promise<ClientSettingsResponse | void> => {
      try {
        const data = await fetchData('/client/settings', {
          method: 'put',
          body: JSON.stringify(body),
        });

        setClientSettings(data);
        return data;
      } catch (e) {
        if (!(e instanceof ValidationError)) {
          showError();
          throw e;
        }
        // Don't throw error otherwise
      }
    },
    [showError],
  );

  const clientSettingsContextValue = useMemo(
    () => ({
      clientSettings,
      fetchClientSettings,
      updateAcceptNews,
      updateClientSettings,
    }),
    [
      clientSettings,
      fetchClientSettings,
      updateAcceptNews,
      updateClientSettings,
    ],
  );

  return (
    <ClientSettingsContext.Provider value={clientSettingsContextValue}>
      {children}
    </ClientSettingsContext.Provider>
  );
};

export { useClientSettings as default, ClientSettingsProvider };
