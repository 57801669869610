import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { useApplication, useClient } from 'hooks/webapi/index';
import { ValidationError } from '../../utils';
import fetchData from '../../utils/fetchData';
import pMemoize from 'p-memoize';
import useError from '../useError';
import useLatestLoan from './useLatestLoan';

const memoFetchData = pMemoize(fetchData, { maxAge: 1000 });

type Props = {
  children: ReactNode;
};

type BizumPaymentsOptions = {
  isExtension?: boolean;
  body: {
    amount?: number;
    successUrl: string;
    failureUrl: string;
    extensionTerm?: number;
  };
};

type CardPaymentsOptions = {
  body: {
    amount: number;
    applicationName: string;
    username: string;
    loanNumber: string;
    currency: string;
    storeCreditCard: boolean;
    successUrl: string;
    failureUrl: string;
    extensionTerm?: number;
  };
};

type CardPaymentsReissuedOptions = {
  body: {
    amount: number;
    applicationName: string;
    username: string;
    loanNumber: string;
    currency: string;
    cardId: number;
    extensionTerm?: number;
  };
};

type SaveCardPayments = {
  body: {
    username: string;
    clientNumber: string;
    applicationName: string;
    successUrl: string;
    failureUrl: string;
  };
};

type BizumPaymentsResponse = {
  errorCode?: 'DUPLICATE_PAYMENT' | string;
  status: 'OK' | 'ERROR';
  payFormUrl: string;
  requestId: string;
};

type CardPaymentsResponse = {
  status: string;
  payformUrl?: string;
  redirectUrl?: string;
  errorMessage?: null | string;
  errorMessageTemplate?: string;
};

type CardPaymentsReissuedResponse = {
  status: string;
  payformUrl?: string;
  redirectUrl?: string;
  errorMessage?: null | string;
  errorMessageTemplate?: string;
};

type SavedCardResponse = {
  status: string;
  payformUrl: string;
  errorMessage: null | string;
};

type CardPaymentsContextType = {
  getBizumPayments: (
    props: BizumPaymentsOptions,
  ) => Promise<BizumPaymentsResponse | void>;
  postCardPayments: ({
    body,
  }: CardPaymentsOptions) => Promise<CardPaymentsResponse | void>;
  postCardPaymentsReissued: ({
    body,
  }: CardPaymentsReissuedOptions) => Promise<CardPaymentsReissuedResponse | void>;
  saveCreditCard: ({
    body,
  }: SaveCardPayments) => Promise<SavedCardResponse | void>;
};

const CardPaymentsContext = createContext<CardPaymentsContextType>(
  {} as CardPaymentsContextType,
);

const useCardPaymentsSettings = (): CardPaymentsContextType =>
  useContext(CardPaymentsContext);

const PaymentsProvider = ({ children }: Props): JSX.Element => {
  const { showError } = useError();
  const { client } = useClient();
  const { application } = useApplication();
  const { selectedLoanExtension } = useLatestLoan();

  const registerDatadog = ({
    error,
    method,
    redirectionUrl,
  }: {
    error?: ValidationError;
    method: 'card' | 'bizum' | 'reissue';
    redirectionUrl?: string;
  }) => {
    if (error) {
      datadogLogs.logger.error(
        `payment - method:${method} - client:${client?.id} - app:${application?.id}`,
        {
          error: error,
        },
      );
    } else {
      datadogLogs.logger.info(
        `payment - method:${method} - client:${client?.id} - app:${application?.id} - redirection:${redirectionUrl}`,
      );
    }
  };

  const getBizumPayments = useCallback(
    async ({
      isExtension = false,
      body,
    }: BizumPaymentsOptions): Promise<BizumPaymentsResponse | void> => {
      try {
        // replace amount property by the extension term
        if (isExtension && selectedLoanExtension) {
          delete body?.amount;
          body.extensionTerm = selectedLoanExtension?.term;
        }

        const data = await memoFetchData(
          `/payment-platform/pay-by-link/pay${isExtension ? '-extension' : ''}`,
          {
            method: 'post',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              requesterName: 'frontend',
              ...body,
            }),
          },
        );

        registerDatadog({
          method: 'bizum',
          redirectionUrl: data?.payformUrl,
        });

        return data;
      } catch (e) {
        if (!(e instanceof ValidationError)) {
          showError(e as ValidationError);
        } else {
          registerDatadog({ method: 'bizum', error: e });
        }
        // Don't throw error otherwise
      }
    },
    [showError, selectedLoanExtension],
  );

  const postCardPayments = useCallback(
    async ({
      body,
    }: CardPaymentsOptions): Promise<CardPaymentsResponse | void> => {
      try {
        const data = await memoFetchData('/cardpayments', {
          method: 'post',
          headers: {
            Accept: 'application/vnd.cardpayments.v1+json',
            'Content-Type': 'application/vnd.cardpayments.v1+json',
          },
          body: JSON.stringify(body),
        });

        registerDatadog({
          method: 'card',
          redirectionUrl: data?.payformUrl || data?.redirectUrl,
        });

        return data;
      } catch (e) {
        if (!(e instanceof ValidationError)) {
          showError();
          throw e;
        } else {
          registerDatadog({ method: 'card', error: e });
          const errorMessageTemplate = e?.fieldErrors?.[0]?.messageTemplate;
          return {
            status: 'ERROR',
            errorMessageTemplate,
          };
        }
        // Don't throw error otherwise
      }
    },
    [showError],
  );

  const postCardPaymentsReissued = async ({
    body,
  }: CardPaymentsReissuedOptions): Promise<CardPaymentsReissuedResponse | void> => {
    try {
      const data = await memoFetchData(
        '/cardpayments/reissue-with-stored-card',
        {
          method: 'post',
          retries: 0,
          headers: {
            Accept: 'application/vnd.cardpayments.v1+json',
            'Content-Type': 'application/vnd.cardpayments.v1+json',
          },
          body: JSON.stringify(body),
        },
      );

      registerDatadog({
        method: 'reissue',
        redirectionUrl: data?.payformUrl || data?.redirectUrl,
      });

      return data;
    } catch (e) {
      if (!(e instanceof ValidationError)) {
        showError();
        throw e;
      } else {
        registerDatadog({ method: 'reissue', error: e });
        const errorMessageTemplate = e?.fieldErrors?.[0]?.messageTemplate;
        return {
          status: 'ERROR',
          errorMessageTemplate,
        };
      }
      // Don't throw error otherwise
    }
  };

  const saveCreditCard = useCallback(
    async ({ body }: SaveCardPayments): Promise<SavedCardResponse | void> => {
      try {
        const data = await memoFetchData('/cardpayments/store-credit-card', {
          method: 'post',
          headers: {
            Accept: 'application/vnd.cardpayments.v1+json',
            'Content-Type': 'application/vnd.cardpayments.v1+json',
          },
          body: JSON.stringify(body),
        });

        return data;
      } catch (e) {
        if (!(e instanceof ValidationError)) {
          showError();
          throw e;
        }
        // Don't throw error otherwise
      }
    },
    [showError],
  );

  const CardPaymentsContextValue = useMemo(
    () => ({
      getBizumPayments,
      postCardPayments,
      postCardPaymentsReissued,
      saveCreditCard,
    }),
    [
      getBizumPayments,
      postCardPayments,
      postCardPaymentsReissued,
      saveCreditCard,
    ],
  );

  return (
    <CardPaymentsContext.Provider value={CardPaymentsContextValue}>
      {children}
    </CardPaymentsContext.Provider>
  );
};

export { useCardPaymentsSettings as default, PaymentsProvider };
