import * as R from '../Router/routes';
import { CardType } from '../hooks/webapi/useActiveCard';
import { Client } from '../hooks/webapi/useClient';
import { ERROR_LOGIN_MESSAGE_QUERY_PARAM } from '../customAnalytics/constants';
import { LatestLoan } from '../hooks/webapi/useLatestLoan';
import { PaymentPreferences } from '../hooks/webapi/usePaymentPreferences';
import { userIsNotIdentified } from './userIsNotIdentified';

/* eslint-disable no-console */
export function isEnvSwitcherEnabled(env: string) {
  return ['feqa-1', 'feqa-2', 'at1', 'demo', 'local'].includes(env);
}

export function getSelectedDevEnv(): string {
  return (
    localStorage.getItem('qaSelectedEnv') ??
    (process.env.REACT_APP_DEFAULT_API_ENV as string) ??
    'stage'
  );
}

type UrlType =
  | 'WEB_API'
  | 'COLA_URL'
  | 'STATIC_HOMEPAGE'
  | 'STATIC_61_HOMEPAGE'
  | 'VIVUS_REGISTRATION_URL'
  | 'VIVUSONLINE_REGISTRATION_URL';

export function getProcessEnvUrl(url: UrlType) {
  const fallback = process.env[`REACT_APP_${url}`];

  if (!isEnvSwitcherEnabled(process.env.REACT_APP_ENV ?? '')) {
    return fallback;
  }

  const selectedDevEnv = getSelectedDevEnv().toUpperCase().replace(/-/g, '_');

  const found = process.env[`REACT_APP_${selectedDevEnv}_${url}`];

  if (!found) {
    console.error(`process.env.REACT_APP_${selectedDevEnv}_${url} not found.`);
  }

  return found ?? fallback;
}

export function getBackUrlMenuScreens(
  client: Client | undefined,
  latestLoan: LatestLoan | null | undefined,
) {
  const userNotIdentified = userIsNotIdentified(client);

  const backUrlUserNOTIdentified = userNotIdentified && R.DOCUMENTS_PENDING;
  const backUrlUserIdentified = latestLoan ? R.ACTIVE_LOAN : R.REPEATED_LOAN;

  return backUrlUserNOTIdentified || backUrlUserIdentified;
}

export function getClearUrlQueryString() {
  const urlParams = new URLSearchParams(
    decodeURIComponent(window.location.search),
  );
  urlParams.delete(ERROR_LOGIN_MESSAGE_QUERY_PARAM);
  urlParams.delete('redirect');
  urlParams.delete('cons');

  const paramsString = urlParams && urlParams.toString();

  if (paramsString) {
    return decodeURIComponent(`?${paramsString}`);
  }

  return '';
}

interface LoanConfirmationProps {
  activeCard: CardType | undefined;
  paymentPreferences: PaymentPreferences | undefined;
}

export function getUrlBetweenLoanAndConfirmation({
  activeCard,
  paymentPreferences,
}: LoanConfirmationProps) {
  const urlParams = new URLSearchParams(
    decodeURIComponent(window.location.search),
  );
  if (urlParams.has('autorepay')) urlParams.delete('autorepay');

  const urlParamsString = urlParams.size ? `?${urlParams.toString()}` : '';

  let nextRoute = '';
  if (!paymentPreferences?.autoRepay) {
    // To avoid searchParams from dashboard. For example: redirect, error search params...
    const fromLoanConfirm = window.location.pathname === R.LOAN_CONFIRM;
    if (activeCard) {
      nextRoute = fromLoanConfirm ? R.AUTOREPAY + urlParamsString : R.AUTOREPAY;
    } else {
      nextRoute = fromLoanConfirm ? R.SAVE_CARD + urlParamsString : R.SAVE_CARD;
    }
  } else {
    if (window.location.pathname === R.REPEATED_LOAN)
      nextRoute = R.LOAN_CONFIRM + urlParamsString;
    else nextRoute = R.REPEATED_LOAN + urlParamsString;
  }
  return nextRoute;
}

export const getLoginUrl = () => {
  const clientProductNameLogin = sessionStorage.getItem(
    'clientProductNameLogin',
  );
  if (clientProductNameLogin === '6') return R.ONLINE_LOGIN;
  return R.LOGIN;
};

export const getCalculatorUrl = () => {
  const clientProductNameLogin = sessionStorage.getItem(
    'clientProductNameLogin',
  );
  if (clientProductNameLogin === '6')
    return getProcessEnvUrl('STATIC_61_HOMEPAGE');
  return getProcessEnvUrl('STATIC_HOMEPAGE');
};
