import { useApplication, useClient } from './webapi';
import smartlookClient from 'smartlook-client';

const useSmartlook = () => {
  const smartlookProjectKeyExists = Boolean(
    process.env.REACT_APP_SMARTLOOK_PROJECT_KEY,
  );

  const { client: clientData } = useClient();
  const { application: clientApplicationData } = useApplication();

  const identifyClient = () => {
    if (smartlookProjectKeyExists && clientData?.number) {
      smartlookClient.identify(clientData.number, {
        application_resolution: clientApplicationData?.resolution || '',
        application_resolution_detail:
          clientApplicationData?.resolutionDetail || '',
      });
    }
  };

  return { identifyClient };
};

export default useSmartlook;
