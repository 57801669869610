import { ReactComponent as ChevronIcon } from '../../assets/icons/chevron-sharp.svg';
import { ReactComponent as NewLabelIcon } from '../../assets/icons/new-label.svg';

import { AnalyticsPassDownProps } from '../../customAnalytics/types/components';
import { Heading, Paragraph, Placeholder } from '..';
import { useNavigate } from '@reach/router';
import classes from 'classnames';
import React, {
  FunctionComponent,
  memo,
  ReactNode,
  SVGProps,
  useCallback,
} from 'react';
import styles from './MenuButton.module.scss';
import Touchable from '../Touchable/Touchable';
import Tracked from '../../customAnalytics/components/Tracked';

export type Props = {
  additionalInfo?: ReactNode;
  className?: string;
  classNameButton?: string;
  classNameIcon?: string;
  classNameHeading?: string;
  dataTest?: string;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  info?: string;
  isNew?: boolean;
  heading?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  url?: string;
};

export default memo(function MenuButton({
  heading,
  disabled,
  info,
  Icon,
  url,
  className,
  classNameButton,
  classNameIcon,
  classNameHeading,
  dataTest = 'menuButton',
  loading,
  label,
  additionalInfo,
  onMouseEnter,
  analyticsIsTrackable = false,
  analyticsTrackingDescription,
  isNew = false,
  onClick,
}: Props & AnalyticsPassDownProps) {
  const navigate = useNavigate();
  const handleNavigate = useCallback(() => {
    if (onClick) {
      onClick();
    }
    if (url) {
      navigate(url);
    }
  }, [url, navigate]);

  return (
    <div className={classes(styles.container, className)} data-test={dataTest}>
      <Tracked
        description={analyticsTrackingDescription}
        isContentTracked={analyticsIsTrackable}
        contentOverride={heading}
      >
        <Touchable
          disabled={disabled}
          dataTest="button"
          className={styles['button-container']}
          buttonClassName={classes(styles.button, classNameButton)}
          onClick={handleNavigate}
          onMouseEnter={onMouseEnter}
        >
          <Placeholder kind="div" className={styles.description}>
            {Icon && (
              <Placeholder loading={loading}>
                <Icon className={classes(styles.icon, classNameIcon)} />
              </Placeholder>
            )}
            <div>
              <Heading
                dataTest={`${dataTest}-heading`}
                className={classes(styles.heading, classNameHeading)}
              >
                <Placeholder loading={loading} className={classNameHeading}>
                  {heading}
                </Placeholder>
                {isNew && (
                  <Placeholder loading={loading}>
                    <NewLabelIcon className={styles['new-label-icon']} />
                  </Placeholder>
                )}
              </Heading>
              {info && (
                <Paragraph
                  dataTest={`${dataTest}-subheading`}
                  type="small"
                  className={styles.paragraph}
                >
                  <Placeholder loading={loading}>{info}</Placeholder>
                </Paragraph>
              )}
              <Paragraph dataTest={`${dataTest}-text`} type="small">
                {additionalInfo}
              </Paragraph>
            </div>
          </Placeholder>
          {label && (
            <Placeholder loading={loading} kind="div" className={styles.label}>
              {label}
            </Placeholder>
          )}
          <Placeholder loading={loading}>
            <ChevronIcon className={styles.chevron} />
          </Placeholder>
        </Touchable>
      </Tracked>
    </div>
  );
});
