import * as R from './routes';
import { LoadableComponent } from '@loadable/component';
import { Redirect, useLocation } from '@reach/router';
import { useEffect } from 'react';
import { useLatestLoan } from 'hooks/webapi';
import { useNavigate } from '@reach/router';
import GuardRoute from 'Router/GuardRoute';
import isLogged from 'utils/isLogged';

type Props = {
  component: LoadableComponent<any>;
  path: string;
  fallback: JSX.Element;
  [index: string]: any;
};

export default function PrivateRoute({
  component: Component,
  path,
  ...props
}: Props) {
  const { pathname } = useLocation();
  const { latestLoan } = useLatestLoan();
  const navigate = useNavigate();

  const userIsChangingThePass = pathname === R.PASSWORD_CHANGE;
  const isTokenDefined = isLogged();

  useEffect(() => {
    if (sessionStorage.getItem('fromTemporaryPassword') === 'true') {
      navigate(R.CHANGE_TEMPORARY_PASSWORD, { replace: true });
    }
  }, [navigate]);

  if (isTokenDefined || userIsChangingThePass) {
    return (
      <GuardRoute path={path}>
        <Component {...props} path={path} />
      </GuardRoute>
    );
  }

  const dashboardRedirectionRoute = Boolean(latestLoan)
    ? R.ACTIVE_LOAN
    : R.REPEATED_LOAN;

  const redirection = isTokenDefined ? dashboardRedirectionRoute : R.LOGIN;

  return <Redirect to={redirection} noThrow />;
}
