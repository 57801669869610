import { memo } from 'react';
import { Notification, Placeholder } from '../';
import { numberToCurrency } from '../../utils/translate';
import { useTranslation } from 'react-i18next';
import styles from './PenaltyNotification.module.scss';

type Props = {
  loading: boolean;
  penalty: number;
  overdue: number;
  commission: number;
};

export default memo(function PenaltyNotification({
  loading,
  penalty,
  overdue,
  commission,
}: Props) {
  const { t } = useTranslation('repayment');
  let notificationText;

  if (penalty > 0 && commission > 0)
    notificationText = 'penalties_penalty_and_commission';
  else if (penalty > 0 && commission === 0)
    notificationText = 'penalties_penalty';
  else notificationText = 'penalties_commission';

  return (
    <Notification
      type="error"
      className={styles.penalty}
      dataTest="penalty-notification"
    >
      <Placeholder loading={loading} dataTest="penalty-notification-text">
        {t(notificationText, {
          value: numberToCurrency(penalty + commission),
          count: overdue,
        })}
      </Placeholder>
    </Notification>
  );
});
