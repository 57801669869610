import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import fetchData from '../utils/fetchData';
import useError from './useError';

interface SeonConfig {
  host: string;
  session_id: string;
  audio_fingerprint: boolean;
  canvas_fingerprint: boolean;
  webgl_fingerprint: boolean;
  max_proxy_delay: number;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
}

interface SeonInstance {
  config: (body: SeonConfig) => void;
  getBase64Session: () => Promise<string>;
}

declare global {
  interface Window {
    seon: SeonInstance;
  }
}

const SEON_SCRIPT = 'https://cdn.seondf.com/js/v5/agent.js';

export const useSeon = () => {
  const [session, setSession] = useState<string | undefined>();

  const { showError } = useError();

  // Create seon session script
  useEffect(() => {
    const seonScript = document.createElement('script');
    seonScript.src = SEON_SCRIPT;
    seonScript.async = true;
    seonScript.defer = true;
    seonScript.id = 'seon-agent';
    document.head.appendChild(seonScript);
  }, []);

  const getSession = async () => {
    if (session) return session;

    const session_id = uuidv4();

    window.seon?.config({
      host: 'seondf.com',
      session_id,
      audio_fingerprint: true,
      canvas_fingerprint: true,
      webgl_fingerprint: true,
      max_proxy_delay: 1000,
      onSuccess: () => undefined,
      onError: () => undefined,
    });

    const encodedSession = await window.seon.getBase64Session();

    setSession(encodedSession);
    return encodedSession;
  };

  const sendSeonFraudInfo = async () => {
    try {
      const session = await getSession();

      const body = { session };

      await fetchData('/client/device-fingerprint-session', {
        method: 'POST',
        body: JSON.stringify(body),
      });
    } catch {
      showError();
    }
  };

  return {
    sendSeonFraudInfo,
  };
};
