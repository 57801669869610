import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import dayjs from 'dayjs';
import fetchData from '../../utils/fetchData';
import pMemoize from 'p-memoize';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

export type Discount = {
  name: string;
  type: 'EXTENSION' | 'INTEREST' | 'INITIAL_COMMISSION';
  discountPercent: number;
  lumpSumDiscount: number | null;
  termRanges: Array<{
    min: number;
    max: number;
  }>;
  validityRanges: Array<{
    min: string | null;
    max: string | null;
  }>;
  amountRanges: Array<{
    min: number;
    max: number;
  }>;
  created: string;
};

type DiscountsContextType = {
  discounts?: Discount[];
  largestExtensionDiscount?: Discount;
  largestInterestDiscount?: Discount;
  fetchDiscounts: () => Promise<Discount[] | void>;
};

const DiscountsContext = createContext<DiscountsContextType>(
  {} as DiscountsContextType,
);

const memoFetchData = pMemoize(fetchData, { maxAge: 1000 });

/** Finds the largest discount from web-api discount object. Optionally find largest discount by type */
const findLargestDiscount = (
  discounts: Discount[],
  type: 'EXTENSION' | 'INTEREST' | null = null,
) => {
  if (discounts?.length) {
    return discounts.reduce((accumulator: Discount | undefined, discount) => {
      return (accumulator?.discountPercent || 0) < discount.discountPercent &&
        (type === null || type === discount.type) &&
        (discount.validityRanges[0].min === null ||
          dayjs() >= dayjs(discount.validityRanges[0].min))
        ? discount
        : accumulator;
    }, undefined);
  }
  return undefined;
};

const useDiscounts = (): DiscountsContextType => useContext(DiscountsContext);

const DiscountsProvider = ({ children }: Props): JSX.Element => {
  const [discounts, setDiscounts] = useState<Discount[]>();
  const [largestExtensionDiscount, setLargestExtensionDiscount] =
    useState<Discount>();
  const [largestInterestDiscount, setLargestInterestDiscount] =
    useState<Discount>();
  const { showError } = useError();

  const fetchDiscounts = useCallback(async (): Promise<Discount[] | void> => {
    try {
      const fetchedDiscounts = await memoFetchData(
        '/client/discounts/available',
      );

      if (fetchedDiscounts) {
        setDiscounts(fetchedDiscounts.availableDiscounts);

        setLargestExtensionDiscount(
          findLargestDiscount(fetchedDiscounts.availableDiscounts, 'EXTENSION'),
        );
        setLargestInterestDiscount(
          findLargestDiscount(fetchedDiscounts.availableDiscounts, 'INTEREST'),
        );
      }

      return fetchedDiscounts;
    } catch (e) {
      showError();
      throw e;
    }
  }, [showError]);

  const discountsContextValue = useMemo(
    () => ({
      discounts,
      largestExtensionDiscount,
      largestInterestDiscount,
      fetchDiscounts,
    }),
    [
      discounts,
      fetchDiscounts,
      largestExtensionDiscount,
      largestInterestDiscount,
    ],
  );

  return (
    <DiscountsContext.Provider value={discountsContextValue}>
      {children}
    </DiscountsContext.Provider>
  );
};

export { useDiscounts as default, DiscountsProvider };
