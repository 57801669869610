import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import fetchData from '../../utils/fetchData';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

type ClientBlockedStatus = {
  blocked: boolean;
};

type ClientBlockedStatusContextType = {
  clientBlockedStatus: ClientBlockedStatus | undefined;
  fetchClientBlockedStatus: () => Promise<ClientBlockedStatus | void>;
};

const ClientBlockedStatusContext =
  createContext<ClientBlockedStatusContextType>(
    {} as ClientBlockedStatusContextType,
  );

const useClientBlockedStatus = (): ClientBlockedStatusContextType =>
  useContext(ClientBlockedStatusContext);

const ClientBlockedStatusProvider = ({ children }: Props): JSX.Element => {
  const [clientBlockedStatus, setClientBlockedStatus] = useState<
    ClientBlockedStatus | undefined
  >();
  const { showError } = useError();

  const fetchClientBlockedStatus =
    useCallback(async (): Promise<ClientBlockedStatus | void> => {
      try {
        const fetchedClientBlockedStatus = await fetchData(
          '/client/blockedStatus',
        );
        setClientBlockedStatus(fetchedClientBlockedStatus);
        return fetchedClientBlockedStatus;
      } catch (e) {
        showError();
        throw e;
      }
    }, [showError]);

  const clientBlockedStatusContextValue = useMemo(
    () => ({
      clientBlockedStatus,
      fetchClientBlockedStatus,
    }),
    [clientBlockedStatus, fetchClientBlockedStatus],
  );

  return (
    <ClientBlockedStatusContext.Provider
      value={clientBlockedStatusContextValue}
    >
      {children}
    </ClientBlockedStatusContext.Provider>
  );
};

export { useClientBlockedStatus as default, ClientBlockedStatusProvider };
