import * as R from '../../Router/routes';
import { getExtensionFee } from '../../utils/overdue';
import { roundNumber } from 'utils/translate';
import { useActiveLoan } from '../../pages/ActiveLoan/useActiveLoan';
import { useLatestLoan, usePaymentSchedule } from '../webapi';
import { useLocation } from '@reach/router';

const DEFAULT_PENALTY_RATE_PER_DAY = 0.8;
export const FEE_GRACE_PERIOD = 40; // 40€ reminder fee amount

interface BusinessOverdueNumbers {
  commissions?: number;
  debtAmount?: number;
  penaltiesAndCommissions?: number;
  totalInvoicedAmount?: number;
  fullRepayment?: number;
  amount?: number;
}

export const useBusinessOverdue = () => {
  const { isBrokenAgreement } = useActiveLoan();
  const { latestLoan } = useLatestLoan();
  const { paymentSchedule } = usePaymentSchedule();
  const location = useLocation();
  const productNumber = latestLoan?.productNumber;

  const isOverdue = latestLoan && latestLoan.overDueDays > 0;
  const isGracePeriod =
    latestLoan && latestLoan.overDueDays > 0 && latestLoan.overDueDays < 6;

  const commissions = isGracePeriod
    ? FEE_GRACE_PERIOD
    : roundNumber(latestLoan?.commissionAmount) || 0;

  const overdueTotalInvoicedAmount6_8 = roundNumber(
    latestLoan?.productCode === 'MINI'
      ? latestLoan?.payNowAmount
      : latestLoan?.payableAmount,
  );

  const overdueAmount6_8 = roundNumber(
    latestLoan?.productCode === 'MINI'
      ? latestLoan?.principalAmount
      : latestLoan?.payableAmount,
  );

  const getPenaltiesAndCommissions = () => {
    const debtProduct7 = paymentSchedule?.items.find(
      ({ status }) => status !== 'PAID',
    )?.principalAmount;
    // Grace period

    const debtAmount = is7CONS ? debtProduct7 || 0 : overdueAmount6_8 || 0;

    // principalAmount

    const penaltyRatePerDay =
      latestLoan?.penaltyRatePerDay || DEFAULT_PENALTY_RATE_PER_DAY;

    const penaltyGracePeriod =
      5 * (debtAmount * (penaltyRatePerDay / 100)) + commissions;

    // NON Grace period

    const penaltyNonGracePeriod =
      latestLoan && latestLoan.penaltyAmount + commissions;

    // Penalties and commissions

    const isGracePeriod =
      latestLoan && latestLoan.overDueDays > 0 && latestLoan.overDueDays <= 5;

    return isGracePeriod ? penaltyGracePeriod || 0 : penaltyNonGracePeriod || 0;
  };

  const is7CONS =
    latestLoan?.productCode === 'CONS' && latestLoan?.productNumber === '7';

  const getPendingDebt = () => {
    if (is7CONS) {
      const nextPayment = paymentSchedule?.items.find(
        ({ status }) => status !== 'PAID',
      );
      const commissionsToDebt = isGracePeriod
        ? 0
        : commissions + (latestLoan?.penaltyAmount || 0);

      return (nextPayment?.totalAmount || 0) + commissionsToDebt;
    }

    const commissionsToDebt = !isGracePeriod ? 0 : commissions;

    const debtAmount = (latestLoan?.payNowAmount || 0) - commissionsToDebt;

    return debtAmount;
  };

  const penaltiesAndCommissions = getPenaltiesAndCommissions();

  const getTotalInvoicedAmount: () => number = () => {
    if (is7CONS) {
      const penaltiesAndCommissionsToTotal = isGracePeriod
        ? 0
        : penaltiesAndCommissions || 0;
      return (
        (latestLoan?.totalOutstandingAmount || 0) +
        penaltiesAndCommissionsToTotal
      );
    }

    const totalAmountNoOverdue = roundNumber(
      latestLoan &&
        latestLoan?.totalOutstandingAmount +
          latestLoan?.penaltyAmount +
          latestLoan?.commissionAmount,
    );

    return isOverdue
      ? overdueTotalInvoicedAmount6_8 || 0
      : totalAmountNoOverdue || 0;
  };
  const totalInvoicedAmount = roundNumber(getTotalInvoicedAmount());

  const debtAmount = roundNumber(getPendingDebt());

  const getTotalToCardPayBrokenLoan = () => {
    if (!latestLoan) return 0;

    // tolerance to avoid the loan is closed during partial payment
    const term =
      latestLoan.loanTerm.termUnit === 'MONTHS'
        ? latestLoan.loanTerm.value * 30
        : latestLoan.loanTerm.value;
    const tolerance = (latestLoan.interestAmount / term) * 5;

    const amountToPay = isBrokenAgreement ? totalInvoicedAmount : debtAmount;

    // round number
    return Math.floor((amountToPay - tolerance) / 10) * 10;
  };

  const getValues = (): BusinessOverdueNumbers => {
    if (!productNumber) return {};
    const debtToPay = is7CONS ? debtAmount : totalInvoicedAmount;
    const extensionPay = getExtensionFee(latestLoan);

    const fullRepayment = roundNumber(
      is7CONS ? latestLoan?.payNowAmount || 0 : totalInvoicedAmount,
    );

    const amountDebtToPay = isBrokenAgreement ? fullRepayment : debtToPay;

    switch (location.pathname) {
      case R.EXTENSIONS:
        return { penaltiesAndCommissions, amount: extensionPay };
      case R.ACTIVE_LOAN:
        return {
          penaltiesAndCommissions,
          amount: amountDebtToPay,
          totalInvoicedAmount,
        };
      case R.DR_OVERDUE:
        return { amount: latestLoan?.payNowAmount, totalInvoicedAmount };
      case R.INSTALLMENT_PAYMENT:
        return {
          commissions,
          debtAmount,
          amount: debtToPay,
          totalInvoicedAmount,
        };
      case R.CARD_PAYMENT:
      case R.FULL_REPAYMENT:
        return { amount: fullRepayment };
      case R.REPAYMENT_BROKEN_LOAN:
        const amount = roundNumber(getTotalToCardPayBrokenLoan());
        return { amount };
      case R.BREAKDOWN_DEBT:
        return { commissions, amount: fullRepayment };
      default:
        return {};
    }
  };

  return getValues();
};
