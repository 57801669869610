import { ReactComponent as CheckPaidIcon } from '../../assets/icons/check-circle-success-v2.svg';
import { dateFormat, numberToCurrency } from '../../utils/translate';
import { DR_PAYMENT_SCHEDULE } from '../../Router/routes';
import { ReactComponent as ErrorIcon } from '../../assets/icons/exclamation-circle-error.svg';
import { getOverdueType } from '../../utils/overdue';
import { getToday } from 'utils/shiftedDateTime';
import { memo } from 'react';
import { PaymentScheduleStatus } from '../../hooks/webapi/usePaymentSchedule';
import { Placeholder } from '../../components';
import { useActiveLoan } from '../../pages/ActiveLoan/useActiveLoan';
import { useBusinessOverdue } from '../../hooks/businessLogic/useBusinessOverdue';
import { useLatestLoan, usePaymentSchedule } from '../../hooks/webapi';
import { useLocation } from '@reach/router';
import { ReactComponent as WarningIcon } from '../../assets/icons/exclamation-circle-warning.svg';
import classes from 'classnames';
import styles from './PaymentScheduleTable.module.scss';
import useClientDebtRestructuring from '../../hooks/webapi/useClientDebtRestructuring';

interface LoanOverviewProps {
  loading?: boolean;
}

const PaymentScheduleTable = memo(({ loading = false }: LoanOverviewProps) => {
  const { isOverdue } = useActiveLoan();
  const { paymentSchedule } = usePaymentSchedule();
  const { latestLoan } = useLatestLoan();
  const { amount } = useBusinessOverdue();
  const { debtRestructuringData } = useClientDebtRestructuring();
  const { pathname } = useLocation();

  const overdueType = getOverdueType(latestLoan);

  const getIconPaymentSchedule = (status: PaymentScheduleStatus) => {
    if (status === 'PAID')
      return (
        <CheckPaidIcon
          className={styles.icon}
          data-test="payment-schedule-paid-icon"
        />
      );
    if (overdueType === 'error' || status === 'DELAYED')
      return (
        <ErrorIcon
          className={styles.icon}
          data-test="payment-schedule-error-icon"
        />
      );
    if (overdueType === 'warning')
      return (
        <WarningIcon
          className={styles.icon}
          data-test="payment-schedule-warning-icon"
        />
      );

    return null;
  };

  if (pathname === DR_PAYMENT_SCHEDULE) {
    return (
      <div data-test="payment-schedule" className={styles['payment-schedule']}>
        {debtRestructuringData?.latestRepaymentProposal?.installments.map(
          ({ dueDate, amount: amountPayment, status }, index) => {
            const isDelayed = getToday(dueDate) < getToday();
            return (
              <div
                key={`${index}-${dueDate}`}
                className={classes(
                  styles['table-row'],
                  isDelayed && styles['overdue-color-error'],
                )}
              >
                <Placeholder
                  dataTest={`payment-schedule-date-${index}`}
                  loading={loading}
                >
                  {dateFormat(dueDate)}
                </Placeholder>
                <Placeholder
                  className={styles['table-row-value']}
                  dataTest={`payment-schedule-amount-${index}`}
                  loading={loading}
                >
                  {isDelayed && getIconPaymentSchedule('DELAYED')}
                  {numberToCurrency(amountPayment)}
                </Placeholder>
              </div>
            );
          },
        )}
      </div>
    );
  }

  return (
    <div data-test="payment-schedule" className={styles['payment-schedule']}>
      {paymentSchedule?.items.map(
        ({ dueDate, totalAmount, number, status }, index) => {
          const isPaid = number < paymentSchedule?.activeInstalement;
          const isDelayed = status === 'DELAYED';
          const showOverdueStyles = isOverdue && Boolean(status);

          const isFirstPaymentPendingToPay =
            isOverdue && status && status !== 'PAID';
          return (
            <div
              key={`${index}-${dueDate}`}
              className={classes(
                styles['table-row'],
                (showOverdueStyles || isDelayed) &&
                  styles[`overdue-color-${overdueType}`],
                isPaid && styles['paid-quota'],
              )}
            >
              <Placeholder
                dataTest={`payment-schedule-date-${index}`}
                loading={loading}
              >
                {dateFormat(dueDate)}
              </Placeholder>
              <Placeholder
                className={styles['table-row-value']}
                dataTest={`payment-schedule-amount-${index}`}
                loading={loading}
              >
                {(showOverdueStyles || isDelayed) &&
                  getIconPaymentSchedule(status)}
                {isFirstPaymentPendingToPay
                  ? numberToCurrency(amount)
                  : numberToCurrency(totalAmount)}
              </Placeholder>
            </div>
          );
        },
      )}
    </div>
  );
});

export default PaymentScheduleTable;
