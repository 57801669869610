import { ChangeEvent, memo, ReactNode, useCallback } from 'react';
import classes from 'classnames';
import styles from './Checkbox.module.scss';

export type Props = {
  children: ReactNode;
  className?: string;
  dataTest?: string;
  disabled?: boolean;
  error?: string;
  isTouched?: boolean;
  border?: boolean;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: boolean;
  pending?: boolean;
};

export default memo(function Checkbox({
  children,
  className,
  dataTest = 'checkbox',
  disabled,
  error,
  isTouched,
  border = false,
  name,
  onChange,
  value,
  pending,
}: Props) {
  const showError = isTouched && error;

  const handleOnChange = useCallback(
    (e: ChangeEvent<any>) => {
      if (onChange) onChange(e);
    },
    [onChange],
  );

  return (
    <div
      className={classes(className, styles.wrapper, border && styles.border)}
    >
      <label
        className={classes(
          styles.container,
          disabled && styles.disabled,
          pending && styles.pending,
        )}
      >
        <input
          type="checkbox"
          name={name}
          id={name}
          onChange={handleOnChange}
          value={String(value)}
          checked={value || false}
          data-test={dataTest}
          disabled={disabled || pending}
          className={classes(styles.invisible)}
        />
        <div className={styles.content} data-test={`${dataTest}-content`}>
          {children}
        </div>
        <span
          className={classes(styles.checkmark, showError && styles.error)}
        />
      </label>
      {showError && (
        <div
          className={styles['error-message']}
          data-test={`${dataTest}-error`}
        >
          {error}
        </div>
      )}
    </div>
  );
});
