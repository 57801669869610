import { isNotProduction } from './isNotProduction';

export const SHIFTED_DATETIME = 'shiftedDateTime';

export const removeShiftedDatetime = () => {
  return sessionStorage.removeItem(SHIFTED_DATETIME);
};

export const getShiftedDatetime = () => {
  return sessionStorage.getItem(SHIFTED_DATETIME) || undefined;
};

export const setShiftedDatetime = (day: string) => {
  const newDate = `${day} 00:00:00:000`;
  sessionStorage.setItem(SHIFTED_DATETIME, newDate);
};

export const getShiftedDatetimeHeader = () => {
  const timeFromSession = getShiftedDatetime();
  if (!isNotProduction() || !timeFromSession) return {};
  return {
    time: timeFromSession,
  };
};

export const getToday = (valueFromParent?: string) => {
  if (valueFromParent) return new Date(valueFromParent);

  const shiftedDate = getShiftedDatetime();
  if (shiftedDate) return new Date(shiftedDate);
  return new Date();
};
