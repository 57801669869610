import {
  AppInfo,
  IdentifyEvent,
  IdentifyPayload,
  PageEvent,
  PagePayload,
  TrackEvent,
  TrackPayload,
} from '../types';
import { debounce, isNil, omitBy } from 'lodash';
import { detect } from 'detect-browser';
import { digestMessage } from '../hash';
import { EMAIL_REGEXP } from '../../../utils/validations';
import { Queue } from './Queue';
import { v4 as uuid } from 'uuid';
import fetchAnalytics from '../../fetchAnalytics';

export interface AnalyticsPluginProps {
  url: string;
  debounceTimeMs?: number;
  app?: AppInfo;
  dequeueInterval?: number;
  onFetchError?: (error?: any) => void;
}

const regexDateSelector = /^\d{4}-\d{2}-\d{2}/g;

export const analyticsPlugin = ({
  debounceTimeMs = 5000,
  app,
  dequeueInterval = 1000,
  onFetchError,
}: AnalyticsPluginProps) => {
  const queue = new Queue();
  let isFetching = false;
  const debouncedEnqueue = debounce(queue.enqueue.bind(queue), debounceTimeMs);
  const shortDebouncedEnqueue = debounce(queue.enqueue.bind(queue), 60);

  let lastTrackEvent: TrackEvent | null = null;
  let previousPageTs: number | null = null;
  let loginId: string | null;
  let applicationId: string | number | null = null;
  let uid: string | null;

  setInterval(async () => {
    if (isFetching || queue.isEmpty()) {
      return;
    }
    const analyticsEvent = queue.peek();
    if (!analyticsEvent) {
      return;
    }
    try {
      isFetching = true;
      await fetchAnalytics(analyticsEvent);
      queue.dequeue();
    } catch (e) {
      onFetchError?.(e);
    } finally {
      isFetching = false;
    }
  }, dequeueInterval);

  return {
    name: 'analytics-plugin',
    initialize: () => {
      loginId = sessionStorage.getItem('loginId');
      applicationId = sessionStorage.getItem('applicationId');
      uid = sessionStorage.getItem('uid');
      if (!loginId) {
        loginId = uuid();
        sessionStorage.setItem('loginId', loginId);
      }
    },
    reset: () => {
      queue.clear();
      loginId = uuid();
      applicationId = null;
      uid = null;
      sessionStorage.removeItem('applicationId');
      sessionStorage.removeItem('uid');
      sessionStorage.setItem('loginId', loginId);
    },

    track: async ({
      payload: {
        event: type,
        properties,
        meta: { ts },
        options,
      },
    }: {
      payload: TrackPayload;
    }) => {
      const { href, pathname, origin, search } = window.location;

      if (
        type === 'input' &&
        typeof properties?.value === 'string' &&
        properties?.value.match(EMAIL_REGEXP)
      ) {
        const hash = await digestMessage(properties.value);
        uid = hash;
        sessionStorage.setItem('uid', hash);
      }

      // Create TRACK event
      const trackEvent: TrackEvent = {
        uid,
        loginId,
        applicationId,
        target: omitBy(properties, isNil),
        type,
        ts,
        location: {
          href,
          pathname,
          origin,
          search,
        },
      };

      if (type === 'calculator') {
        if (
          lastTrackEvent?.type === 'calculator' &&
          lastTrackEvent?.target?.description !== trackEvent.target.description
        ) {
          queue.enqueue(lastTrackEvent);
        } else {
          debouncedEnqueue(trackEvent);
        }
        lastTrackEvent = trackEvent;
        return;
      }

      if (
        type !== 'input' ||
        typeof properties?.value !== 'string' ||
        options.isPasted
      ) {
        queue.enqueue(trackEvent);
        lastTrackEvent = null;
        return;
      }

      if (properties.value.match(regexDateSelector)) {
        shortDebouncedEnqueue(trackEvent);
        lastTrackEvent = null;
        return;
      }

      if (type === 'input') {
        if (
          typeof lastTrackEvent?.target.inputPlaceholder === 'string' &&
          lastTrackEvent.target.inputPlaceholder !== properties.inputPlaceholder
        ) {
          queue.enqueue(lastTrackEvent);
        }
        lastTrackEvent = trackEvent;
        debouncedEnqueue(trackEvent);
      }
    },
    page: ({
      payload: {
        anonymousId,
        type,
        properties,
        meta: { ts },
      },
    }: {
      payload: PagePayload;
    }) => {
      const timeSpent = previousPageTs !== null ? ts - previousPageTs : null;
      previousPageTs = ts;

      const browser = detect();

      const pageEvent: PageEvent = {
        uid,
        loginId,
        applicationId,
        type,
        properties: {
          ...properties,
          prevPathTimeSpent: timeSpent,
        },
        app,
        deviceInfo: browser ? { browser } : undefined,
      };
      queue.enqueue(pageEvent);
    },
    identify: async ({
      payload: { anonymousId, type, traits },
    }: {
      payload: IdentifyPayload;
    }) => {
      if (traits.application?.id) {
        applicationId = traits.application.id;
        sessionStorage.setItem('applicationId', applicationId.toString());
      }
      const email = traits.email;
      if (email && typeof email === 'string') {
        const hash = await digestMessage(email);
        uid = hash;
        sessionStorage.setItem('uid', hash);
      }
      const identifyEvent: IdentifyEvent = {
        uid,
        loginId,
        applicationId,
        type,
        traits,
      };
      queue.enqueue(identifyEvent);
    },
  };
};
