export const PHONE = '91 290 77 88';
export const PHONE_LINK = `+34${PHONE.replaceAll(' ', '')}`;

export const PHONE_ONLINE = '91 060 66 44';
export const PHONE_ONLINE_LINK = `+34${PHONE_ONLINE.replaceAll(' ', '')}`;

export const PHONE_OVERDUE = '91 084 05 59';
export const PHONE_OVERDUE_LINK = `+34${PHONE_OVERDUE.replaceAll(' ', '')}`;

// QUERY SEARCH

export const QUERY_FULL_REPAYMEMT = 'fullRepayment=true';
export const QUERY_BACK_ROUTE = 'backRoute';
export const QUERY_PARTIAL_AMOUNT = 'partialAmount';
export const QUERY_EXTENSION = 'extension';
export const QUERY_PAYMENT_METHOD = 'paymentMethod';
export const QUERY_BIZUM = 'paymentMethod=bizum';
export const QUERY_BIZUM_VALUE = 'bizum';
export const QUERY_CARD_ADDED = 'card';

// OTHER

export const COMPANY_NAME_4FINANCE = '4FINANCE SPAIN FINANCIAL SERVICES, S.A.U';
export const MIN_PARTIAL_PAYMENT_VALUE = 10;
