import { globalHistory } from '@reach/router';
import { isNil, omitBy } from 'lodash';
import { useApplication, useClient, useLatestLoan } from '../../hooks/webapi';
import { useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import useDataLayerHashedValues from './useDataLayerHashedValues';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM || '',
};

export default function useDataLayer() {
  const { client, email, phone } = useClient();
  const { application } = useApplication();
  const { latestLoan } = useLatestLoan();
  const prevLocation = useRef<string | null>();
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const {
    getHashedEmail,
    getHashedFirstName,
    getHashedLastName,
    getHashedTelephoneNumber,
  } = useDataLayerHashedValues();

  const logPageChange = async () => {
    try {
      const dataLayer: Record<string, any> = {
        event: 'pageChange',
        page: {
          url: window.location.pathname,
          referrer: prevLocation.current ?? document.referrer,
        },
      };

      if (client) {
        dataLayer.client = omitBy(
          {
            id: client.id,
            dateOfBirth: client.dateOfBirth,
            number: client.number,
            status: client.status,
            age: client.age,
            identifiedBy: client.identifiedBy,
            registeredBy: client.registeredBy,
            htn: await getHashedTelephoneNumber(phone),
            hma: await getHashedEmail(email),
            address: omitBy(
              {
                hfn: await getHashedFirstName(client.firstName),
                hln: await getHashedLastName(client.lastName),
                city: client.declaredAddress?.location1,
                'postal-code': client.declaredAddress?.postalCode,
              },
              isNil,
            ),
          },
          isNil,
        );
      }

      if (application) {
        if (application.status === 'OPEN') {
          dataLayer.application = omitBy(
            {
              amount: application.amount,
              id: application.id,
              status: application.status,
              term: application.term,
              type: application.type,
            },
            isNil,
          );
        } else {
          dataLayer.application = omitBy(
            {
              status: application.status,
              resolution: application.resolution,
              resolutionDetail: application.resolutionDetail,
              resolutionMessage: application.resolutionMessage,
            },
            isNil,
          );
        }
      }

      if (latestLoan) {
        dataLayer.latestLoan = omitBy(
          {
            loanNumber: latestLoan.loanNumber,
            amount: latestLoan.amount,
            term: latestLoan.loanTerm.value,
            overdueDays: latestLoan.overDueDays,
            dueDate: latestLoan.dueDate,
          },
          isNil,
        );
      }

      TagManager.dataLayer({
        dataLayer,
      });

      prevLocation.current = window.location.pathname;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error logging page change do GTM dataLayer', e);
    }
  };

  const decouncedLogPageChange = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(logPageChange, 2000);
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);

    // Track basic JavaScript errors
    window.addEventListener('error', function (e) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'error',
          message: e.message,
          stacktrace: e.error?.stack || 'error stack undefined',
          path: window.location.pathname,
        },
      });
    });

    if (
      process.env.REACT_APP_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'staging' ||
      process.env.REACT_APP_ENV === 'test'
    ) {
      // Log the initial page load
      logPageChange();

      return globalHistory.listen(async () => {
        decouncedLogPageChange();
      });
    }
  }, []);

  useEffect(() => {
    decouncedLogPageChange();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [client, application, latestLoan]);
}
