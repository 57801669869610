import { memo, ReactNode } from 'react';
import classes from 'classnames';
import Ripples from 'react-ripples';
import styles from './Touchable.module.scss';

export type Props = {
  children: ReactNode;
  type?: 'submit' | 'button';
  disabled?: boolean;
  className?: string;
  dataTest?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  buttonClassName?: string;
  rippleColor?: string;
  rounded?: boolean;
};

export default memo(function Touchable({
  children,
  type = 'button',
  disabled = false,
  className,
  dataTest,
  onClick,
  onMouseEnter,
  buttonClassName,
  rippleColor = 'rgba(166, 204, 51, 0.4)',
  rounded = false,
}: Props) {
  return (
    <Ripples
      className={classes(
        className,
        styles.ripples,
        rounded && styles.rounded,
        disabled && styles.disabled,
      )}
      color={disabled ? '' : rippleColor}
    >
      <button
        className={classes(buttonClassName, styles.button)}
        data-test={dataTest}
        type={type}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        disabled={disabled}
      >
        {children}
      </button>
    </Ripples>
  );
});
