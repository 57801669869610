import { memo, MouseEvent, useCallback, useEffect, useState } from 'react';
import { Paragraph } from '..';
import classes from 'classnames';
import Lottie from 'react-lottie-light';
import Ripples from 'react-ripples';
import styles from './DocumentUpload.module.scss';

import { ReactComponent as DocumentIcon } from '../../assets/icons/document-green.svg';
import { ReactComponent as HazardIcon } from '../../assets/icons/hazard.svg';
import { ReactComponent as IdBackIcon } from '../../assets/icons/id-back.svg';
import { ReactComponent as IdFrontIcon } from '../../assets/icons/id-front.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus-circle.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { useTranslation } from 'react-i18next';
import BigDoneIcon from '../../assets/lottie/big-done-icon.json';
import DocumentIconPath from '../../assets/icons/document.svg';

const doneIconLottieOptions = {
  loop: false,
  autoplay: true,
  animationData: BigDoneIcon,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
export type Props = {
  type: 'ID_FRONT' | 'ID_BACK' | 'DOCUMENT';
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  error?: string;
  isTouched?: boolean;
  value?: File | null;
  onDelete: (name: string) => void;
  dataTest?: string;
  name: string;
  containerClassName?: string;
  onClick?: (e: MouseEvent<any>) => void;
  isNotInput?: boolean;
  innerRef?: any;
  typeDoc?: string | null;
};

const getBase64FromFile = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      }
    });

    reader.addEventListener('error', () => {
      reject();
    });

    reader.readAsDataURL(file);
  });
};

export default memo(function DocumentUpload({
  type,
  value,
  onChange,
  className,
  error,
  isTouched,
  onDelete,
  dataTest = 'documentUpload',
  name,
  containerClassName,
  onClick,
  isNotInput = false,
  innerRef,
  typeDoc,
}: Props) {
  const { t } = useTranslation('document_upload');

  const UPLOAD_TYPE = {
    ID_FRONT: {
      icon: IdFrontIcon,
      title: t('id_front_title'),
      subtitle: '',
    },
    ID_BACK: {
      icon: IdBackIcon,
      title: t('id_back_title'),
      subtitle: '',
    },
    DOCUMENT: {
      icon: DocumentIcon,
      subtitle: '',
      title: t('additionalDocument'),
    },
  };

  const [img, setImg] = useState('');
  const Icon = UPLOAD_TYPE[type].icon;
  const showError = isTouched && error;

  useEffect(() => {
    const createImg = async () => {
      if (!value) return;

      const convertedImg = await getBase64FromFile(value);
      setImg(convertedImg);
    };

    createImg();
  }, [value]);

  const handleDelete = useCallback(() => {
    if (!onDelete) return;

    onDelete(name);
  }, [name, onDelete]);

  const renderInput = useCallback(() => {
    return isNotInput ? (
      <>
        <div
          onClick={onClick}
          className={styles.input}
          data-test={`${dataTest}-input`}
        ></div>
        <Icon className={styles.icon} data-test={`${dataTest}-input-icon`} />
      </>
    ) : (
      <>
        <input
          data-test={`${dataTest}-input`}
          ref={innerRef}
          name={name}
          className={styles.input}
          onChange={onChange}
          type="file"
          accept="application/pdf,image/jpeg,image/gif,image/png,image/tiff,image/bmp"
        />
        <Icon className={styles.icon} data-test={`${dataTest}-input-icon`} />
      </>
    );
  }, [isNotInput, innerRef, Icon, name, onClick, onChange]);

  return (
    <div className={className}>
      <Ripples
        className={classes(styles.ripples, !value && styles.focusable)}
        color={value ? 'rgba(0, 0, 0, 0)' : 'rgba(92, 182, 14, 0.3)'}
      >
        <div
          className={classes(
            styles.container,
            containerClassName,
            showError && styles.error,
          )}
          data-test={dataTest}
        >
          {value ? (
            <div
              style={{
                backgroundImage: `url(${img}), url(${DocumentIconPath})`,
              }}
              className={styles.preview}
              data-test={`${dataTest}-preview`}
            >
              <div
                className={styles['lottie-wrapper']}
                data-test={`${dataTest}-lottie-wrapper`}
              >
                <Lottie options={doneIconLottieOptions} />
              </div>
            </div>
          ) : (
            renderInput()
          )}
          <div className={styles.text}>
            <label
              className={classes(styles.label, showError && styles.error)}
              data-test={`${dataTest}-title`}
            >
              {typeDoc
                ? t(`documentTypes:${typeDoc}`)
                : UPLOAD_TYPE[type].title}
            </label>
            {UPLOAD_TYPE[type].subtitle && (
              <span
                className={styles.subtitle}
                data-test={`${dataTest}-subtitle`}
              >
                {UPLOAD_TYPE[type].subtitle}
              </span>
            )}
          </div>
          {value ? (
            <button
              className={styles.delete}
              type="button"
              data-test={`${dataTest}-delete-icon`}
              onClick={handleDelete}
            >
              <TrashIcon data-test={`${dataTest}-trash-icon`} />
            </button>
          ) : (
            <PlusIcon
              className={classes(styles.plus, showError && styles.error)}
              data-test={`${dataTest}-plus-icon`}
            />
          )}
        </div>
      </Ripples>
      {showError && (
        <div
          data-test={`${dataTest}-error`}
          className={styles['error-wrapper']}
        >
          <HazardIcon
            className={styles['error-icon']}
            data-test={`${dataTest}-error-icon`}
          />
          <Paragraph
            className={styles.error}
            data-test={`${dataTest}-error-text`}
          >
            {error}
          </Paragraph>
        </div>
      )}
    </div>
  );
});
