import * as R from '../../Router/routes';
import { ReactComponent as ChevronIcon } from '../../assets/icons/chevron-sharp.svg';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import { memo } from 'react';
import { urlBundlePreloadHashMap } from '../../Router/Router';
import { useTranslation } from 'react-i18next';
import classes from 'classnames';
import Link from '../Link/Link';
import styles from './DesktopHeader.module.scss';
import Tracked from '../../customAnalytics/components/Tracked';

export type Props = {
  backUrl?: string;
  backUrlDisabled?: boolean;
  withLogout?: boolean;
  onLogout?: () => void;
  className?: string;
  pending?: boolean;
  logoLinkUrl?: string;
};

function preloadAccount() {
  urlBundlePreloadHashMap[R.ACTIVE_LOAN]();
}

export default memo(function DesktopHeader({
  backUrl,
  backUrlDisabled,
  withLogout,
  onLogout,
  className,
  pending,
  logoLinkUrl,
}: Props) {
  const { t } = useTranslation();
  function preload() {
    if (backUrl && urlBundlePreloadHashMap[backUrl]) {
      urlBundlePreloadHashMap[backUrl]();
    }
  }

  return (
    <header className={classes(styles.header, className)}>
      <div className={styles.item}>
        {backUrl && (
          <Tracked description="Back arrow" contentOverride="<">
            <Link
              dataTest="flow-back"
              disabled={backUrlDisabled}
              to={backUrl}
              onMouseEnter={preload}
              isButtonPreferred={false}
            >
              <ChevronIcon
                className={styles.chevron}
                data-test="desktopHeader-chevron-icon"
              />
              <span data-test="desktopHeader-back-text">
                {t('common:back')}
              </span>
            </Link>
          </Tracked>
        )}
      </div>
      <div className={classes(styles.item, styles.center)}>
        <Tracked description="Logo link" contentOverride="<logo>">
          <Link
            to={logoLinkUrl || R.ACTIVE_LOAN}
            onMouseEnter={preloadAccount}
            dataTest="desktop-back-link"
            isButtonPreferred={false}
          >
            <Logo />
          </Link>
        </Tracked>
      </div>
      <div className={classes(styles.item, styles.right)}>
        {withLogout && (
          <Tracked description="Logout link" contentOverride="<logout icon>">
            <Link
              disabled={pending}
              onClick={onLogout}
              dataTest="desktopHeader-logout-link"
            >
              <span data-test="desktopHeader-logout-text">
                {t('menu:logout')}
              </span>
              <LogoutIcon
                className={styles.logout}
                data-test="desktopHeader-logout-icon"
              />
            </Link>
          </Tracked>
        )}
      </div>
    </header>
  );
});
