import { LatestLoan } from '../hooks/webapi/useLatestLoan';

export const getOverdueType = (latestLoan: LatestLoan | null | undefined) => {
  if (latestLoan?.overDueDays === undefined) return;

  if (latestLoan?.overDueDays > 5) return 'error';
  return 'warning';
};

export const getExtensionFee = (latestLoan: LatestLoan | null | undefined) => {
  if (!latestLoan) return 0;

  const isMini = latestLoan.productCode === 'MINI';
  const isMini8 = isMini && latestLoan.productNumber === '8';

  const totalFee =
    latestLoan.productCode === 'MINI'
      ? latestLoan.payNowAmount
      : latestLoan.payableAmount;

  const totalFeeNoPenalties = (totalFee || 0) - (latestLoan.penaltyAmount || 0);

  const feeToPay = isMini8 ? totalFeeNoPenalties : totalFee;

  return feeToPay;
};
