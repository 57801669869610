import { AnalyticsPassDownProps } from '../../customAnalytics/types/components';
import { Button, Card, Heading, Paragraph } from '..';
import { memo, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AttentionLottie from '../../assets/lottie/attention.json';
import classes from 'classnames';
import DoneLottie from '../../assets/lottie/done.json';
import Lottie from 'react-lottie-light';
import styles from './StatusCard.module.scss';
import Tracked from '../../customAnalytics/components/Tracked';

export type Props = {
  type?: 'success' | 'rejected';
  heading?: string;
  contentClassName?: string;
  titleDescription?: string;
  description?: string;
  descriptionClassName?: string;
  children?: ReactNode;
  icon?: ReactNode;
  onClick?: () => void;
  button?: string;
  className?: string;
  hideButton?: boolean;
  pending?: boolean;
  dataTest?: string;
};

export default memo(function ({
  dataTest = 'statusCard',
  type = 'success',
  heading,
  children,
  contentClassName,
  titleDescription,
  description,
  button,
  onClick,
  className,
  descriptionClassName,
  hideButton = false,
  analyticsIsTrackable = false,
  analyticsTrackingDescription,
  icon,
  pending,
}: Props & AnalyticsPassDownProps) {
  const { t } = useTranslation('common');

  const buttonText = button || t('understood');

  const lottieOptions = useMemo(
    () => ({
      loop: false,
      autoplay: true,
      animationData: type === 'success' ? DoneLottie : AttentionLottie,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }),
    [type],
  );

  const cardStyle = type === 'success' ? styles['card-success'] : styles.card;

  return (
    <Card
      className={classes(cardStyle, className)}
      dataTest={`${dataTest}-card`}
    >
      <div className={classes([styles.content, contentClassName])}>
        <div
          className={styles['lottie-wrapper']}
          data-test={`${dataTest}-icon-wrapper`}
        >
          {icon || <Lottie options={lottieOptions} />}
        </div>
        {heading && (
          <Heading
            className={styles.heading}
            size="l"
            dataTest={`${dataTest}-heading`}
          >
            {heading}
          </Heading>
        )}
        {titleDescription && (
          <Paragraph className={classes([styles['title-description']])}>
            <span
              dangerouslySetInnerHTML={{ __html: titleDescription }}
              data-test={`${dataTest}-title-description`}
            />
          </Paragraph>
        )}
        {description && (
          <Paragraph
            className={classes([styles.description, descriptionClassName])}
            type="secondary"
          >
            <span
              dangerouslySetInnerHTML={{ __html: description }}
              data-test={`${dataTest}-description`}
            />
          </Paragraph>
        )}
      </div>
      {onClick &&
        !hideButton &&
        (analyticsIsTrackable ? (
          <Tracked description={analyticsTrackingDescription}>
            <Button
              dataTest="continue"
              className={styles.button}
              onClick={onClick}
            >
              {buttonText}
            </Button>{' '}
          </Tracked>
        ) : (
          <Button
            dataTest="continue"
            className={styles.button}
            onClick={onClick}
            pending={pending}
          >
            {buttonText}
          </Button>
        ))}
      {children}
    </Card>
  );
});
