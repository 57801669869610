import { datadogLogs } from '@datadog/browser-logs';
import { getProcessEnvUrl } from '../utils/getUrl';
import { useAuth } from './webapi';
import { useTranslation } from 'react-i18next';
import { ValidationError } from '../utils';
import fetchData from '../utils/fetchData';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const FINGERPRINT_CONSTANTS_KEYS = {
  codeId: 'X-Code-ID',
  deviceId: 'X-Device-Id',
  verificationId: 'X-Verification-ID',
};

export const useFingerprint = () => {
  const { t } = useTranslation();
  const { setLoginAuth } = useAuth();

  const getDeviceSessionId = () =>
    sessionStorage.getItem(FINGERPRINT_CONSTANTS_KEYS.deviceId);
  const setDeviceSessionId = (deviceId: string) => {
    sessionStorage.setItem(FINGERPRINT_CONSTANTS_KEYS.deviceId, deviceId);
  };

  const getVerificationSessionId = () =>
    sessionStorage.getItem(FINGERPRINT_CONSTANTS_KEYS.verificationId);
  const setVerificationSessionId = (verificationId: string) => {
    sessionStorage.setItem(
      FINGERPRINT_CONSTANTS_KEYS.verificationId,
      verificationId,
    );
  };

  const getCodeSessionId = () =>
    sessionStorage.getItem(FINGERPRINT_CONSTANTS_KEYS.codeId);
  const setCodeSessionId = (codeId: string) => {
    sessionStorage.setItem(FINGERPRINT_CONSTANTS_KEYS.codeId, codeId);
  };

  const clearFingerprintSessionKeys = () => {
    sessionStorage.removeItem(FINGERPRINT_CONSTANTS_KEYS.deviceId);
    sessionStorage.removeItem(FINGERPRINT_CONSTANTS_KEYS.verificationId);
    sessionStorage.removeItem(FINGERPRINT_CONSTANTS_KEYS.codeId);
    sessionStorage.removeItem('email'); // used to generate a client device id
  };

  const getDeviceId = async () => {
    const deviceId = getDeviceSessionId();
    if (deviceId) return deviceId;

    const email = sessionStorage.getItem('email');

    const { visitorId } = await FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => result);

    if (!email) {
      setDeviceSessionId(visitorId);
      return visitorId;
    }

    // Hash email
    const buffer = new TextEncoder().encode(email);
    const emailHashed = await crypto.subtle.digest('SHA-1', buffer);
    const hashArray = Array.from(new Uint8Array(emailHashed)); // convert buffer to byte array
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join(''); // convert bytes to hex string

    const halfOfHashed = hashHex.substring(
      hashHex.length / 2,
      hashHex.length - 1,
    );

    const encodedDeviceId = halfOfHashed + visitorId;

    setDeviceSessionId(encodedDeviceId);
    return encodedDeviceId;
  };

  const getCodeId = async (forceNewCode = false) => {
    const sessionCodeId = getCodeSessionId();
    if (!forceNewCode && sessionCodeId) return sessionCodeId;

    const deviceId = getDeviceSessionId();

    try {
      const { codeId, maskedPhoneNumber } = await fetchData('/2fa/SMS', {
        method: 'post',
        headers: {
          [FINGERPRINT_CONSTANTS_KEYS.deviceId]: deviceId || '',
          [FINGERPRINT_CONSTANTS_KEYS.verificationId]:
            getVerificationSessionId() || '',
        },
      });
      sessionStorage.setItem('maskedPhoneNumber', maskedPhoneNumber);

      setCodeSessionId(codeId);
    } catch {
      return false;
    }
  };

  const registerTrustDeviceAndLogin = async (code: string) => {
    const path = '/2fa/code';

    try {
      const codeId = await getCodeId();
      const deviceId = getDeviceSessionId();
      const verificationId = getVerificationSessionId();

      const { token, fromTemporaryPassword, username } = await fetchData(path, {
        method: 'post',
        headers: {
          [FINGERPRINT_CONSTANTS_KEYS.deviceId]: deviceId || '',
          [FINGERPRINT_CONSTANTS_KEYS.verificationId]: verificationId || '',
          [FINGERPRINT_CONSTANTS_KEYS.codeId]: codeId || '',
        },
        body: JSON.stringify({ code }),
      });

      const authorizationToken = token ? btoa(`${username}:${token}`) : null;

      if (authorizationToken) {
        setLoginAuth(authorizationToken);
        sessionStorage.setItem('token', authorizationToken);
        sessionStorage.setItem('fromTemporaryPassword', fromTemporaryPassword);
      }
      clearFingerprintSessionKeys();
      return { fromTemporaryPassword };
    } catch (exception) {
      const url = `${getProcessEnvUrl('WEB_API')}${path}`;
      datadogLogs.logger.error(`request: ${path} response parse`, {
        request_url: url,
        error: exception,
      });

      if (
        !(exception instanceof ValidationError) &&
        exception instanceof Error
      ) {
        const message =
          exception.message && exception.message !== 'Failed to fetch'
            ? exception.message
            : t('error:generic');
        throw Error(message);
      }
      throw exception;
    }
  };

  return {
    clearFingerprintSessionKeys,
    getCodeId,
    getCodeSessionId,
    getDeviceId,
    getVerificationSessionId,
    registerTrustDeviceAndLogin,
    setVerificationSessionId,
  };
};
