import classes from 'classnames';
import styles from './Loader.module.scss';

type Props = {
  className?: string;
  circleClassName?: string;
};

export default function Loader({ className, circleClassName }: Props) {
  return (
    <div
      className={classes(styles.wrapper, className)}
      data-test="loader-wrapper"
    >
      <div className={styles.loader} data-test="loader">
        <div
          className={classes(styles.circle, circleClassName)}
          data-test="loader-circle-1"
        />
        <div
          className={classes(styles.circle, circleClassName)}
          data-test="loader-circle-2"
        />
      </div>
    </div>
  );
}
