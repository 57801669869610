import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { JUICY_SCRIPT, useJuicy } from './useJuicy';
import { useSeon } from '../useSeon';
import fetchData from '../../utils/fetchData';
import pMemoize from 'p-memoize';
import useError from '../useError';

const memoFetchData = pMemoize(fetchData, { maxAge: 1000 });

type Props = {
  children: ReactNode;
};

export type Application = {
  amount: number;
  confirmed: boolean;
  created: string;
  expired: boolean;
  id: number;
  pendingProcessing: boolean;
  status: 'NEW' | 'OPEN' | 'CLOSED';
  term: number;
  type:
    | 'SMS'
    | 'WEB'
    | 'MOBILE'
    | 'PHONE'
    | 'OFFLINE_TRANSFER'
    | 'OFFLINE_CASH'
    | 'BROKER'
    | 'POS'
    | 'FINTONIC'
    | 'PRESTALO';
  productNumber: ProductNumber;
  resolutionDetail: string;
  resolutionMessage?: string;
  resolution: string;
  _embedded: {
    proposal?: {};
  };
};

type CreateApplicationOptions = {
  body: {
    affiliateInfo?: {
      provider?: string;
      partner?: string;
      token?: string;
      cookieCreated?: string;
    };
    productNumber?: ProductNumber;
    amount: number;
    term: number;
    monthlyPayment?: number;
    type?:
      | 'SMS'
      | 'WEB'
      | 'MOBILE'
      | 'PHONE'
      | 'OFFLINE_TRANSFER'
      | 'OFFLINE_CASH'
      | 'BROKER'
      | 'POS'
      | 'FINTONIC'
      | 'PRESTALO';
    source?:
      | 'DESKTOP'
      | 'MOBILE'
      | 'CALL_CENTER'
      | 'BROKER'
      | 'IOS'
      | 'ANDROID'
      | 'HYBRID_IOS'
      | 'HYBRID_ANDROID'
      | 'EXTRA_SERVICE_APPLICATION'
      | 'CRM'
      | 'WEB_OTHER_PRODUCT';
    autoRepay?: boolean; // Whether the loan should be auto-repaid automatically on due date
    typeDetail?:
      | 'MOBILE_APPLICATION'
      | 'ESHOP'
      | 'E_WALLET_ANDROID'
      | 'E_WALLET_IOS'
      | 'E_WALLET_BOON'
      | 'CALL_CENTER'
      | 'CAMPAIGN_DISTRIBUTED'
      | 'LEAD_GENERATOR'
      | 'EXTRA_SERVICE_APPLICATION'
      | 'MULTIFACTOR_AUTHENTIFICATION'
      | 'IOS'
      | 'HYBRID_IOS'
      | 'HYBRID_ANDROID'
      | 'ANDROID'
      | 'WEB_OFFER'
      | 'PHONE_OFFER'
      | 'AFFILIATE'
      | 'CRM'
      | 'E_MONEY'
      | 'MOBILE_APPLICATION_OFFER'
      | 'REFINANCE'
      | 'OFFLINE_OFFER';
    discountPercent?: number;
    newSourceVersion?: boolean; // Whether the application came from a new front end, used for data customAnalytics only
    partnerRepresentativeId?: number;
    ioBlackBox?: string;
    firstPaymentDueDate?: string;
    calculatorId?: number;
    customTermOffered?: boolean;
  };
  postApplicationCreated: (
    application: CreatedApplicationResponse,
  ) => Promise<void>;
};

export type CreatedApplicationResponse = {
  rejected: boolean;
};

type ApplicationContextType = {
  application?: Application | null;
  fetchApplication: () => Promise<Application | void>;
  createApplication: ({
    body,
  }: CreateApplicationOptions) => Promise<CreatedApplicationResponse | void>;
  confirmApplication: ({
    postApplicationConfirmed,
  }: {
    postApplicationConfirmed: () => Promise<void>;
  }) => Promise<ConfirmApplicationResponse | void>;
  acceptLatestTerms: () => Promise<void>;
};

type ConfirmApplicationResponse = {
  status: 'IN_PROGRESS';
};

const ApplicationContext = createContext<ApplicationContextType>(
  {} as ApplicationContextType,
);

const useApplication = (): ApplicationContextType =>
  useContext(ApplicationContext);

const ApplicationProvider = ({ children }: Props): JSX.Element => {
  const [application, setApplication] = useState<
    Application | undefined | null
  >();
  const { sendSeonFraudInfo } = useSeon();
  const { showError } = useError();
  const { generateJuicyApplicationSession } = useJuicy();

  // Create juicy session script - temporarily disabled until 403 error is solved
  useEffect(() => {
    if (!window.juicyScoreApi) {
      datadogLogs.logger.info('JuicyScore injected');
      const juicyScript = document.createElement('script');
      juicyScript.src = JUICY_SCRIPT;
      juicyScript.async = true;
      juicyScript.defer = true;
      juicyScript.id = 'juicyScript';
      document.head.appendChild(juicyScript);
    }
  }, []);

  const postApplicationConfirmedDone = useRef<boolean>(false);

  const fetchApplication =
    useCallback(async (): Promise<Application | void> => {
      try {
        const fetchedApplicationn = await memoFetchData('/client/application', {
          headers: {
            Accept: 'application/vnd.4finance.web.v1.hal+json',
          },
        });
        setApplication(fetchedApplicationn);
        return fetchedApplicationn;
      } catch (e) {
        setApplication(null);
        showError();
        throw e;
      }
    }, [showError]);

  const createApplication = useCallback(
    async ({
      body,
      postApplicationCreated,
    }: CreateApplicationOptions): Promise<CreatedApplicationResponse | void> => {
      try {
        const createdApplicationResponse = await fetchData(
          '/client/application',
          {
            method: 'post',
            body: JSON.stringify(body),
          },
        );

        const { clientLoanCount } = await fetchData('/client/loans');

        if (!clientLoanCount) {
          try {
            await sendSeonFraudInfo();
          } catch {}
        }

        setTimeout(async () => {
          if (!postApplicationConfirmedDone.current)
            postApplicationCreated(createdApplicationResponse);
        }, 12000);

        await generateJuicyApplicationSession();
        postApplicationConfirmedDone.current = true;

        postApplicationCreated(createdApplicationResponse);
      } catch (e) {
        showError();
        throw e;
      }
    },
    [showError],
  );

  const confirmApplication = useCallback(
    async ({
      postApplicationConfirmed,
    }): Promise<ConfirmApplicationResponse | void> => {
      try {
        const data = await fetchData('/client/application', {
          method: 'put',
          body: '{}',
        });

        setTimeout(async () => {
          if (!postApplicationConfirmedDone.current) postApplicationConfirmed();
        }, 12000);

        await generateJuicyApplicationSession();
        postApplicationConfirmedDone.current = true;
        postApplicationConfirmed();

        return data;
      } catch (e) {
        showError();
        throw e;
      }
    },
    [showError],
  );

  const acceptLatestTerms = useCallback(async () => {
    try {
      const data = await fetchData('/client/accept-latest-agreement-version', {
        method: 'post',
      });

      return data;
    } catch (e) {
      showError();
      throw e;
    }
  }, [showError]);

  const applicationContextValue = useMemo(
    () => ({
      application,
      fetchApplication,
      createApplication,
      confirmApplication,
      acceptLatestTerms,
    }),
    [
      application,
      fetchApplication,
      createApplication,
      confirmApplication,
      acceptLatestTerms,
    ],
  );

  return (
    <ApplicationContext.Provider value={applicationContextValue}>
      {children}
    </ApplicationContext.Provider>
  );
};

export { useApplication as default, ApplicationProvider };
