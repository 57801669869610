import * as R from '../Router/routes';
import { ClientDebtRestructuringData } from '../hooks/webapi/useClientDebtRestructuring';
import { LatestLoan } from '../hooks/webapi/useLatestLoan';

const getNextDRScreen = (
  debtRestructuringData?: ClientDebtRestructuringData | null | void,
  latestLoan?: LatestLoan | null | void,
) => {
  if (latestLoan?.debtCollectLoanAgreement) {
    return R.ACTIVE_LOAN;
  }

  if ((debtRestructuringData?.status || '').includes('ACTIVE')) {
    return R.DR_OVERDUE;
  }

  return R.ACTIVE_LOAN;
};

export { getNextDRScreen };
