import 'dayjs/locale/es';
import { datadogLogs } from '@datadog/browser-logs';
import { useEffect } from 'react';
import AppRouter from './Router/Router';
import dayjs from 'dayjs';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GA || '');

if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    env: process.env.REACT_APP_ENV,
    service: 'account-vivus-es',
  });
}

function App() {
  useEffect(() => {
    dayjs.locale('es');
  }, []);

  return <AppRouter />;
}

export default App;
