import * as R from './routes';
import { getNextDRScreen } from 'utils/drRedirection';
import { getShowBanner } from 'utils/voluntaryBanner';
import { isNotProduction } from 'utils/isNotProduction';
import { isRepaymentRouteDisabled } from 'hooks/useMenuItems';
import { isUserAffectedByDana } from 'pages/Dana/Dana';
import { ReactNode, useEffect, useState } from 'react';
import { Redirect } from '@reach/router';
import {
  useActiveCard,
  useApplication,
  useClient,
  useClientSettings,
  useLatestLoan,
} from 'hooks/webapi';
import { useActiveLoan } from 'pages/ActiveLoan/useActiveLoan';
import { useTranslation } from 'react-i18next';
import isLogged from 'utils/isLogged';
import styles from './GuardRoute.module.scss';
import useApplicationProposals from 'hooks/webapi/useApplicationProposals';
import useClientDebtRestructuring from 'hooks/webapi/useClientDebtRestructuring';

type Props = {
  children: ReactNode;
  path: string;
};

export default function GuardRoute({ children, path }: Props) {
  const { t } = useTranslation('error');
  const [isRouteAvailableFromBE, setIsRouteAvailableFromBE] = useState<
    boolean | undefined
  >(true);
  const [initialRouteChecked, setInitialRouteChecked] = useState<
    string | undefined
  >();

  const { activeCard } = useActiveCard();
  const { isOverdue, isBrokenAgreement } = useActiveLoan();
  const { debtRestructuringData, isDebtRestructuring, isPaymentReceived } =
    useClientDebtRestructuring();
  const { client } = useClient();
  const { clientSettings, fetchClientSettings } = useClientSettings();
  const { application } = useApplication();
  const { applicationProposals } = useApplicationProposals();
  const { latestLoan } = useLatestLoan();

  const dataRequiredMissing =
    latestLoan === undefined ||
    applicationProposals === undefined ||
    application === undefined ||
    client === undefined ||
    clientSettings === undefined ||
    debtRestructuringData === undefined;

  useEffect(() => {
    if (!clientSettings && isLogged()) fetchClientSettings();
  }, [clientSettings]);

  useEffect(() => {
    const checkBEConditions = () => {
      if (dataRequiredMissing) return true;

      const isExtension =
        latestLoan &&
        latestLoan.loanExtensionFees &&
        latestLoan.loanExtensionFees.length > 1;

      // Pending routes: EXTENSION_CARD_PAYMENT_REJECTED_DUE_DUPLICATE + CARD_PAYMENT_FAILURE_BY_CARD
      switch (path) {
        case R.ACTIVE_LOAN:
          return Boolean(latestLoan);
        case R.REPEATED_LOAN:
        case R.LOAN_CONFIRM:
          return Boolean(!latestLoan);
        case R.LOAN_CHECK_DETAILS:
          return Boolean(!latestLoan);
        case R.LOAN_APPROVED:
          return Boolean(
            application?.confirmed && application?.resolution === 'APPROVED',
          );
        case R.LOAN_FAILED:
        case R.LOAN_REJECTED:
          return Boolean(
            application?.confirmed && application?.resolution !== 'APPROVED',
          );
        case R.BEST_OFFER:
          const isBestOfferExpired =
            applicationProposals?.bestOfferProposal === null;
          return Boolean(
            !isBestOfferExpired &&
              application?.resolutionDetail === 'NEW_OFFER',
          );
        case R.ADDITIONAL_AMOUNT:
        case R.ADDITIONAL_AMOUNT_CONFIRM:
        case R.ADDITIONAL_AMOUNT_CHECK_DETAILS:
          return Boolean(
            client.additionalAmount && !latestLoan?.debtCollectLoanAgreement,
          );
        case R.ADDITIONAL_AMOUNT_APPROVED:
          return Boolean(application?.resolution === 'APPROVED');
        case R.ADDITIONAL_AMOUNT_FAILED:
        case R.ADDITIONAL_AMOUNT_REJECTED:
          return Boolean(application?.resolution !== 'APPROVED');
        case R.NEWS_CONSENT:
          return !clientSettings.acceptNews;
        case R.REPAYMENT:
          return !isRepaymentRouteDisabled(latestLoan, debtRestructuringData);
        case R.FULL_REPAYMENT:
          return (
            isOverdue &&
            !isBrokenAgreement &&
            !isRepaymentRouteDisabled(latestLoan, debtRestructuringData)
          );
        case R.REPAYMENT_WIRE_TRANSFER:
          return Boolean(latestLoan && !latestLoan.debtCollectLoanAgreement);
        case R.EXTENSION_WIRE_TRANSFER:
          return Boolean(
            latestLoan &&
              !latestLoan.debtCollectLoanAgreement &&
              latestLoan.loanExtensionFees &&
              latestLoan.loanExtensionFees.length,
          );
        case R.EXTENSIONS:
        case R.EXTENSION_PAYMENT:
        case R.EXTENSION_WITH_CARD:
        case R.EXTENSION_LIMITS_REJECTED:
        case R.EXTENSION_REJECTED:
        case R.EXTENSION_SUCCESS:
          return Boolean(isExtension);
        case R.INSTALLMENT_PAYMENT:
        case R.CARD_PAYMENT_CONS_SUCCESS:
        case R.CARD_PAYMENT_CONS_FAILURE:
          return latestLoan?.productCode === 'CONS' || isDebtRestructuring;
        case R.CARD_PAYMENT_MINI_SUCCESS:
          return latestLoan?.productCode === 'MINI';
        case R.DR_PAYMENT_SCHEDULE:
        case R.DR_OFFER_ACCEPTED:
          return isDebtRestructuring;
        case R.DR_OFFER:
          return (
            isDebtRestructuring && debtRestructuringData?.status === 'ELIGIBLE'
          );
        case R.DR_OFFER_CONS:
          return isDebtRestructuring && latestLoan?.productCode === 'CONS';
        case R.DR_OVERDUE:
          return isDebtRestructuring;
        case R.DR_PAYMENT:
          return isDebtRestructuring && !isPaymentReceived;
        case R.CHECK_DOCUMENTS:
        case R.ALTERNATIVE_DOCUMENTS_UPLOAD:
        case R.DOCUMENTS_PENDING:
        case R.DOCUMENTS_APPROVED:
          const voluntaryBannerAvailable = getShowBanner(client);
          return Boolean(!client.hasAttachments || voluntaryBannerAvailable);
        case R.REPAYMENT_BROKEN_LOAN:
          return Boolean(latestLoan) && isBrokenAgreement;
        case R.SIGN_DR_AGREEMENT:
          return Boolean(
            debtRestructuringData && !debtRestructuringData?.isSigned,
          );
        case R.SIGN_DR_AGREEMENT_SUCCESS:
          return Boolean(
            debtRestructuringData && debtRestructuringData?.isSigned,
          );
        case R.SAVE_CARD:
          return Boolean(!activeCard);
        case R.AUTOREPAY:
          return Boolean(activeCard);
        case R.BREAKDOWN_DEBT:
          return latestLoan?.productCode === 'CONS' && !isOverdue;
        case R.DANA:
          const isDanaUser = isUserAffectedByDana(client, latestLoan);
          return isDanaUser;
        case R.ONLINE_LOGIN:
        case R.LOGIN:
        case R.TWO_FACTOR_AUTH:
        case R.TWO_2FA_PHONE_MODIFIED:
        case R.CHANGE_PHONE_TWO_FACTOR_AUTH:
        case R.TWO_2FA_PHONE_ERROR:
        case R.ONE_TIME_TOKEN_LOGIN:
        case R.TWO_2FA_PHONE:
        case R.CARD_PAYMENT_REJECTED_DUE_DUPLICATE:
        case R.CARD_PAYMENT_FAILURE:
        case R.CARD_PAYMENT:
        case R.PROFILE:
        case R.PASSWORD_CHANGE:
        case R.PASSWORD_CHANGE_SUCCESS:
        case R.CHANGE_TEMPORARY_PASSWORD:
        case R.PASSWORD_RECOVERY:
        case R.PASSWORD_RECOVERY_SUCCESS:
        case R.FAQ:
          return true;
        default:
          return undefined;
      }
    };

    let nextIsRouteAvailableFromBE: boolean | undefined = true;
    if (initialRouteChecked !== path && !dataRequiredMissing) {
      nextIsRouteAvailableFromBE = checkBEConditions();
      if (nextIsRouteAvailableFromBE) setInitialRouteChecked(path);
    }

    if (isRouteAvailableFromBE !== nextIsRouteAvailableFromBE)
      setIsRouteAvailableFromBE(nextIsRouteAvailableFromBE);
  }, [
    client,
    clientSettings,
    application,
    applicationProposals,
    latestLoan,
    path,
  ]);

  if (isRouteAvailableFromBE !== false) {
    return (
      <div className={styles['wrapper-guardRoute']} key={path}>
        {isNotProduction() && isRouteAvailableFromBE === undefined && (
          <div className={styles.banner}>{t('routerBanner')}</div>
        )}
        {children}
      </div>
    );
  }

  const dashboardRedirectionRoute = Boolean(latestLoan)
    ? getNextDRScreen(debtRestructuringData, latestLoan)
    : R.REPEATED_LOAN;

  return <Redirect to={dashboardRedirectionRoute} noThrow />;
}
