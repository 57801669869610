import { Card, Heading } from '..';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import classes from 'classnames';
import React, { memo, ReactNode, useEffect, useRef } from 'react';
import styles from './Modal.module.scss';

export type Props = {
  onClose?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | MouseEvent,
  ) => void;
  children?: ReactNode;
  backdropClose?: boolean;
  className?: string;
  containerClassName?: string;
  dataTest?: string;
  heading?: string;
  closeClassName?: string;
  contentClassName?: string;
  wrapperClassName?: string;
};

export default memo(function Modal({
  onClose,
  children,
  backdropClose = true,
  className,
  containerClassName,
  dataTest = 'modal',
  heading,
  contentClassName,
  closeClassName,
  wrapperClassName,
}: Props) {
  const overlayRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (overlayRef.current && backdropClose) {
      overlayRef.current.addEventListener('mouseup', (event) => {
        if (onClose) {
          onClose(event);
        }
      });
    }
  }, [overlayRef, onClose, backdropClose]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('mouseup', (event) => {
        event.stopPropagation();
      });
    }
  }, [containerRef]);

  return (
    <div
      ref={overlayRef}
      className={classes([styles.overlay, wrapperClassName])}
      data-test={dataTest}
    >
      <div
        className={classes(styles.container, styles.fade, containerClassName)}
      >
        <Card className={classes(styles.card, className)} ref={containerRef}>
          <header>
            {heading && (
              <Heading
                className={styles.heading}
                size="s"
                dataTest={`${dataTest}-heading`}
              >
                {heading}
              </Heading>
            )}
            {onClose && (
              <button
                data-test="close-modal"
                onClick={onClose}
                className={classes(styles.close, closeClassName)}
                type="button"
              >
                <CloseIcon />
              </button>
            )}
          </header>
          <div
            className={classes(styles.content, contentClassName)}
            data-test={`${dataTest}-modal-content`}
          >
            {children}
          </div>
        </Card>
      </div>
    </div>
  );
});
