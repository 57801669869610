export function getClientProductNumber(
  availableProducts?: string[],
): ProductNumber | undefined {
  // @ts-ignore
  const clientProductNumberAccordingLogin: ProductNumber =
    sessionStorage.getItem('clientProductNameLogin');

  // Check we have all information
  if (!availableProducts) return;

  if (
    clientProductNumberAccordingLogin &&
    availableProducts.includes(clientProductNumberAccordingLogin)
  )
    return clientProductNumberAccordingLogin;
}
