import * as R from '../../Router/routes';
import { datadogLogs } from '@datadog/browser-logs';
import { ERROR_LOGIN_MESSAGE_QUERY_PARAM } from '../../customAnalytics/constants';
import { getProcessEnvUrl } from '../../utils/getUrl';
import { useCallback, useMemo } from 'react';
import fetchData from '../../utils/fetchData';
import useError from '../useError';

const useDocumentUpload = () => {
  const { showError } = useError();

  const documentUpload = useCallback(
    async ({ body }) => {
      try {
        const path = '/client/attachments';
        const url = `${getProcessEnvUrl('WEB_API')}${path}`;
        const response = await fetchData(
          path,
          {
            method: 'post',
            headers: {
              ...(sessionStorage.getItem('token') && {
                Authorization: `Basic ${sessionStorage.getItem('token')}`,
              }),
            },
            body,
          },
          false,
          true,
        );
        if (response.status === 401) {
          sessionStorage.removeItem('token');
          window.location.replace(
            `${R.LOGIN}?${ERROR_LOGIN_MESSAGE_QUERY_PARAM}=session_expired`,
          );
          return;
        }

        if (!response.ok && response.status) {
          try {
            const data = await response.json();

            datadogLogs.logger.error(`request: ${path}`, {
              request_url: url,
              error: data,
            });
          } catch (exception) {
            datadogLogs.logger.error(`request: ${path} response parse`, {
              request_url: url,
              error: exception,
            });
          }

          throw new Error(response.status.toString());
        }

        return response;
      } catch (e) {
        showError();
        throw e;
      }
    },
    [showError],
  );

  return useMemo(() => {
    return {
      documentUpload,
    };
  }, [documentUpload]);
};

export default useDocumentUpload;
