import * as R from '../../Router/routes';
import { ReactComponent as ChevronIcon } from '../../assets/icons/chevron-sharp.svg';
import { ReactComponent as HeaderIcon } from '../../assets/icons/header.svg';
import { ReactComponent as HeaderWhiteIcon } from '../../assets/icons/header-white.svg';
import { ReactComponent as Menu } from '../../assets/icons/menu.svg';

import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ReactComponent as LogoSmall } from '../../assets/icons/logo-small.svg';
import { ReactComponent as LogoWhite } from '../../assets/icons/logo-white.svg';
import { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { MenuItem } from '../MobileMenu/MobileMenu';
import { MobileMenu, Portal } from '..';
import { urlBundlePreloadHashMap } from '../../Router/Router';
import classes from 'classnames';
import Link from '../Link/Link';
import styles from './MobileHeader.module.scss';
import Touchable from '../Touchable/Touchable';
import Tracked from '../../customAnalytics/components/Tracked';
import useBreakpoint from '../../hooks/useBreakpoint';
import useLatestLoan from '../../hooks/webapi/useLatestLoan';

export type Props = {
  backUrl?: string;
  backUrlDisabled?: boolean;
  mobileHeading?: string | ReactNode;
  menuItems: MenuItem[];
  className?: string;
  onLogout?: () => void;
  clientName?: string;
  clientContract?: string;
  isSecondaryBackground?: boolean;
  isStaticMenu?: boolean;
  pending?: boolean;
  withMenu?: boolean;
};

export default memo(function MobileHeader({
  backUrl,
  backUrlDisabled,
  mobileHeading,
  menuItems,
  className,
  onLogout,
  clientName,
  clientContract,
  isSecondaryBackground = false,
  isStaticMenu = false,
  pending,
  withMenu = true,
}: Props) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { size } = useBreakpoint();
  const { latestLoan } = useLatestLoan();

  useEffect(() => {
    if (size.index > 2) {
      setIsMobileMenuOpen(false);
    }
  }, [size.index]);

  useEffect(() => {
    if (size.index <= 2) {
      if (backUrl) {
        if (urlBundlePreloadHashMap[backUrl]) {
          urlBundlePreloadHashMap[backUrl]();
        }
      } else {
        urlBundlePreloadHashMap[R.ACTIVE_LOAN]();
      }
    }
  }, [backUrl, size]);

  const handleMenuToggle = useCallback(() => {
    setIsMobileMenuOpen((prevState) => !prevState);
  }, []);

  const normalHeader = useCallback(
    () => (isStaticMenu ? <Menu /> : <HeaderIcon />),
    [isStaticMenu],
  );

  return (
    <>
      <header className={classes(styles.header, className)}>
        <div className={styles.item}>
          {backUrl ? (
            <Tracked description="Back arrow" contentOverride="<">
              <Link
                disabled={backUrlDisabled}
                to={backUrl}
                dataTest="mobile-back-link"
                isButtonPreferred={false}
              >
                <ChevronIcon
                  className={
                    isSecondaryBackground
                      ? styles['white-chevron']
                      : styles.chevron
                  }
                />
              </Link>
            </Tracked>
          ) : (
            <Tracked description="Back arrow" contentOverride="<logo>">
              <Link
                to={latestLoan ? R.ACTIVE_LOAN : R.REPEATED_LOAN}
                dataTest="mobile-back-link"
                isButtonPreferred={false}
              >
                {isSecondaryBackground ? (
                  <LogoWhite
                    data-test="mobile-logo"
                    className={styles['logo-small']}
                  />
                ) : (
                  !isStaticMenu && (
                    <LogoSmall
                      data-test="mobile-logo"
                      className={styles['logo-small']}
                    />
                  )
                )}
              </Link>
            </Tracked>
          )}
        </div>

        <div className={classes(styles.item, styles.center)}>
          {isStaticMenu ? (
            <Logo data-test="mobile-logo" />
          ) : (
            <div
              className={classes(
                styles['mobile-heading'],
                isSecondaryBackground && styles['white-color'],
              )}
              data-test="mobile-header-heading"
            >
              {mobileHeading}
            </div>
          )}
        </div>
        {withMenu && (
          <div
            className={classes(
              styles.item,
              styles.right,
              isStaticMenu && styles['static-button'],
            )}
          >
            <Tracked description="Toggle menu" contentOverride="<menu icon>">
              <Touchable
                className={styles['menu-button']}
                rounded
                onClick={handleMenuToggle}
              >
                {isSecondaryBackground ? (
                  <HeaderWhiteIcon data-test="mobileHeader-white-icon" />
                ) : (
                  normalHeader()
                )}
              </Touchable>
            </Tracked>
          </div>
        )}
      </header>

      {isMobileMenuOpen && (
        <Portal>
          <MobileMenu
            className={styles.menu}
            onClose={handleMenuToggle}
            menuItems={menuItems}
            active={window.location.pathname}
            clientName={clientName}
            clientContract={clientContract}
            onLogout={onLogout}
            isStaticMenu={isStaticMenu}
            pending={pending}
          />
        </Portal>
      )}
    </>
  );
});
