import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import fetchData from '../../utils/fetchData';

type Props = {
  children: ReactNode;
};

export type CardType = {
  number: string;
  id: number;
};

type ActiveCardContextType = {
  activeCard: CardType | undefined;
  fetchActiveCard: () => Promise<CardType | void>;
  fetchingCard: boolean;
};

const ActiveCardContext = createContext<ActiveCardContextType>(
  {} as ActiveCardContextType,
);

const useActiveCard = (): ActiveCardContextType =>
  useContext(ActiveCardContext);

const ActiveCardProvider = ({ children }: Props): JSX.Element => {
  const [activeCard, setActiveCard] = useState<CardType | undefined>();
  const [fetchingCard, setFetchingCard] = useState<boolean>(true);

  const fetchActiveCard = useCallback(async (): Promise<CardType | void> => {
    try {
      setFetchingCard(true);
      const fetchedActiveCard = await fetchData(
        '/client/cards/active',
        {},
        true,
      );
      setActiveCard(fetchedActiveCard);
      return fetchedActiveCard;
    } catch (e) {
      setActiveCard(undefined);
    } finally {
      setFetchingCard(false);
    }
  }, []);

  const activeCardContextValue = useMemo(
    () => ({
      activeCard,
      fetchActiveCard,
      fetchingCard,
    }),
    [activeCard, fetchingCard, fetchActiveCard],
  );

  return (
    <ActiveCardContext.Provider value={activeCardContextValue}>
      {children}
    </ActiveCardContext.Provider>
  );
};

export { useActiveCard as default, ActiveCardProvider };
