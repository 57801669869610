import { memo, ReactNode } from 'react';
import { Link as RouterLink } from '@reach/router';
import classes from 'classnames';
import styles from './Link.module.scss';

export type Props = {
  to?: string;
  children: ReactNode;
  disabled?: boolean;
  className?: string;
  external?: boolean;
  target?: '_blank' | '_self' | '_parent' | '_top';
  dataTest?: string;
  size?: 'l' | 'm' | 's' | 'xs';
  onClick?: () => void;
  onMouseEnter?: () => void;
  loading?: boolean;
  isButtonPreferred?: boolean;
};

export default memo(function Link({
  to = '',
  children,
  disabled = false,
  className,
  external = false,
  target = '_self',
  dataTest = 'link',
  size = 'l',
  onClick,
  onMouseEnter,
  loading,
  isButtonPreferred = true,
}: Props) {
  if (disabled || loading)
    return (
      <span
        onMouseEnter={onMouseEnter}
        data-test={dataTest}
        className={classes(
          styles.link,
          styles[size],
          disabled && styles.disabled,
          loading && styles.loading,
          className,
        )}
      >
        {children}
      </span>
    );

  if (onClick && isButtonPreferred) {
    return (
      <button
        onMouseEnter={onMouseEnter}
        type="button"
        onClick={onClick}
        data-test={dataTest}
        className={classes(styles.link, styles[size], className)}
      >
        {children}
      </button>
    );
  }

  if (external)
    return (
      <a
        onMouseEnter={onMouseEnter}
        onClick={onClick}
        data-test={dataTest}
        className={classes(styles.link, styles[size], className)}
        href={to}
        target={target}
        rel="noopener"
      >
        {children}
      </a>
    );

  return (
    <RouterLink
      onMouseEnter={onMouseEnter}
      data-test={dataTest}
      className={classes(styles.link, styles[size], className)}
      to={to}
    >
      {children}
    </RouterLink>
  );
});
