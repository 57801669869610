import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ValidationError } from '../../utils';
import fetchData from '../../utils/fetchData';
import pMemoize from 'p-memoize';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

export type PaymentScheduleStatus =
  | 'DELAYED'
  | 'PAID'
  | 'CANCELLED'
  | 'WRITTEN_OFF'
  | undefined;

export type PaymentSchedule = {
  invoicedInitialCommissionAmount: number;
  invoicedInterestAmount: number;
  items: Array<{
    commissionAmount: number;
    discountAmount: number;
    dueDate: string;
    initialCommissionAmount: number;
    initialCommissionDiscountAmount: number;
    interestAmount: number;
    interestDiscountAmount: number;
    invoicedCommissionAmount: number;
    invoicedInitialCommissionAmount: number;
    invoicedInterestAmount: number;
    invoicedPrincipalAmount: number;
    number: number;
    penaltyAmount: number;
    principalAmount: number;
    remainingPrincipal: number;
    startDate: string;
    totalAmount: number;
    totalDiscountAmount: number;
    status?: PaymentScheduleStatus;
  }>;
  // keys which we are adding on the FE
  activeInstalement: number;
  instalmentAmount: number;
};

type PaymentScheduleContextType = {
  paymentSchedule?: PaymentSchedule;
  fetchPaymentSchedule: () => Promise<PaymentSchedule | void>;
};

const memoFetchData = pMemoize(fetchData, { maxAge: 1000 });

function getActiveInstalment(array?: any[]) {
  if (!array) {
    return null;
  }

  const activeInstalementItem = array.find(
    (item) => !item.status || item.status === 'DELAYED',
  );

  if (activeInstalementItem) {
    return activeInstalementItem.number;
  }

  return null;
}

const PaymentScheduleContext = createContext<PaymentScheduleContextType>(
  {} as PaymentScheduleContextType,
);

const usePaymentSchedule = (): PaymentScheduleContextType =>
  useContext(PaymentScheduleContext);

const PaymentScheduleProvider = ({ children }: Props): JSX.Element => {
  const [paymentSchedule, setPaymentSchedule] = useState<PaymentSchedule>();
  const { showError } = useError();

  const fetchPaymentSchedule =
    useCallback(async (): Promise<PaymentSchedule | void> => {
      try {
        const fetchedPaymentSchedule = await memoFetchData(
          '/client/loans/latest/payment-schedule',
        );

        if (fetchedPaymentSchedule) {
          fetchedPaymentSchedule.activeInstalement = getActiveInstalment(
            fetchedPaymentSchedule.items,
          );
          fetchedPaymentSchedule.instalmentAmount =
            fetchedPaymentSchedule.items.length;
        }

        setPaymentSchedule(fetchedPaymentSchedule);
        return fetchedPaymentSchedule;
      } catch (e) {
        if ((e as ValidationError)?.message !== '404') showError();
        throw e;
      }
    }, [showError]);

  const paymentScheduleContextValue = useMemo(
    () => ({
      paymentSchedule,
      fetchPaymentSchedule,
    }),
    [paymentSchedule, fetchPaymentSchedule],
  );

  return (
    <PaymentScheduleContext.Provider value={paymentScheduleContextValue}>
      {children}
    </PaymentScheduleContext.Provider>
  );
};

export { usePaymentSchedule as default, PaymentScheduleProvider };
