import { Card } from '..';
import { ReactComponent as ChevronIcon } from '../../assets/icons/chevron-sharp.svg';
import { memo } from 'react';
import { numberToCurrency } from '../../utils/translate';
import { useTranslation } from 'react-i18next';
import classes from 'classnames';
import styles from './ExtensionSchedule.module.scss';

export type Props = {
  term: number;
  unit: 'DAYS' | 'MONTHS';
  fee: number;
  feeBeforeDiscount?: number;
  schedule?: Array<{
    currentDate: string;
    newDate: string;
  }>;
};

export default memo(function ExtensionSchedule({
  term,
  unit,
  fee,
  feeBeforeDiscount,
  schedule,
}: Props) {
  const { t } = useTranslation('extension_schedule');
  return (
    <Card className={styles.card} dataTest="extensionSchedule-card">
      <div className={styles.extension}>
        <span className={styles.term} data-test="extensionSchedule-card-term">
          {unit === 'DAYS'
            ? t('common:days_amount', { count: term })
            : t('common:months_amount', { count: term })}
        </span>
        <div data-test="extensionSchedule-card-feeBeforeDiscount">
          {feeBeforeDiscount && (
            <span className={styles['fee-before-discount']}>
              {numberToCurrency(feeBeforeDiscount)}
            </span>
          )}
          <span
            className={classes(
              classes(styles.fee, feeBeforeDiscount && styles.discounted),
            )}
            data-test="extensionSchedule-card-fee"
          >
            {numberToCurrency(fee)}
          </span>
        </div>
      </div>
      <div className={styles.schedule}>
        <div className={styles.header}>
          <span
            className={styles['header-date']}
            data-test="extensionSchedule-card-header-date"
          >
            {t('new_date')}
          </span>
        </div>
        {schedule?.map(({ currentDate, newDate }, index) => (
          <div
            className={styles['schedule-item']}
            key={currentDate}
            data-test={`extensionSchedule-${index}-row`}
          >
            <span
              className={styles['current-date']}
              data-test={`extensionSchedule-${index}-currentDate`}
            >
              {currentDate}
            </span>
            <ChevronIcon
              className={styles.arrow}
              data-test={`extensionSchedule-${index}-chevron-icon`}
            />
            <span
              className={styles['new-date']}
              data-test={`extensionSchedule-${index}-newDate`}
            >
              {newDate}
            </span>
          </div>
        ))}
      </div>
    </Card>
  );
});
