import * as R from '../Router/routes';
import { Client } from '../hooks/webapi/useClient';

const userIsNotIdentified = (client: Client | undefined) => {
  return (
    client?.validationResolutionDetail === 'PERSONAL_ID_MISSING' ||
    client?.validationResolutionDetail === 'EXPIRED_IDENTITY_DOCUMENT'
  );
};

const ROUTES_USER_NOT_IDENTIFIED = [
  R.LOGIN,
  R.TWO_FACTOR_AUTH,
  R.CHECK_DOCUMENTS,
  R.ALTERNATIVE_DOCUMENTS_UPLOAD,
  R.DOCUMENTS_PENDING,
  R.DOCUMENTS_APPROVED,
];

const ROUTE_REDIRECTION_USER_NOT_IDENTIFIED = R.CHECK_DOCUMENTS;

export {
  ROUTE_REDIRECTION_USER_NOT_IDENTIFIED,
  ROUTES_USER_NOT_IDENTIFIED,
  userIsNotIdentified,
};
