import dayjs from 'dayjs';
import i18Next from 'i18next';

export const NBSP_SYMBOL = '\xa0';
export const NUMBER_DELIMETER = ',';
export const NUMBER_GROUP_SIZE = 3;
export const NUMBER_GROUP_DELIMITER = '.';

export function roundNumber(
  number: number | undefined | null,
  precision: number = 2,
): number {
  if (!number) return 0;
  if (precision < 1) return number;

  const digitForPrecision = Math.pow(10, precision);

  return Math.round(number * digitForPrecision) / digitForPrecision;
}

export function numberToLocale(
  number: number | undefined | null,
  precision: number = 2,
  trailingZero: boolean = true,
): string {
  if (number === undefined || number === null || Number.isNaN(number)) {
    return '';
  }

  const numberString = String(number);
  const isInteger = Number.isInteger(number);
  const getNumberParts = () => {
    if (isInteger) {
      return [numberString, '00'];
    }

    if (trailingZero) {
      return number.toFixed(precision).split('.');
    }

    return numberString.split('.');
  };

  const numberParts = getNumberParts();
  const isNegative = number < 0;
  const integer = isNegative ? numberParts[0].substring(1) : numberParts[0];
  const numberOfIterations = Math.ceil(integer.length / NUMBER_GROUP_SIZE);
  let result = '';

  for (let i = integer.length, j = 1; i > 0; i -= NUMBER_GROUP_SIZE, j++) {
    const append = result;
    const prepend = integer.substring(i - NUMBER_GROUP_SIZE, i);

    if (prepend.length < NUMBER_GROUP_SIZE || j === numberOfIterations) {
      result = `${prepend}${append}`;
    } else {
      result = `${NUMBER_GROUP_DELIMITER}${prepend}${append}`;
    }
  }

  const fraction = numberParts[1];
  result += `${NUMBER_DELIMETER}${fraction.substring(0, precision)}`;

  if (isNegative) {
    result = `-${result}`;
  }

  return result;
}

export function numberWithSuffix(
  number: number,
  suffix: string,
  precision?: number,
): string {
  const prepend = numberToLocale(number, precision);

  return `${prepend}${NBSP_SYMBOL}${suffix}`;
}

export function numberToCurrency(number?: number, precision?: number): string {
  if (number === undefined) {
    return '';
  }

  const suffix = i18Next.t('common:currency');

  return numberWithSuffix(number, suffix, precision);
}

export function numberToPercent(number?: number, precision?: number): string {
  if (number === undefined) {
    return '';
  }

  return `${numberToLocale(number, precision, false)}%`;
}

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export function dateFormat(
  date: string | undefined,
  format: string = DEFAULT_DATE_FORMAT,
): string {
  if (date === undefined) {
    return '';
  }

  return dayjs(date).format(format);
}

export function existsTranslation(messageTranslated: string) {
  return !(
    !messageTranslated ||
    (messageTranslated.startsWith('{') && messageTranslated.endsWith('}'))
  );
}
