import { getNextDRScreen } from 'utils/drRedirection';
import { LoadableComponent } from '@loadable/component';
import { Redirect } from '@reach/router';
import { useClient, useLatestLoan } from '../hooks/webapi';
import { useEffect, useState } from 'react';
import GuardRoute from './GuardRoute';
import isLogged from 'utils/isLogged';
import useClientDebtRestructuring from 'hooks/webapi/useClientDebtRestructuring';

type Props = {
  component: LoadableComponent<any>;
  path: string;
  fallback: JSX.Element;
};

export default function PublicRoute({ component: Component, ...props }: Props) {
  const [shouldBeRedirected, setShouldBeRedirected] = useState(false);
  const { client } = useClient();
  const { debtRestructuringData } = useClientDebtRestructuring();
  const { latestLoan } = useLatestLoan();
  const basicInfoLoaded = Boolean(debtRestructuringData && client);

  const publicRouteAvailable =
    !isLogged() ||
    !basicInfoLoaded ||
    (client && client.status === 'REGISTERED');

  // 1 second to give login/twofa time for redirection
  useEffect(() => {
    if (!publicRouteAvailable) {
      setTimeout(() => {
        setShouldBeRedirected(true);
      }, 1000);
    }
  }, [publicRouteAvailable]);

  if (!shouldBeRedirected) {
    return (
      <GuardRoute path={props.path}>
        <Component {...props} />
      </GuardRoute>
    );
  }

  const dashboardRoute = getNextDRScreen(debtRestructuringData, latestLoan);

  return <Redirect to={dashboardRoute} noThrow />;
}
