import * as R from '../../Router/routes';
import { Application } from './useApplication';
import { Client } from './useClient';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { ERROR_LOGIN_MESSAGE_QUERY_PARAM } from '../../customAnalytics/constants';
import { FINGERPRINT_CONSTANTS_KEYS, useFingerprint } from '../useFingerprint';
import {
  getCalculatorUrl,
  getLoginUrl,
  getProcessEnvUrl,
} from '../../utils/getUrl';
import { getClientProductNumber } from '../../utils/getProductNumber';
import {
  getShiftedDatetime,
  getShiftedDatetimeHeader,
  SHIFTED_DATETIME,
} from '../../utils/shiftedDateTime';
import { isSoldDebtFromErrors } from '../../utils/soldDebtErrorInfo';
import { useTranslation } from 'react-i18next';
import { ValidationError } from '../../utils';
import fetchData, { defaultOptions, fetchRetry } from '../../utils/fetchData';
import getPhone from '../../utils/getPhone';
import isLogged from 'utils/isLogged';

type Props = {
  children: ReactNode;
};

type LoginResponse = {
  fromTemporaryPassword?: boolean;
  accessVerificationRequired?: boolean;
  authorizationToken: string | null;
};

type CrossLoginParams = {
  invalidateSession?: boolean;
  application?: Application | null;
  client?: Client;
};

type AuthContextType = {
  login: (email: string, password: string) => Promise<LoginResponse>;
  logout: () => Promise<void>;
  crossLoginRedirect: (params?: CrossLoginParams) => void;
  crossLoginAuth: (token: string) => Promise<LoginResponse>;
  clearStorage: () => void;
  loginAuth: string;
  setLoginAuth: (token: string) => void;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const useAuth = (): AuthContextType => useContext(AuthContext);

const AuthProvider = ({ children }: Props): JSX.Element => {
  const [loginAuth, setLoginAuth] = useState('');
  const {
    clearFingerprintSessionKeys,
    getDeviceId,
    getCodeId,
    setVerificationSessionId,
  } = useFingerprint();
  const { t } = useTranslation();

  const login = useCallback(async (email: string, password: string) => {
    try {
      const encodedCredentials = btoa(`${email}:${password}`);
      const path = '/token/basic';
      const url = `${getProcessEnvUrl('WEB_API')}${path}`;

      sessionStorage.setItem('email', email);

      const deviceId = await getDeviceId();

      const temporalToken = `Basic ${encodedCredentials}`;

      const res = await fetchRetry(url, {
        ...defaultOptions,
        method: 'post',
        headers: {
          ...getShiftedDatetimeHeader(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: temporalToken,
          [FINGERPRINT_CONSTANTS_KEYS.deviceId]: deviceId,
        },
      });

      if (!res.ok) {
        let data;
        try {
          data = await res.json();

          datadogLogs.logger.error(`request: ${path}`, {
            request_url: url,
            error: data,
          });
        } catch (exception) {
          datadogLogs.logger.error(`request: ${path} response parse`, {
            request_url: url,
            error: exception,
          });
        }

        if (res.status === 401) {
          throw new Error(t('error:validation.unauthorized'));
        }

        if (res.status === 403) {
          const { message } = isSoldDebtFromErrors(data?.errors);

          const { phone, phoneLink } = getPhone(false);
          throw new Error(
            message || t('error:validation.session', { phone, phoneLink }),
          );
        }

        throw new Error(t('error:generic'));
      }

      const data = await res.json();

      const authorizationToken = data.token
        ? btoa(`${data.username}:${data.token}`)
        : null;

      sessionStorage.setItem(
        'fromTemporaryPassword',
        Boolean(data.fromTemporaryPassword).toString(),
      );

      if (authorizationToken) {
        sessionStorage.setItem('token', authorizationToken);
        clearFingerprintSessionKeys();
      }

      const accessVerificationRequired = Boolean(data.verificationId);

      if (accessVerificationRequired) {
        sessionStorage.setItem('maskedPhoneNumber', data.maskedPhoneNumber);
      }

      if (data.verificationId) {
        setVerificationSessionId(data.verificationId);
        await getCodeId();
      }

      setLoginAuth(encodedCredentials || authorizationToken || '');

      return {
        accessVerificationRequired,
        authorizationToken,
        fromTemporaryPassword: Boolean(data.fromTemporaryPassword),
      };
    } catch (e) {
      if (!(e instanceof ValidationError) && e instanceof Error) {
        const message =
          e.message && e.message !== 'Failed to fetch'
            ? e.message
            : t('error:generic');
        throw Error(message);
      }
      throw e;
    }
  }, []);

  const clearStorage = useCallback(() => {
    sessionStorage.removeItem('fromTemporaryPassword');
    sessionStorage.removeItem('maskedPhoneNumber');
    sessionStorage.removeItem('token');
  }, []);

  const logout = useCallback(
    async (redirect = true) => {
      try {
        if (isLogged()) {
          sessionStorage.removeItem('discountBannerShowed');

          await fetchData('/token/invalidate', {
            method: 'delete',
          });

          clearStorage();
        }
      } catch (e) {
        throw e;
      } finally {
        // If invalidate request fails for some reason, at least remove token
        clearStorage();

        if (redirect) {
          const loginUrl = getLoginUrl();
          const homeURL =
            process.env.NODE_ENV === 'development'
              ? loginUrl
              : getCalculatorUrl() || loginUrl;

          window.location.assign(homeURL);
        }
      }
    },
    [clearStorage],
  );

  const crossLoginAuth = useCallback(async (oneTimeToken: string) => {
    try {
      const { username, token, fromTemporaryPassword } = await fetchData(
        '/token/one-time',
        {
          method: 'post',
          body: JSON.stringify({
            oneTimeToken,
          }),
        },
      );

      const authorizationToken = btoa(`${username}:${token}`);

      sessionStorage.setItem('token', authorizationToken);
      sessionStorage.setItem('fromTemporaryPassword', fromTemporaryPassword);

      return {
        authorizationToken,
        fromTemporaryPassword,
      };
    } catch (e) {
      throw e;
    }
  }, []);

  const crossLoginRedirect = useCallback(
    async (params: CrossLoginParams = {}) => {
      const { application, invalidateSession = true } = params;

      try {
        const clientProductName =
          application?.status === 'OPEN'
            ? application.productNumber
            : getClientProductNumber(params.client?.availableProducts);

        const url =
          clientProductName === '6'
            ? getProcessEnvUrl('VIVUSONLINE_REGISTRATION_URL')
            : getProcessEnvUrl('VIVUS_REGISTRATION_URL');

        const { oneTimeToken } = await fetchData(
          '/client/security/one-time-token',
          {
            method: 'post',
          },
        );

        if (invalidateSession) {
          await logout(false);
        }

        const paramsUrl = new URLSearchParams(
          decodeURIComponent(window.location.search),
        );
        // remove these two query params because they are obsolete.
        paramsUrl.delete('token');
        paramsUrl.delete('productNumber');

        const queryString = paramsUrl.size ? `&${paramsUrl.toString()}` : '';

        const productNameQuery = sessionStorage.getItem(
          'clientProductNameLogin',
        )
          ? `&productNumber=${sessionStorage.getItem('clientProductNameLogin')}`
          : '';

        const sessionTime = getShiftedDatetime();
        const sessionTimeNumber =
          sessionTime && new Date(sessionTime).getTime();
        const timeQueryParam = sessionTimeNumber
          ? `&${SHIFTED_DATETIME}=${sessionTimeNumber}`
          : '';

        const searchParams = `token=${oneTimeToken}${queryString}${productNameQuery}${timeQueryParam}`;

        const redirectURL = `${url}/one-time-token-login?${searchParams}`;

        // disable cross-origin redirect for cypress
        if ((window as any).Cypress) {
          return console.log(redirectURL); // eslint-disable-line no-console
        }
        window.location.assign(redirectURL);
      } catch (e) {
        const loginUrlError = `${R.LOGIN}?${ERROR_LOGIN_MESSAGE_QUERY_PARAM}=register`;
        if (
          window.location.pathname + window.location.search !==
          loginUrlError
        ) {
          window.location.assign(loginUrlError);
        }
      }
    },
    [logout],
  );

  const profileContextValue = useMemo(
    () => ({
      login,
      logout,
      crossLoginRedirect,
      crossLoginAuth,
      clearStorage,
      loginAuth,
      setLoginAuth,
    }),
    [
      login,
      logout,
      crossLoginRedirect,
      crossLoginAuth,
      clearStorage,
      loginAuth,
      setLoginAuth,
    ],
  );

  return (
    <AuthContext.Provider value={profileContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export { useAuth as default, AuthProvider };
