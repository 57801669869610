import * as R from './routes';
import { handleGTMModalVisibility } from 'utils/gtmModal';
import { isNotProduction } from '../utils/isNotProduction';
import { Loader } from '../components';
import { Redirect, Router } from '@reach/router';
import { useEffect } from 'react';
import loadable from '@loadable/component';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import styles from './Router.module.scss';
import useSmartlook from '../hooks/useSmartlook';

export const AsyncDana = loadable(() => import('../pages/Dana/Dana'));

export const AsyncOneTimeTokenLogin = loadable(
  () => import('../pages/Login/OneTimeTokenLogin'),
);

export const AsyncBestOffer = loadable(
  () => import('../pages/BestOffer/BestOffer'),
);

export const AsyncLogin = loadable(() => import('../pages/Login/Login'));

export const AsyncChangePhoneTwoFactorAuth = loadable(
  () => import('../pages/TwoFAChangePhone/TwoFAChangePhone'),
);

export const AsyncTwoFactorAuth = loadable(
  () => import('../pages/Login/TwoFactorAuth'),
);

export const AsyncTwoFAPhoneError = loadable(
  () => import('../pages/TwoFAPhoneError/TwoFAPhoneError'),
);

export const AsyncTwo2FAPhoneModified = loadable(
  () => import('../pages/TwoFAPhoneModified/TwoFAPhoneModified'),
);

export const AsyncOnlineLogin = loadable(
  () => import('../pages/Login/OnlineLogin'),
);

export const AsyncActiveLoan = loadable(
  () => import('../pages/ActiveLoan/ActiveLoan'),
);
export const AsyncRepeatedLoan = loadable(
  () => import('../pages/RepeatedLoan/RepeatedLoan'),
);
export const AsyncLoanConfirm = loadable(
  () => import('../pages/LoanConfirm/LoanConfirm'),
);
export const AsyncRepayment = loadable(
  () => import('../pages/Repayment/Repayment'),
);
export const AsyncFullRepayment = loadable(
  () => import('../pages/FullRepayment/FullRepayment'),
);
export const AsyncRepaymentWireTransfer = loadable(
  () => import('../pages/RepaymentWireTransfer/RepaymentWireTransfer'),
);
export const AsyncExtensions = loadable(
  () => import('../pages/Extensions/Extensions'),
);
export const AsyncExtensionPayment = loadable(
  () => import('../pages/ExtensionPayment/ExtensionPayment'),
);
export const AsyncExtensionSuccess = loadable(
  () => import('../pages/ExtensionSuccess/ExtensionSuccess'),
);

export const AsyncRejectedOrFailure = loadable(
  () => import('../pages/RejectedOrFailure/RejectedOrFailure'),
);

export const AsyncCardPayment = loadable(
  () => import('../pages/CardPayment/CardPayment'),
);

export const AsyncCardPaymentConsSuccess = loadable(
  () => import('../pages/CardPayment/CardPaymentStatus/Cons/ConsSuccess'),
);

export const AsyncCardPaymentMiniSuccess = loadable(
  () => import('../pages/CardPayment/CardPaymentStatus/Mini/MiniSuccess'),
);

export const AsyncCheckDocuments = loadable(
  () => import('../pages/CheckDocuments/CheckDocuments'),
);

export const AsyncLoanCheckDetails = loadable(
  () => import('../pages/LoanCheckDetails/LoanCheckDetails'),
);

export const AsyncAdditionalAmount = loadable(
  () => import('../pages/AdditionalAmount/AdditionalAmount'),
);

export const AsyncAlternativeDocumentsUpload = loadable(
  () =>
    import('../pages/AlternativeDocumentsUpload/AlternativeDocumentsUpload'),
);

export const AsyncDocumentsPending = loadable(
  () => import('../pages/DocumentsPending/DocumentsPending'),
);

export const AsyncDocumentsApproved = loadable(
  () => import('../pages/DocumentsApproved/DocumentsApproved'),
);

export const AsyncProfile = loadable(() => import('../pages/Profile/Profile'));

export const AsyncExtensionCardPayment = loadable(
  () => import('../pages/ExtensionCardPayment/ExtensionCardPayment'),
);

export const AsyncLoanApproved = loadable(
  () => import('../pages/LoanApproved/LoanApproved'),
);

export const AsyncPasswordChangeSuccess = loadable(
  () => import('../pages/PasswordChangeSuccess/PasswordChangeSuccess'),
);

export const AsyncChangeTemporaryPassword = loadable(
  () => import('../pages/ChangeTemporaryPassword/ChangeTemporaryPassword'),
);

export const AsyncNotFound = loadable(
  () => import('../pages/NotFound/NotFound'),
);

export const AsyncNewsConsent = loadable(
  () => import('../pages/NewsConsent/NewsConsent'),
);

export const AsyncFAQ = loadable(() => import('../pages/FAQ/FAQ'));

export const AsyncPasswordChange = loadable(
  () => import('../pages/PasswordChange/PasswordChange'),
);

export const AsyncPasswordRecovery = loadable(
  () => import('../pages/PasswordRecovery/PasswordRecovery'),
);

export const AsyncSignDrAgreement = loadable(
  () => import('../pages/SignDrAgreement/SignDrAgreement'),
);

export const AsyncSignDrAgreementSuccess = loadable(
  () => import('../pages/SignDrAgreementSuccess/SignDrAgreementSuccess'),
);

export const AsyncPasswordRecoverySuccess = loadable(
  () => import('../pages/PasswordRecoverySuccess/PasswordRecoverySuccess'),
);

export const AsyncSaveCard = loadable(
  () => import('../pages/SaveCard/SaveCard'),
);

export const AsyncAutoRepay = loadable(
  () => import('../pages/AutoRepay/AutoRepay'),
);

export const AsyncCardPaymentOverdueBrokenLoan = loadable(
  () =>
    import(
      '../pages/CardPaymentOverdueBrokenLoan/CardPaymentOverdueBrokenLoan'
    ),
);

export const AsyncBreakdownDebt = loadable(
  () => import('../pages/BreakdownDebt/BreakdownDebt'),
);

export const AsyncInstallmentPayment = loadable(
  () => import('../pages/InstallmentPayment/InstallmentPayment'),
);

export const AsyncDROffer = loadable(
  () => import('../pages/DebtRestructuringScreen/Offer/Offer'),
);

export const AsyncDROfferCons = loadable(
  () => import('../pages/DebtRestructuringScreen/OfferCons/OfferCons'),
);

export const AsyncDROverdue = loadable(
  () => import('../pages/DebtRestructuringScreen/DROverdue/DROverdue'),
);

export const AsyncDRPayment = loadable(
  () => import('../pages/DRPayment/DRPayment'),
);

export const AsyncDROfferAccepted = loadable(
  () => import('../pages/DebtRestructuringScreen/OfferAccepted/OfferAccepted'),
);

export const AsyncDRPaymentSchedule = loadable(
  () => import('../pages/DRPaymentSchedule/DRPaymentSchedule'),
);

export const urlBundlePreloadHashMap: any = {
  [R.LOGIN]: AsyncLogin.preload,
  [R.TWO_FACTOR_AUTH]: AsyncTwoFactorAuth.preload,
  [R.TWO_2FA_PHONE_ERROR]: AsyncTwoFAPhoneError.preload,
  [R.ONLINE_LOGIN]: AsyncOnlineLogin.preload,
  [R.LOAN_CONFIRM]: AsyncLoanConfirm.preload,
  [R.ACTIVE_LOAN]: AsyncActiveLoan.preload,
  [R.PROFILE]: AsyncProfile.preload,
  [R.REPEATED_LOAN]: AsyncRepeatedLoan.preload,
  [R.REPAYMENT]: AsyncRepayment.preload,
  [R.FULL_REPAYMENT]: AsyncFullRepayment.preload,
  [R.EXTENSIONS]: AsyncExtensions.preload,
  [R.EXTENSION_PAYMENT]: AsyncExtensionPayment.preload,
  [R.EXTENSION_SUCCESS]: AsyncExtensionSuccess.preload,
  [R.EXTENSION_REJECTED]: AsyncRejectedOrFailure.preload,
  [R.EXTENSION_LIMITS_REJECTED]: AsyncRejectedOrFailure.preload,
  [R.CARD_PAYMENT]: AsyncCardPayment.preload,
  [R.CARD_PAYMENT_CONS_SUCCESS]: AsyncCardPaymentConsSuccess.preload,
  [R.CARD_PAYMENT_CONS_FAILURE]: AsyncRejectedOrFailure.preload,
  [R.CARD_PAYMENT_MINI_SUCCESS]: AsyncCardPaymentMiniSuccess.preload,
  [R.CARD_PAYMENT_FAILURE]: AsyncRejectedOrFailure.preload,
  [R.CHECK_DOCUMENTS]: AsyncCheckDocuments.preload,
  [R.LOAN_CHECK_DETAILS]: AsyncLoanCheckDetails.preload,
  [R.ALTERNATIVE_DOCUMENTS_UPLOAD]: AsyncAlternativeDocumentsUpload.preload,
  [R.DOCUMENTS_APPROVED]: AsyncDocumentsApproved.preload,
  [R.DOCUMENTS_PENDING]: AsyncDocumentsPending.preload,
  [R.EXTENSION_WITH_CARD]: AsyncExtensionCardPayment.preload,
  [R.ADDITIONAL_AMOUNT]: AsyncAdditionalAmount.preload,
  [R.ADDITIONAL_AMOUNT_REJECTED]: AsyncRejectedOrFailure.preload,
  [R.ADDITIONAL_AMOUNT_APPROVED]: AsyncLoanApproved.preload,
  [R.ADDITIONAL_AMOUNT_FAILED]: AsyncRejectedOrFailure.preload,
  [R.FAQ]: AsyncFAQ.preload,
  [R.CHANGE_TEMPORARY_PASSWORD]: AsyncChangeTemporaryPassword.preload,
  [R.NEWS_CONSENT]: AsyncNewsConsent.preload,
  [R.SAVE_CARD]: AsyncSaveCard.preload,
  [R.AUTOREPAY]: AsyncAutoRepay.preload,
  [R.REPAYMENT_BROKEN_LOAN]: AsyncCardPaymentOverdueBrokenLoan.preload,
  [R.BREAKDOWN_DEBT]: AsyncBreakdownDebt.preload,
  [R.INSTALLMENT_PAYMENT]: AsyncInstallmentPayment.preload,
  [R.DR_OFFER]: AsyncDROffer.preload,
  [R.DR_OVERDUE]: AsyncDROverdue.preload,
  [R.DR_PAYMENT]: AsyncDRPayment.preload,
  [R.DR_OFFER_ACCEPTED]: AsyncDROfferAccepted.preload,
  [R.DR_PAYMENT_SCHEDULE]: AsyncDRPaymentSchedule.preload,
  [R.LOAN_REJECTED]: AsyncRejectedOrFailure.preload,
  [R.LOAN_FAILED]: AsyncRejectedOrFailure.preload,
  [R.EXTENSION_REJECTED]: AsyncRejectedOrFailure.preload,
  [R.CARD_PAYMENT_REJECTED_DUE_DUPLICATE]: AsyncRejectedOrFailure.preload,
  [R.EXTENSION_CARD_PAYMENT_REJECTED_DUE_DUPLICATE]:
    AsyncRejectedOrFailure.preload,
  [R.EXTENSION_LIMITS_REJECTED]: AsyncRejectedOrFailure.preload,
  [R.DANA]: AsyncDana.preload,
};

export default function AppRouter() {
  const { identifyClient } = useSmartlook();

  // Smartlook Identify Client
  useEffect(() => {
    if (!isNotProduction()) identifyClient();
  }, [identifyClient]);

  useEffect(() => {
    handleGTMModalVisibility();
  }, [window.location.pathname]);

  return (
    <Router className={styles.wrapper}>
      {/* PublicRoute should not be used as redirect specified on login itself based on user state */}
      <PublicRoute
        fallback={<Loader />}
        component={AsyncLogin}
        path={R.LOGIN}
      />

      <PublicRoute
        fallback={<Loader />}
        component={AsyncTwoFactorAuth}
        path={R.TWO_FACTOR_AUTH}
      />

      <PublicRoute
        fallback={<Loader />}
        component={AsyncChangePhoneTwoFactorAuth}
        path={R.CHANGE_PHONE_TWO_FACTOR_AUTH}
      />

      <PublicRoute
        fallback={<Loader />}
        component={AsyncTwoFAPhoneError}
        path={R.TWO_2FA_PHONE_ERROR}
      />

      <PublicRoute
        fallback={<Loader />}
        component={AsyncTwo2FAPhoneModified}
        path={R.TWO_2FA_PHONE_MODIFIED}
      />

      <PublicRoute
        fallback={<Loader />}
        component={AsyncOnlineLogin}
        path={R.ONLINE_LOGIN}
      />

      <PublicRoute
        fallback={<Loader />}
        component={AsyncOneTimeTokenLogin}
        path={R.ONE_TIME_TOKEN_LOGIN}
      />

      <PrivateRoute
        component={AsyncPasswordChange}
        path={R.PASSWORD_CHANGE}
        fallback={<Loader />}
      />

      <PublicRoute
        fallback={<Loader />}
        component={AsyncPasswordRecovery}
        path={R.PASSWORD_RECOVERY}
      />

      <PublicRoute
        fallback={<Loader />}
        component={AsyncPasswordRecoverySuccess}
        path={R.PASSWORD_RECOVERY_SUCCESS}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncLoanConfirm}
        path={R.LOAN_CONFIRM}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncActiveLoan}
        path={R.ACTIVE_LOAN}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncProfile}
        path={R.PROFILE}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncRepeatedLoan}
        path={R.REPEATED_LOAN}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncRepayment}
        path={R.REPAYMENT}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncFullRepayment}
        path={R.FULL_REPAYMENT}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncRepaymentWireTransfer}
        path={R.REPAYMENT_WIRE_TRANSFER}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncRepaymentWireTransfer}
        path={R.EXTENSION_WIRE_TRANSFER}
        typeOfPayment="EXTENSION"
        backUrl={R.EXTENSION_PAYMENT}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncExtensions}
        path={R.EXTENSIONS}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncExtensionPayment}
        path={R.EXTENSION_PAYMENT}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncCheckDocuments}
        path={R.CHECK_DOCUMENTS}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncExtensionSuccess}
        path={R.EXTENSION_SUCCESS}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncAdditionalAmount}
        path={R.ADDITIONAL_AMOUNT}
      />
      <PrivateRoute
        fallback={<Loader />}
        component={AsyncLoanCheckDetails}
        path={R.LOAN_CHECK_DETAILS}
        typeOfLoan="MAIN"
      />
      <PrivateRoute
        fallback={<Loader />}
        component={AsyncLoanCheckDetails}
        path={R.ADDITIONAL_AMOUNT_CHECK_DETAILS}
        typeOfLoan="ADDITIONAL"
      />
      <PrivateRoute
        fallback={<Loader />}
        component={AsyncLoanConfirm}
        path={R.ADDITIONAL_AMOUNT_CONFIRM}
        typeOfLoan="ADDITIONAL"
        backUrl={R.ADDITIONAL_AMOUNT}
      />
      <PrivateRoute
        fallback={<Loader />}
        component={AsyncCardPayment}
        path={R.CARD_PAYMENT}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncAlternativeDocumentsUpload}
        path={R.ALTERNATIVE_DOCUMENTS_UPLOAD}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncCardPaymentConsSuccess}
        path={R.CARD_PAYMENT_CONS_SUCCESS}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncCardPaymentMiniSuccess}
        path={R.CARD_PAYMENT_MINI_SUCCESS}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncDocumentsApproved}
        path={R.DOCUMENTS_APPROVED}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncDocumentsPending}
        path={R.DOCUMENTS_PENDING}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncExtensionCardPayment}
        path={R.EXTENSION_WITH_CARD}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncLoanApproved}
        path={R.LOAN_APPROVED}
        typeOfLoan="MAIN"
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncLoanApproved}
        path={R.ADDITIONAL_AMOUNT_APPROVED}
        typeOfLoan="ADDITIONAL"
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncPasswordChangeSuccess}
        path={R.PASSWORD_CHANGE_SUCCESS}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncChangeTemporaryPassword}
        path={R.CHANGE_TEMPORARY_PASSWORD}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncNewsConsent}
        path={R.NEWS_CONSENT}
      />

      <PrivateRoute fallback={<Loader />} component={AsyncFAQ} path={R.FAQ} />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncBestOffer}
        path={R.BEST_OFFER}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncSignDrAgreement}
        path={R.SIGN_DR_AGREEMENT}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncSignDrAgreementSuccess}
        path={R.SIGN_DR_AGREEMENT_SUCCESS}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncSaveCard}
        path={R.SAVE_CARD}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncAutoRepay}
        path={R.AUTOREPAY}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncCardPaymentOverdueBrokenLoan}
        path={R.REPAYMENT_BROKEN_LOAN}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncBreakdownDebt}
        path={R.BREAKDOWN_DEBT}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncInstallmentPayment}
        path={R.INSTALLMENT_PAYMENT}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncDROffer}
        path={R.DR_OFFER}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncDROfferCons}
        path={R.DR_OFFER_CONS}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncDROverdue}
        path={R.DR_OVERDUE}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncDRPayment}
        path={R.DR_PAYMENT}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncDROfferAccepted}
        path={R.DR_OFFER_ACCEPTED}
      />

      <PrivateRoute
        fallback={<Loader />}
        component={AsyncDRPaymentSchedule}
        path={R.DR_PAYMENT_SCHEDULE}
      />
      {[
        R.LOAN_REJECTED,
        R.ADDITIONAL_AMOUNT_REJECTED,
        R.LOAN_FAILED,
        R.ADDITIONAL_AMOUNT_FAILED,
        R.EXTENSION_REJECTED,
        R.CARD_PAYMENT_FAILURE,
        R.CARD_PAYMENT_CONS_FAILURE,
        R.CARD_PAYMENT_REJECTED_DUE_DUPLICATE,
        R.EXTENSION_CARD_PAYMENT_REJECTED_DUE_DUPLICATE,
        R.EXTENSION_LIMITS_REJECTED,
        R.CARD_PAYMENT_FAILURE_BY_CARD,
      ].map((route) => (
        <PrivateRoute
          fallback={<Loader />}
          component={AsyncRejectedOrFailure}
          path={route}
        />
      ))}

      <PrivateRoute fallback={<Loader />} component={AsyncDana} path={R.DANA} />

      <AsyncNotFound default />

      <Redirect from="/" to={R.LOGIN} noThrow />
    </Router>
  );
}
