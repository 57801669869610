import * as R from '../../Router/routes';
import {
  AsyncAdditionalAmount,
  AsyncExtensions,
  AsyncFullRepayment,
  AsyncRepayment,
} from '../../Router/Router';
import { DEFAULT_DATE_FORMAT } from '../../utils/translate';
import { useCallback, useEffect, useState } from 'react';
import {
  useLatestLoan,
  useLegalDocuments,
  usePaymentSchedule,
} from '../../hooks/webapi';
import { useLocation, useNavigate } from '@reach/router';
import dayjs from 'dayjs';
import isLogged from 'utils/isLogged';

export const useActiveLoan = () => {
  const { latestLoan, fetchLatestLoan, fetchLatestLoanInvoice } =
    useLatestLoan();
  const { fetchCurrentLegalTerms } = useLegalDocuments();
  const { paymentSchedule } = usePaymentSchedule();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  let latestLoanTimeout: NodeJS.Timeout;

  // Call only for initial render os some simple screens where only latestLoan is disabled
  useEffect(() => {
    const getLatestLoan = async () => {
      try {
        await fetchLatestLoan();
      } catch {}
    };

    const isNotRepeatedLoanFlow =
      !pathname.includes(R.REPEATED_LOAN) && pathname !== R.SAVE_CARD;

    if (!latestLoan && isLogged() && isNotRepeatedLoanFlow && false) {
      clearTimeout(latestLoanTimeout);
      latestLoanTimeout = setTimeout(() => {
        getLatestLoan();
      }, 1000);
    }
  }, [latestLoan]);

  /**
   * State
   */
  const [pendingInvoice, setPendingInvoice] = useState(false);
  const [isLoadingLegalTerms, setIsLoadingLegalTerms] = useState(false);
  const [legalTerms, setLegalTerms] = useState<string>(); // Holds html
  const [isLegalTermsOpen, setIsLegalTermsOpen] = useState(false);

  /**
   * Lifecycle
   */
  useEffect(() => {
    AsyncRepayment.preload();
    AsyncAdditionalAmount.preload();
    AsyncFullRepayment.preload();
    AsyncExtensions.preload();
  });

  /**
   * Functions
   */
  const navigateToRepayment = useCallback(() => {
    navigate(R.REPAYMENT);
  }, [navigate]);

  const fetchInvoice = useCallback(async () => {
    try {
      setPendingInvoice(true);

      await fetchLatestLoanInvoice();
    } catch {
    } finally {
      setPendingInvoice(false);
    }
  }, [fetchLatestLoanInvoice]);

  const downloadLegalTerms = useCallback(async () => {
    try {
      setIsLoadingLegalTerms(true);
      if (latestLoan) {
        const fetchedLegalTerms = await fetchCurrentLegalTerms();
        setLegalTerms(fetchedLegalTerms);
        setIsLegalTermsOpen(true);
      }
    } catch {
    } finally {
      setIsLoadingLegalTerms(false);
    }
  }, [fetchCurrentLegalTerms, latestLoan]);

  const closeLegalTerms = useCallback(() => {
    setIsLegalTermsOpen(false);
    setLegalTerms(undefined);
  }, []);
  const renderLegalTerms = useCallback(() => {
    return <div dangerouslySetInnerHTML={{ __html: legalTerms || '' }}></div>;
  }, [legalTerms]);

  const getNextPaymentDueDate = useCallback(() => {
    let nextPayment = paymentSchedule?.items.find(
      ({ status }) => status !== 'PAID',
    );

    // If all items are paid but penalties are pending
    if (!nextPayment && paymentSchedule?.items.length) {
      const lastElemPos = paymentSchedule?.items.length - 1;
      nextPayment = paymentSchedule?.items[lastElemPos];
    }

    return (
      nextPayment?.dueDate &&
      dayjs(nextPayment?.dueDate).format(DEFAULT_DATE_FORMAT)
    );
  }, [paymentSchedule]);

  /**
   * Computed Variables
   */
  const { overDueDays, productCode } = latestLoan || {};
  const is7CONS =
    latestLoan?.productCode === 'CONS' && latestLoan?.productNumber === '7';
  const isOverdue = Boolean(latestLoan && latestLoan.overDueDays > 0);
  const isBrokenAgreement =
    Boolean(latestLoan?.debtCollectLoanAgreement) ||
    Boolean(latestLoan && latestLoan.overDueDays > 30);

  const shouldHideInvoiceLink = Boolean(overDueDays && productCode === 'CONS'); // CWT-1633

  return {
    pendingInvoice,
    setPendingInvoice,
    isLoadingLegalTerms,
    setIsLoadingLegalTerms,
    legalTerms,
    setLegalTerms,
    isLegalTermsOpen,
    setIsLegalTermsOpen,
    navigateToRepayment,
    fetchInvoice,
    downloadLegalTerms,
    closeLegalTerms,
    renderLegalTerms,
    getNextPaymentDueDate,
    is7CONS,
    isOverdue,
    isBrokenAgreement,
    productCode,
    shouldHideInvoiceLink,
  };
};
