import { ActiveCardProvider } from './hooks/webapi/useActiveCard';
import { AffiliatesProvider } from './hooks/webapi/useAffiliates';
import { analyticsInstance } from './customAnalytics';
import { AnalyticsProvider } from 'use-analytics';
import { ApplicationProposalsProvider } from './hooks/webapi/useApplicationProposals';
import { ApplicationProvider } from './hooks/webapi/useApplication';
import { AuthProvider } from './hooks/webapi/useAuth';
import { BanksProvider } from './hooks/webapi/useBanks';
import { ClientBlockedStatusProvider } from './hooks/webapi/useClientBlockedStatus';
import { ClientConstraintsProvider } from './hooks/webapi/useClientConstraints';
import { ClientDebtRestructuringProvider } from './hooks/webapi/useClientDebtRestructuring';
import { ClientOfferProvider } from './hooks/webapi/useClientOffer';
import { ClientProvider } from './hooks/webapi/useClient';
import { ClientSettingsProvider } from './hooks/webapi/useClientSettings';
import { DiscountsProvider } from './hooks/webapi/useDiscounts';
import { ErrorProvider } from './hooks/useError';
import { HelmetProvider } from 'react-helmet-async';
import { isNotProduction } from './utils/isNotProduction';
import { LatestLoanProvider } from './hooks/webapi/useLatestLoan';
import { lazy, ReactNode, Suspense } from 'react';
import { OfferProvider } from './hooks/webapi/useOffer';
import { PaymentPreferencesProvider } from './hooks/webapi/usePaymentPreferences';
import { PaymentScheduleProvider } from './hooks/webapi/usePaymentSchedule';
import { PaymentsProvider } from './hooks/webapi/useCardPayments';
import { SignDrAgreementProvider } from './hooks/webapi/useSignDrAgreement';

const LazyBuildInfo = lazy(() => import('./utils/BuildInfo'));

type Props = {
  children: ReactNode;
};

function StateProvider({ children }: Props) {
  return (
    <>
      {isNotProduction() && (
        <Suspense fallback={null}>
          <LazyBuildInfo />
        </Suspense>
      )}
      <AnalyticsProvider instance={analyticsInstance}>
        <HelmetProvider>
          <ErrorProvider>
            <AuthProvider>
              <ClientProvider>
                <ClientSettingsProvider>
                  <LatestLoanProvider>
                    <PaymentScheduleProvider>
                      <ClientConstraintsProvider>
                        <ClientOfferProvider>
                          <PaymentsProvider>
                            <PaymentPreferencesProvider>
                              <ActiveCardProvider>
                                <ApplicationProvider>
                                  <ApplicationProposalsProvider>
                                    <ClientDebtRestructuringProvider>
                                      <BanksProvider>
                                        <ClientBlockedStatusProvider>
                                          <DiscountsProvider>
                                            <SignDrAgreementProvider>
                                              <OfferProvider>
                                                <AffiliatesProvider>
                                                  {children}
                                                </AffiliatesProvider>
                                              </OfferProvider>
                                            </SignDrAgreementProvider>
                                          </DiscountsProvider>
                                        </ClientBlockedStatusProvider>
                                      </BanksProvider>
                                    </ClientDebtRestructuringProvider>
                                  </ApplicationProposalsProvider>
                                </ApplicationProvider>
                              </ActiveCardProvider>
                            </PaymentPreferencesProvider>
                          </PaymentsProvider>
                        </ClientOfferProvider>
                      </ClientConstraintsProvider>
                    </PaymentScheduleProvider>
                  </LatestLoanProvider>
                </ClientSettingsProvider>
              </ClientProvider>
            </AuthProvider>
          </ErrorProvider>
        </HelmetProvider>
      </AnalyticsProvider>
    </>
  );
}

export default StateProvider;
