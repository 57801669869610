import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import Cookie from 'js-cookie';
import fetchData from '../../utils/fetchData';
import pMemoize from 'p-memoize';
import useError from '../useError';

const memoFetchData = pMemoize(fetchData, { maxAge: 1000 });

type Props = {
  children: ReactNode;
};

export type DebtRestructuringStatus =
  | 'ELIGIBLE'
  | 'NOT_ELIGIBLE'
  | 'ELIGIBLE_INITIATED'
  | 'IN_PROCESS'
  | 'IN_PROCESS_SIGNED'
  | 'IN_PROCESS_PAID'
  | 'ACTIVE'
  | 'ACTIVE_KEPT'
  | 'EXPIRED_BOTH'
  | 'EXPIRED_PAID'
  | 'EXPIRED_SIGNED'
  | 'BROKEN';

export type Client = {
  id: number;
  number: string;
  debtRestructuringCustomerAction:
    | 'REQUEST_TO_SIGN'
    | 'REQUEST_TO_PAY'
    | 'NO_ACTION';
  debtRestructuringPaymentReceived?: boolean;
  status: 'UNKNOWN' | 'REGISTERED' | 'IDENTIFIED';
  debtRestructuringStatus?: DebtRestructuringStatus;
  actualAddress: {
    postalCode: string;
  };
  declaredAddress: {
    fullAddress: string;
    postalCode: string;
    location1: string;
  };
  documentUploadStarted: boolean;
  mobilePhoneVerified: boolean;
  firstName: string;
  hasActiveIdDocument: boolean;
  lastName: string;
  fullName: string;
  personalId: string;
  hasAttachments: boolean;
  identityDocumentId: string;
  dateOfBirth: string;
  age: number;
  identifiedBy: 'BANK' | 'MANUAL' | 'ID_SERVICE' | 'BROKER';
  registeredBy: 'WEB' | 'OFFLINE' | 'BROKER' | 'CALL_CENTER';
  blocked: boolean;
  identified: boolean;
  bankAccount: string;
  openPrincipalAmount: number;
  homePhone: string;
  validationResolutionDetail:
    | 'PERSONAL_ID_MISSING'
    | 'EXPIRED_IDENTITY_DOCUMENT'
    | 'ERROR'
    | 'CHECK_PAYSLIP'; // NOTE: This is not the full enum, instead only the values currently used on FE side
  productNumber: ProductNumber;
  additionalAmount: boolean;
  creditLimit: number;
  totalCreditLimit: number;
  availableProducts: string[];
  loansCount: number;
  voidedLoansCount: number;
  hasOpenLoan: boolean;
  _embedded: {
    mobilePhone: {
      mobilePhone: string;
    };
    email: {
      email: string;
    };
  };
};

export type ClientPhone = {
  mobilePhone: string;
};

export type ClientEmail = {
  email: string;
};

type ClientContextType = {
  client: Client | undefined;
  email?: string;
  phone?: string;
  fetchBankAccount: () => Promise<string>;
  fetchClient: () => Promise<Client>;
  fetchClientPhone: () => Promise<ClientPhone>;
  fetchClientEmail: () => Promise<ClientEmail>;
  postAnalyticsCookies: () => Promise<void>;
  updateClientPhone: (
    newPhone: string,
  ) => Promise<{ verificationCode: string }>;
  confirmClientPhone: (
    webcode: string,
  ) => Promise<{ status: 'CONFIRMED' | 'REJECT'; message: string }>;
};

const ClientContext = createContext<ClientContextType>({} as ClientContextType);

const useClient = (): ClientContextType => useContext(ClientContext);

const ClientProvider = ({ children }: Props): JSX.Element => {
  const [client, setClient] = useState<Client | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [phone, setPhone] = useState<string | undefined>();
  const { showError } = useError();

  const fetchClient = useCallback(async (): Promise<Client> => {
    try {
      const fetchedClient = await memoFetchData('/client');
      setClient(fetchedClient);
      setPhone(fetchedClient?.mobilePhone?.mobilePhone);
      return fetchedClient;
    } catch (e) {
      showError();
      throw e;
    }
  }, [showError]);

  const fetchClientPhone = useCallback(async (): Promise<ClientPhone> => {
    try {
      const fetchedClientPhone = await memoFetchData('/client/phone');
      setPhone(fetchedClientPhone.mobilePhone);
      return fetchedClientPhone;
    } catch (e) {
      showError();
      throw e;
    }
  }, [showError]);

  const updateClientPhone = useCallback(
    async (newClientPhone: string) => {
      try {
        const response = await memoFetchData('/client/phone', {
          method: 'POST',
          body: JSON.stringify({
            mobilePhone: newClientPhone,
          }),
        });
        return response;
      } catch (e) {
        throw e;
      }
    },
    [showError],
  );

  const confirmClientPhone = useCallback(
    async (webCode: string) => {
      try {
        const response = await memoFetchData('/client/phone-confirmation', {
          method: 'PUT',
          body: JSON.stringify({
            verificationCode: webCode,
          }),
        });
        const { mobilePhone } = response;
        setPhone(mobilePhone);
        return response;
      } catch (e) {
        throw e;
      }
    },
    [showError],
  );

  const fetchClientEmail = useCallback(async (): Promise<ClientEmail> => {
    try {
      const fetchedClientEmail = await memoFetchData('/client/email');
      setEmail(fetchedClientEmail.email);
      return fetchedClientEmail;
    } catch (e) {
      showError();
      throw e;
    }
  }, [showError]);

  const fetchBankAccount = useCallback(async (): Promise<string> => {
    try {
      const { bankAccountNumber } = await memoFetchData(
        '/client/bank-account-number',
      );

      if (client) {
        setClient({
          ...client,
          bankAccount: bankAccountNumber,
        });
      }

      return bankAccountNumber;
    } catch (e) {
      showError();
      throw e;
    }
  }, [client, showError]);

  const getAnalyticsCookies = useCallback(() => {
    const analyticsCookies = [];

    const ga = Cookie.get('_ga');

    if (ga) {
      analyticsCookies.push({
        name: 'ga',
        value: ga,
      });
    }

    const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;
    if (!measurementId) {
      return analyticsCookies;
    }

    const [, secondPart] = measurementId.split('-');

    if (!secondPart) {
      return analyticsCookies;
    }

    const valueCookie = Cookie.get(`_ga_${secondPart}`);

    if (valueCookie) {
      analyticsCookies.push({
        name: `ga_${secondPart}`,
        value: valueCookie,
      });
    }

    return analyticsCookies;
  }, []);

  /** Used for tracking client analytic events within COLA */
  const postAnalyticsCookies = useCallback(async () => {
    const gaCookies = getAnalyticsCookies();

    if (gaCookies.length === 0) {
      return;
    }

    try {
      await fetchData('/client/cookies', {
        method: 'post',
        body: JSON.stringify(gaCookies),
        headers: {
          'Content-Type': 'application/vnd.analytics.v2+json',
        },
      });
    } catch (e) {
      showError();
    }
  }, [getAnalyticsCookies, showError]);

  const clientContextValue = useMemo(
    () => ({
      client,
      confirmClientPhone,
      phone,
      email,
      fetchBankAccount,
      fetchClient,
      postAnalyticsCookies,
      fetchClientEmail,
      fetchClientPhone,
      updateClientPhone,
    }),
    [
      client,
      confirmClientPhone,
      phone,
      email,
      fetchBankAccount,
      fetchClient,
      postAnalyticsCookies,
      updateClientPhone,
      fetchClientEmail,
      fetchClientPhone,
    ],
  );

  return (
    <ClientContext.Provider value={clientContextValue}>
      {children}
    </ClientContext.Provider>
  );
};

export { useClient as default, ClientProvider };
