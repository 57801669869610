import * as R from '../../Router/routes';
import { Client } from '../../hooks/webapi/useClient';
import { ReactComponent as HeartIcon } from '../../assets/icons/heart.svg';
import { Helmet } from 'react-helmet-async';
import { LatestLoan } from '../../hooks/webapi/useLatestLoan';
import { StatusCard } from '../../components';
import { useNavigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Flow from '../Layouts/Flow/Flow';
import styles from './Dana.module.scss';
import useClientDebtRestructuring from '../../hooks/webapi/useClientDebtRestructuring';

export function isUserAffectedByDana(
  client: Client | undefined,
  latestLoan: LatestLoan | null | undefined | void,
) {
  const postalCode =
    client?.actualAddress?.postalCode || client?.declaredAddress?.postalCode;

  const todayPlus5 = dayjs().add(5, 'days').format('YYYY-MM-DD');

  const availableLoan =
    latestLoan &&
    (latestLoan.overDueDays ||
      !dayjs(todayPlus5).isBefore(latestLoan.nearestPaymentDate));

  if (postalCode && postalCode.startsWith('46') && availableLoan) {
    return true;
  }
  return false;
}

export default function Dana() {
  const { t } = useTranslation('dana');
  const { isDebtRestructuring } = useClientDebtRestructuring();

  const navigate = useNavigate();

  const nextUrl = isDebtRestructuring ? R.DR_OFFER_ACCEPTED : R.ACTIVE_LOAN;

  const handleContinue = () => {
    navigate(nextUrl);
  };

  return (
    <Flow
      className={styles.container}
      mobileHeading={t('html_title')}
      withLogout
      withMenuDisabled
    >
      <Helmet>
        <title>{t('html_title')}</title>
      </Helmet>
      <StatusCard
        dataTest="dana-statusCard"
        button={t('button')}
        contentClassName={styles['content-status-card']}
        descriptionClassName={styles.description}
        icon={<HeartIcon className={styles.icon} />}
        heading={t('title')}
        description={t('description')}
        onClick={handleContinue}
        className={styles.card}
      />
    </Flow>
  );
}
