import * as R from '../../../Router/routes';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-full-right.svg';
import { ReactComponent as ChatIcon } from '../../../assets/icons/chat-v2.svg';
import { INSTALLMENT_PAYMENT } from '../../../Router/routes';
import { memo } from 'react';
import { useLatestLoan } from '../../../hooks/webapi';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import classes from 'classnames';
import getPhone from '../../../utils/getPhone';
import Link from '../../Link/Link';
import styles from './FooterOverdue.module.scss';

export type Props = {
  className?: string;
  dataTest?: string;
};

export default memo(function FooterOverdue({
  className,
  dataTest = 'footerOverdue',
}: Props) {
  const { t } = useTranslation('footerOverdue');
  const { latestLoan } = useLatestLoan();
  const location = useLocation();

  const { phone } = getPhone(true);

  const isCons7 =
    latestLoan?.productCode === 'CONS' && latestLoan?.productNumber === '7';

  const showPayLink = isCons7 && location.pathname === INSTALLMENT_PAYMENT;

  const footerLinkRoute = `${R.FULL_REPAYMENT}?backRoute=${location.pathname}`;

  return (
    <footer data-test={dataTest} className={classes(styles.footer, className)}>
      <ChatIcon data-test={`${dataTest}-chat-icon`} />
      <span className={styles.title} data-test={`${dataTest}-title`}>
        {t('title')}
      </span>
      <span className={styles['email-phone']} data-test={`${dataTest}-email`}>
        {t('email')}
      </span>
      <span className={styles['email-phone']} data-test={`${dataTest}-phone`}>
        {phone}
      </span>
      <span
        className={styles['work-hours']}
        data-test={`${dataTest}-work-hours`}
      >
        {t('work_hours_weekdays')}
      </span>
      <span
        className={styles['work-hours']}
        data-test={`${dataTest}-work-hours-weekend`}
      >
        {t('work_hours_weekends')}
      </span>
      {showPayLink && (
        <Link className={styles['pay-link']} to={footerLinkRoute}>
          <ArrowIcon />
          <span>{t('pay_full_loan')}</span>
        </Link>
      )}
    </footer>
  );
});
