import { ClientConstraints } from '../../hooks/webapi/useClientConstraints';
import { ClientOffer } from '../../hooks/webapi/useClientOffer';
import {
  getConstraintsAmountValue,
  getConstraintsTermDefaultValue,
} from 'utils/defaultValues';
import { InstalmentCard } from '..';
import { memo, useCallback, useEffect, useState } from 'react';
import { ReactComponent as NewLabelIcon } from '../../assets/icons/new-label.svg';
import { useTranslation } from 'react-i18next';
import classes from 'classnames';
import Slider from '../Slider/Slider';
import styles from './Calculator.module.scss';
import Swiper from '../Swiper/Swiper';
import useBreakpoint from '../../hooks/useBreakpoint';

export type Props = {
  onAmountChange: (value: number) => void;
  onTermChange: (value: number) => void;
  constraints: ClientConstraints;
  calculatorAmount?: number;
  calculatorTerm?: number;
  productCode?: 'MINI' | 'CONS';
  onProductCodeChange?: (productCode: 'MINI' | 'CONS') => void;
  offers?: Partial<ClientOffer>[];
  isLoadingOffer?: boolean;
  dataTest?: string;
  isConsEnabled?: boolean;
};

const Calculator = memo(function Calculator({
  onAmountChange,
  onTermChange,
  constraints,
  calculatorAmount,
  calculatorTerm,
  productCode = 'MINI',
  onProductCodeChange,
  offers,
  isLoadingOffer,
  dataTest,
  isConsEnabled,
}: Props) {
  const [isMobileSize, setIsMobileSize] = useState(false);
  const { t } = useTranslation('calculator');
  const { size } = useBreakpoint();

  let windowSizeTimeout: NodeJS.Timeout;

  const checkSwiperVisibility = () => {
    clearTimeout(windowSizeTimeout);
    windowSizeTimeout = setTimeout(() => {
      const nextIsMobileSize = window.innerWidth < 1024;
      if (isMobileSize !== nextIsMobileSize) {
        setIsMobileSize(nextIsMobileSize);
      }
    }, 250);
  };

  useEffect(() => {
    window.addEventListener('resize', checkSwiperVisibility);
    checkSwiperVisibility();
  }, []);

  const toggleProductType = useCallback(() => {
    if (onProductCodeChange) {
      onProductCodeChange(productCode === 'MINI' ? 'CONS' : 'MINI');
    }
    checkSwiperVisibility();
  }, [onProductCodeChange, productCode]);

  const handleAmountChange = useCallback(
    (amount: number) => {
      onAmountChange(amount);
    },
    [onAmountChange],
  );

  const constraintsAmountDefaultValue = getConstraintsAmountValue(constraints);
  const constraintsTermDefaultValue =
    getConstraintsTermDefaultValue(constraints);

  return (
    <div data-test={dataTest} className={styles['calculator-wrapper']}>
      <Slider
        dataTest="amount-slider"
        min={constraints.amountInterval.min}
        max={constraints.amountInterval.max}
        step={constraints.amountInterval.step}
        value={
          calculatorAmount !== undefined
            ? calculatorAmount
            : constraintsAmountDefaultValue
        }
        onChange={handleAmountChange}
        units={t('common:currency')}
        heading={t('amount_heading')}
        className={styles.slider}
      />
      {isMobileSize && (
        <Swiper
          key={size.name}
          dataTest="amount-swiper"
          min={constraints.amountInterval.min}
          max={constraints.amountInterval.max}
          step={constraints.amountInterval.step}
          defaultValue={
            calculatorAmount !== undefined
              ? calculatorAmount
              : constraintsAmountDefaultValue || 0
          }
          onChange={onAmountChange}
          snap={95}
          heading={t('amount_heading')}
          className={styles.swiper}
          unit={t('common:currency')}
        />
      )}

      {isConsEnabled && (
        <button
          data-test="product-toggle"
          className={styles.toggle}
          type="button"
          onClick={toggleProductType}
        >
          <span
            className={classes(
              styles['toggle-option'],
              productCode === 'MINI' && styles.active,
            )}
          >
            {t('mini')}
          </span>
          <span
            className={classes(
              styles['toggle-option'],
              productCode === 'CONS' && styles.active,
            )}
          >
            {t('cons')}
          </span>
          <NewLabelIcon className={styles['new-icon']} />
        </button>
      )}

      {productCode === 'MINI' ? (
        <>
          <Slider
            dataTest="term-slider"
            min={constraints.termInterval.min}
            max={constraints.termInterval.max}
            step={constraints.termInterval.step}
            value={
              calculatorTerm !== undefined
                ? calculatorTerm
                : constraintsTermDefaultValue
            }
            onAfterChange={onTermChange}
            units={t('term_unit')}
            heading={t('term_heading')}
            className={styles.slider}
          />

          {isMobileSize && (
            <Swiper
              key={size.name}
              dataTest="term-swiper"
              min={constraints.termInterval.min}
              max={constraints.termInterval.max}
              step={constraints.termInterval.step}
              defaultValue={
                calculatorTerm !== undefined
                  ? calculatorTerm
                  : constraints.termInterval.defaultValue
              }
              onChange={onTermChange}
              snap={75}
              heading={t('term_heading')}
              className={styles.swiper}
              unit={t('common:days', { count: calculatorTerm || 0 })}
              unitPosition="bottom"
            />
          )}
        </>
      ) : (
        <>
          <span className={styles['instalment-heading']}>
            {t('instalment_heading')}
          </span>
          <div className={styles['instalment-cards']}>
            {constraints.instalments.map(({ term, amountFrom }) => {
              const termOffer = offers?.find((offer) => offer.term === term);

              // Don't show installment if client's credit limit doesn't allow it
              if (amountFrom > constraints.amountInterval.max) return null;

              return (
                <div
                  key={`term-${term}`}
                  className={styles['instalment-card-container']}
                >
                  <InstalmentCard
                    onClick={onTermChange}
                    className={styles['instalment-card']}
                    monthlyPayment={termOffer?.monthlyPayment}
                    totalInterest={termOffer?.totalInterest}
                    totalRepayableAmount={termOffer?.totalRepayableAmount}
                    term={term}
                    isUnderLimit={
                      (calculatorAmount || constraintsAmountDefaultValue || 0) <
                      amountFrom
                    }
                    amountFrom={amountFrom}
                    active={
                      calculatorTerm !== undefined
                        ? calculatorTerm === term
                        : constraints.termInterval.min === term
                    }
                    isLoading={isLoadingOffer}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
});

export default Calculator;
