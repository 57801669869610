import { analyticsPlugin } from './plugins/mainPlugin';
import { Env } from './plugins/types';
import Analytics from 'analytics';

export const analyticsInstance = Analytics({
  app: 'dashboard-vivus-es',
  version: '1.0.0',
  plugins: [
    ...(process.env.REACT_APP_ANALYTICS_ENABLED === 'true' &&
    process.env.REACT_APP_ANALYTICS_API
      ? [
          analyticsPlugin({
            url: process.env.REACT_APP_ANALYTICS_API as string,
            app: {
              applicationType: 'repeated',
              env: process.env.REACT_APP_ENV as Env,
              type: 'web',
              version: '1.0.0',
            },
            dequeueInterval: 1000,
            onFetchError: (err) => {
              // eslint-disable-next-line no-console
              console.error(
                `Analytics event failed to fetch: ${JSON.stringify(
                  err,
                  null,
                  2,
                )}`,
              );
            },
          }),
        ]
      : []),
  ],
});
