import * as R from '../../Router/routes';
import { Link } from '..';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ReactComponent as Logout } from '../../assets/icons/logout.svg';
import { memo, ReactNode } from 'react';
import { Link as RouterLink } from '@reach/router';
import { urlBundlePreloadHashMap } from '../../Router/Router';
import { useTranslation } from 'react-i18next';
import classes from 'classnames';
import styles from './Sidebar.module.scss';
import useLatestLoan from '../../hooks/webapi/useLatestLoan';

type MenuItem = {
  title: string;
  url: string;
  icon?: ReactNode;
  disabled?: boolean;
  dataTest: string;
};

export type Props = {
  className?: string;
  wrapperClassName?: string;
  active: string;
  onSelect?: (url: string) => void;
  menuItems: MenuItem[];
  onLogout?: () => void;
  pending?: boolean;
};

function preloadAccount() {
  urlBundlePreloadHashMap[R.ACTIVE_LOAN]();
}

export default memo(function Sidebar({
  className,
  wrapperClassName,
  active,
  onSelect,
  menuItems,
  onLogout,
  pending,
}: Props) {
  const { t } = useTranslation('menu');
  const { latestLoan } = useLatestLoan();

  const handleClick = (url: string) => {
    if (onSelect) {
      onSelect(url);
    }
  };

  return (
    <div className={wrapperClassName}>
      <Link
        dataTest="sidebar-link"
        to={latestLoan ? R.ACTIVE_LOAN : R.REPEATED_LOAN}
        onMouseEnter={preloadAccount}
      >
        <Logo data-test="sidebar-logo" />
      </Link>
      <div className={classes(className, styles.container)}>
        {menuItems.map((menu, index) => {
          function preload() {
            if (urlBundlePreloadHashMap[menu.url]) {
              urlBundlePreloadHashMap[menu.url]();
            }
          }

          return (
            <RouterLink
              data-test={`sidebar-${menu.dataTest}`}
              className={classes(
                styles.item,
                active === menu.url && styles.selected,
                menu.disabled && styles.disabled,
              )}
              onClick={() => handleClick(menu.url)}
              to={menu.url}
              key={`${menu.url}-${index}`}
              onMouseEnter={preload}
            >
              <div
                className={classes(
                  styles.icon,
                  active === menu.url && styles['selected-icon'],
                )}
                data-test="sidebar-menu-icon"
              >
                {menu.icon}
              </div>
              <p
                className={classes(styles.title)}
                data-test="sidebar-menu-title"
              >
                {menu.title}
              </p>
            </RouterLink>
          );
        })}
      </div>
      {!!onLogout && (
        <Link
          size="s"
          onClick={onLogout}
          className={classes(styles.logout, pending && styles.disabled)}
        >
          <p data-test="sidebar-logout-text">{t('logout')}</p>
          <Logout data-test="sidebar-logout-icon" />
        </Link>
      )}
    </div>
  );
});
