import { ClientConstraints } from 'hooks/webapi/useClientConstraints';

export const getConstraintsAmountValue = (
  contraints: ClientConstraints | undefined | void,
) =>
  contraints?.amountInterval.initialValue ||
  contraints?.amountInterval.defaultValue;

export const getConstraintsTermDefaultValue = (
  contraints: ClientConstraints | undefined | void,
) =>
  contraints?.termInterval.initialValue ||
  contraints?.termInterval.defaultValue;
