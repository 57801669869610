import i18Next from 'i18next';

export const EMAIL_REGEXP = /^[\w-]([.\w-])*@([\w-]+\.)+[A-Za-z]{2,6}$/;
export const PHONE_REGEXP = /^\+?[67][0-9]{8,8}$/;

export const PHONE_REGEXP_NOT_ALLOWED = /^(6688|6686).*$/;

export const mobilePhoneNumberValid = (phone: string) =>
  PHONE_REGEXP.test(phone) && !PHONE_REGEXP_NOT_ALLOWED.test(phone);

type ValidationFunc = (value: any) => string | undefined;

export function applyAll(...validations: Array<ValidationFunc>) {
  return (value: any) => {
    const errors = validations.reduce((acc, cur) => {
      const err = cur(value);
      if (err) {
        return err + '-divider-' + acc;
      }
      return acc;
    }, '');

    return errors.length > 0 ? errors : undefined;
  };
}

export const isEmpty = (
  value: string | null | undefined | number | boolean,
): boolean => {
  if (typeof value === 'boolean') {
    if (!value) {
      return true;
    }
    return false;
  }

  if (value || value === 0) {
    if (String(value) === 'null' || String(value).length === 0) {
      return true;
    }
  } else {
    return true;
  }

  return false;
};

export const weakPassword = (
  value: string | null | undefined,
): string | undefined => {
  // Password min length 8 chars, must contain at least 1 number or special char, 1 Uppercase and 1 Lowercase
  const STRONG_PASS_REGEX =
    /^(?!.*([A-Za-z0-9])\1{2})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*])(?=.{8,})/;

  if (value && !STRONG_PASS_REGEX.test(value)) {
    return i18Next.t('error:validation.weak_password');
  }

  return;
};

export const hasSpaces = (
  value: string | null | undefined,
): string | undefined => {
  // Password must not contain any kind of whitespaces
  const HAS_SPACES_REGEX = /\s/;

  if (value && HAS_SPACES_REGEX.test(value)) {
    return i18Next.t('error:validation.password_contains_space');
  }

  return;
};

export const empty = (value: string | null | undefined | boolean) => {
  if (isEmpty(value)) {
    return i18Next.t('error:validation.empty_password');
  }

  return;
};

export const notEmail = (
  value: string | null | undefined,
): string | undefined => {
  if (!EMAIL_REGEXP.test(String(value))) {
    return i18Next.t('error:validation.not_email');
  }

  return;
};

export const validateDNI = (value: string) => {
  let emailError = false;
  if (notEmail(value)) {
    emailError = true;
  }
  const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
  const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
  const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
  const str = value.toString().toUpperCase();

  if (!nifRexp.test(str) && !nieRexp.test(str) && emailError)
    return i18Next.t('error:validation.not_email_nor_dni');

  const nie = str
    .replace(/^[X]/, '0')
    .replace(/^[Y]/, '1')
    .replace(/^[Z]/, '2');

  const letter = str.substr(-1);
  const charIndex = parseInt(nie.substr(0, 8)) % 23;

  if (validChars.charAt(charIndex) === letter) {
    return;
  }
};

export const samePassword = (
  password: string | undefined,
  repeatPassword: string | undefined,
) => {
  if (
    !password?.length ||
    !repeatPassword?.length ||
    password === repeatPassword
  )
    return;
  return i18Next.t('error:validation.new_passwords_is_different');
};
