import { memo, ReactNode } from 'react';
import classes from 'classnames';
import styles from './Paragraph.module.scss';

export type Props = {
  children: ReactNode;
  className?: string;
  dataTest?: string;
  type?: 'regular' | 'medium' | 'secondary' | 'small';
};

export default memo(function Paragraph({
  children,
  className,
  dataTest = 'paragraph',
  type = 'regular',
}: Props) {
  return (
    <p
      className={classes(styles.paragraph, styles[type], className)}
      data-test={dataTest}
    >
      {children}
    </p>
  );
});
