import {
  AsyncActiveLoan,
  AsyncAdditionalAmount,
  AsyncExtensions,
  AsyncProfile,
  AsyncRepeatedLoan,
} from '../../Router/Router';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { Footer, Heading, Link } from '..';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import { memo, ReactNode, useEffect } from 'react';
import { Link as RouterLink, useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import classes from 'classnames';
import styles from './MobileMenu.module.scss';
import Touchable from '../Touchable/Touchable';
import Tracked from '../../customAnalytics/components/Tracked';

export type MenuItem = {
  title: string;
  url: string;
  icon?: ReactNode;
  disabled?: boolean;
  dataTest: string;
};

export type Props = {
  className?: string;
  clientName?: string;
  clientContract?: string;
  active: string;
  onSelect?: (url: string) => void;
  menuItems: MenuItem[];
  onClose?: () => void;
  onLogout?: () => void;
  isStaticMenu?: boolean;
  pending?: boolean;
};

function preloadMobileMenuRoutes() {
  AsyncActiveLoan.preload();
  AsyncRepeatedLoan.preload();
  AsyncExtensions.preload();
  AsyncAdditionalAmount.preload();
  AsyncProfile.preload();
}

export default memo(function MobileMenu({
  className,
  active,
  clientName,
  clientContract,
  onSelect,
  menuItems,
  onClose,
  onLogout,
  isStaticMenu = false,
  pending,
}: Props) {
  const { t } = useTranslation('menu');
  const { pathname } = useLocation();

  useEffect(() => {
    preloadMobileMenuRoutes();
  }, []);

  // Prevent main body from scrolling when menu is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  });

  const handleClick = (url: string) => {
    if (url === pathname) {
      if (onClose) onClose();
    } else if (onSelect) {
      onSelect(url);
    }
  };

  return (
    <div className={classes(styles.container, className)}>
      <div className={styles.header}>
        {isStaticMenu && (
          <Logo className={styles.logo} data-test="mobileMenu-logo" />
        )}
        {onClose && (
          <div
            className={classes(
              styles.close,
              isStaticMenu && styles['static-close-icon'],
            )}
          >
            <Tracked description="Mobile menu closed" contentOverride="X">
              <Touchable onClick={onClose} rounded>
                <CloseIcon
                  className={styles['close-icon']}
                  data-test="mobileMenu-close-icon"
                />
              </Touchable>
            </Tracked>
          </div>
        )}
      </div>
      <div className={styles['scroll-box']}>
        <Heading
          className={styles.heading}
          dataTest="mobileMenu-heading-firstName"
        >
          {clientName}
        </Heading>
        <div className={styles.menu}>
          {menuItems.map((menu, index) =>
            !isStaticMenu ? (
              <RouterLink
                data-test={`mobile-menu-${menu.dataTest}`}
                className={classes(
                  styles.item,
                  active === menu.url && styles.selected,
                  menu.disabled && styles.disabled,
                )}
                to={menu.url}
                onClick={() => handleClick(menu.url)}
                key={`${menu.url}-${index}`}
              >
                <div
                  className={classes(
                    styles.icon,
                    active === menu.url && styles['selected-icon'],
                  )}
                  data-test={`mobile-menuIcon-${menu.dataTest}`}
                >
                  {menu.icon}
                </div>
                <p
                  className={classes(styles.title)}
                  data-test={`mobile-menuTitle-${menu.dataTest}`}
                >
                  {menu.title}
                </p>
              </RouterLink>
            ) : (
              <Link
                dataTest={`mobile-menu-${menu.dataTest}`}
                external
                className={styles.item}
                to={menu.url}
              >
                <p className={classes(styles.title)}>{menu.title}</p>
              </Link>
            ),
          )}
        </div>
        {!isStaticMenu && (
          <Tracked description="Logout link">
            <Link
              dataTest="mobileMenu-link-logout"
              disabled={pending}
              onClick={onLogout}
              className={classes(styles.logout)}
            >
              <p>{t('logout')}</p>
              <LogoutIcon data-test="mobileMenu-logout-icon" />
            </Link>
          </Tracked>
        )}
        <Footer />
      </div>
    </div>
  );
});
