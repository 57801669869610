import { ReactComponent as CheckIcon } from '../../assets/icons/check-circle.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error-circle.svg';
import { memo } from 'react';
import classes from 'classnames';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import HeadShake from 'react-reveal-effects/HeadShake';
import styles from './Select.module.scss';

export type Props = {
  disabled?: boolean;
  value?: string | number;
  name?: string;
  error?: string;
  isTouched?: boolean;
  errorAnimationTrigger?: number; // Pass a different value to re-trigger error animaiton
  placeholder?: string;
  dataTest?: string;
  options?: Array<{ name: string; value: string | number }>;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
};

export default memo(function Select({
  disabled,
  value,
  name,
  error,
  isTouched,
  errorAnimationTrigger,
  placeholder,
  dataTest,
  options,
  onChange,
  onBlur,
}: Props) {
  const showError = isTouched && error;
  const showCheck = isTouched && !error;

  return (
    <div className={styles.container}>
      <div className={styles.field}>
        <select
          defaultValue={value || ''}
          disabled={disabled}
          name={name}
          data-test={dataTest}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          className={styles.select}
        >
          <option disabled />
          {options?.map(({ name, value }) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </select>

        <label
          className={classes(
            styles.label,
            (value || '').toString().length && styles.float,
          )}
        >
          {placeholder}
        </label>

        <div className={styles.badges}>
          {(showError || showCheck) && <div className={styles.divider} />}
          {showError && (
            <HeadShake
              spy={errorAnimationTrigger ? errorAnimationTrigger : undefined}
            >
              <ErrorIcon
                className={classes(styles.badge, styles['error-icon'])}
              />
            </HeadShake>
          )}
          {showCheck && <CheckIcon className={styles.badge} />}
        </div>
      </div>
      {showError && (
        <div className={styles['error-message']}>
          <ErrorMessage errors={error} />
        </div>
      )}
    </div>
  );
});
