import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import fetchData from '../../utils/fetchData';
import pMemoize from 'p-memoize';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

type TermLimit = {
  amountFrom: number;
  amountTo: number;
  termFrom: number;
  termTo: number;
  limitType: 'SPL' | 'IL';
};

type Instalment = {
  amountFrom: number;
  term: number;
};

export type ClientConstraints = {
  amountInterval: {
    min: number;
    max: number;
    step: number;
    defaultValue: number;
    initialValue: number;
  };
  termInterval: {
    min: number;
    max: number;
    step: number;
    defaultValue: number;
    initialValue: number;
  };
  termLimits: Array<TermLimit>;
  // This is an FE generated field to optimize the data format. Does not come from web-api
  instalments: Array<Instalment>;
};

type ClientConstraintsContextType = {
  clientConstraints: ClientConstraints | undefined;
  fetchClientConstraints: (
    productNumber?: ProductNumber,
  ) => Promise<ClientConstraints | void>;
  setClientConstraints: React.Dispatch<
    React.SetStateAction<ClientConstraints | undefined>
  >;
};

const memoFetchData = pMemoize(fetchData, { maxAge: 1000 });

/** Based on constraint data from BE generate an Array of instalment objects for easier rendering with map(). Applies only for CONS products */
const generateInstalments = (
  minTerm: number,
  maxTerm: number,
  termLimits: TermLimit[],
) => {
  const instalments: Instalment[] = [];

  for (let term = minTerm; term <= maxTerm; term++) {
    const matchingTermLimit = termLimits.find((termLimit) => {
      return termLimit.termFrom <= term && termLimit.termTo >= term;
    });

    if (!matchingTermLimit) {
      continue;
    }

    const instalment = {
      term,
      amountFrom: matchingTermLimit?.amountFrom,
    };

    instalments.push(instalment);
  }

  return instalments;
};

const ClientConstraintsContext = createContext<ClientConstraintsContextType>(
  {} as ClientConstraintsContextType,
);

const useClientConstraints = (): ClientConstraintsContextType =>
  useContext(ClientConstraintsContext);

const ClientConstraintsProvider = ({ children }: Props): JSX.Element => {
  const [clientConstraints, setClientConstraints] = useState<
    ClientConstraints | undefined
  >();
  const { showError } = useError();

  /** Fetches client calculator constraints based on product number. */
  const fetchClientConstraints = useCallback(
    async (
      productNumber?: ProductNumber,
    ): Promise<ClientConstraints | void> => {
      try {
        const fetchedClientConstraints: ClientConstraints = await memoFetchData(
          productNumber
            ? `/client/application/constraints/product-number/${productNumber}`
            : '/client/application/constraints',
        );

        if (fetchedClientConstraints.termLimits.length) {
          // Optizime the data format so it's easier to use
          fetchedClientConstraints.instalments = generateInstalments(
            fetchedClientConstraints.termInterval.min,
            fetchedClientConstraints.termInterval.max,
            fetchedClientConstraints.termLimits,
          );
        } else {
          fetchedClientConstraints.instalments = [];
        }

        setClientConstraints(fetchedClientConstraints);

        return fetchedClientConstraints;
      } catch (e) {
        showError();
        throw e;
      }
    },
    [showError],
  );

  const clientContextValue = useMemo(
    () => ({
      clientConstraints,
      fetchClientConstraints,
      setClientConstraints,
    }),
    [clientConstraints, fetchClientConstraints],
  );

  return (
    <ClientConstraintsContext.Provider value={clientContextValue}>
      {children}
    </ClientConstraintsContext.Provider>
  );
};

export { useClientConstraints as default, ClientConstraintsProvider };
