import { forwardRef, memo, ReactNode } from 'react';
import classes from 'classnames';
import styles from './Card.module.scss';

export type Props = {
  children: ReactNode;
  className?: string;
  dataTest?: string;
  onClick?: () => void;
};

const Card = forwardRef<HTMLDivElement, Props>(function Card(
  { children, className, dataTest = 'card', onClick },
  ref,
) {
  return (
    <div
      onClick={onClick}
      ref={ref}
      data-test={dataTest}
      className={classes(styles.card, className)}
    >
      {children}
    </div>
  );
});

Card.displayName = 'Card';

export default memo(Card);
