import * as R from '../Router/routes';

const MODAL_ENABLED_ROUTES = [R.LOAN_REJECTED, R.LOAN_FAILED];
const MODAL_TIMEOUT_MS = 5000;
const MODAL_ID = 're';

export async function handleGTMModalVisibility() {
  const currentPathname = window.location.pathname;
  const shouldShowModal = MODAL_ENABLED_ROUTES.includes(currentPathname);
  const existingModalScript = document.getElementById('gtm_modal');

  if (!existingModalScript) createAndAppendModalScript();

  try {
    const modal = await waitForModal();
    modal.hidden = !shouldShowModal;
  } catch (error) {
    console.error('Failed to handle GTM modal:', error);
  }
}

async function waitForModal(
  timeoutMs = MODAL_TIMEOUT_MS,
): Promise<HTMLElement> {
  const existingModal = document.getElementById(MODAL_ID);

  if (existingModal) return existingModal;

  return new Promise((resolve, reject) => {
    const modalObserver = new MutationObserver(() => {
      const modal = document.getElementById(MODAL_ID);

      if (modal) {
        modalObserver.disconnect();
        resolve(modal);
      }
    });

    modalObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });

    setTimeout(() => {
      modalObserver.disconnect();
      reject(new Error('GTM modal did not appear within expected timeframe'));
    }, timeoutMs);
  });
}

const createAndAppendModalScript = () => {
  const modalScript = document.createElement('script');
  modalScript.type = 'text/javascript';
  modalScript.id = 'gtm_modal';
  modalScript.async = true;
  modalScript.src = 'https://dev.4finance.com/modal.js';
  document.body.appendChild(modalScript);
};
