import { Client } from '../hooks/webapi/useClient';
import { LatestLoan } from 'hooks/webapi/useLatestLoan';

export const getShowBanner = (
  client: Client | undefined,
  latestLoan?: LatestLoan | null | undefined,
) =>
  client &&
  client?.totalCreditLimit >= 650 &&
  !client?.hasActiveIdDocument &&
  !latestLoan?.overDueDays;

export const getIsExistingUser = (client: Client | undefined) =>
  !!client?.identified;
