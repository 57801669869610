import { FieldError } from './ValidationError';
import i18Next from 'i18next';

export const isSoldDebtFromErrors = (
  errors: FieldError[],
): { isSoldDebt: boolean; message: string | undefined } => {
  const soldDebtError = errors?.find(
    (error) =>
      error.messageTemplate === 'Forbidden' &&
      error?.message.includes(';') &&
      error?.message.split(';')[0] === 'sold_debt',
  );
  const isSoldDebt = Boolean(soldDebtError);
  let message = '';
  if (isSoldDebt) {
    const [, entity, phonesString, emailsString] =
      soldDebtError?.message.split(';') || [];
    const phones = phonesString.split(',');

    message += i18Next.t('error:validation.soldDebt.prePhones', { entity });

    phones.forEach((phone: any, indexPhone: any) => {
      const separator =
        indexPhone && indexPhone < phones.length
          ? `${i18Next.t('error:validation.soldDebt.phoneEmailSeparator')}`
          : '';
      message += `${separator}<br /><a href="tel:+34${phone.replaceAll(
        ' ',
        '',
      )}">${phone}</a>`;
    });

    message += i18Next.t('error:validation.soldDebt.preEmails');

    const emails = emailsString.split(',');

    emails.forEach((email: any, indexEmail: any) => {
      const separator =
        indexEmail && indexEmail < emails.length
          ? `${i18Next.t('error:validation.soldDebt.phoneEmailSeparator')}`
          : '';
      message += `${separator}<br /><a href="mailto:${email}">${email}</a>`;
    });
  }

  return {
    isSoldDebt,
    message,
  };
};
