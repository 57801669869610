import { ReactComponent as CheckIcon } from '../../assets/icons/check-circle.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error-circle.svg';
import { ReactComponent as EyeClosedIcon } from '../../assets/icons/eye-closed.svg';
import { ReactComponent as EyeOpenIcon } from '../../assets/icons/eye-open.svg';
import classes from 'classnames';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import HeadShake from 'react-reveal-effects/HeadShake';
import React, { memo, useCallback, useState } from 'react';
import styles from './Input.module.scss';

export type Props = {
  type?: 'text' | 'password' | 'tel' | 'email';
  value?: string | number;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  error?: string | boolean;
  isTouched?: boolean;
  errorAnimationTrigger?: number; // Pass a different value to re-trigger error animation
  placeholder?: string;
  postFix?: string;
  showPasswordReveal?: boolean;
  dataTest?: string;
  stackable?: boolean;
  className?: string;
  autoComplete?: 'on' | 'off';
};

export default memo(function Input({
  type = 'text',
  onChange,
  value = '',
  name,
  onBlur,
  error,
  isTouched,
  errorAnimationTrigger,
  placeholder,
  postFix,
  showPasswordReveal = false,
  dataTest,
  stackable = false,
  className,
  autoComplete,
}: Props) {
  const [passwordVisibility, setPasswordVisibility] = useState<
    'password' | 'text'
  >('password');

  const handlePasswordToggle = useCallback(() => {
    setPasswordVisibility(
      passwordVisibility === 'password' ? 'text' : 'password',
    );
  }, [passwordVisibility]);

  const showError = isTouched && error;
  const showCheck = isTouched && !error;
  const EyeIcon =
    passwordVisibility === 'password' ? EyeClosedIcon : EyeOpenIcon;
  return (
    <div className={classes(styles.container, className)}>
      <div
        className={classes(
          styles.field,
          stackable && styles.stackable,
          showError && styles['field-error'],
        )}
      >
        <input
          autoComplete={autoComplete}
          data-test={dataTest}
          className={classes(styles.input, showError && styles['input-error'])}
          name={name}
          id={name}
          type={type === 'password' ? passwordVisibility : type}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
        />

        <label
          htmlFor={name}
          className={classes(
            styles.label,
            showError && styles['label-error'],
            value.toString().length && styles.float,
          )}
        >
          {placeholder}
        </label>

        <div
          className={styles.badges}
          onClick={showPasswordReveal ? handlePasswordToggle : undefined}
        >
          {showPasswordReveal && (
            <EyeIcon
              className={classes(styles.badge, styles['password-icon'])}
            />
          )}
          {postFix && (
            <span
              className={classes(
                styles.badge,
                styles['post-fix'],
                showError && styles['post-fix-error'],
              )}
            >
              {postFix}
            </span>
          )}
          {(showError || showCheck) && (
            <div
              className={classes(
                styles.divider,
                showError && styles['divider-error'],
              )}
            />
          )}
          {showError && (
            <HeadShake
              spy={errorAnimationTrigger ? errorAnimationTrigger : undefined}
            >
              <ErrorIcon
                className={classes(styles.badge, styles['error-icon'])}
              />
            </HeadShake>
          )}
          {showCheck && <CheckIcon className={styles.badge} />}
        </div>
      </div>

      {showError && typeof error !== 'boolean' && (
        <div className={styles['error-message']}>
          <ErrorMessage errors={error} />
        </div>
      )}
    </div>
  );
});
