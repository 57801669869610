import { Card } from '..';
import { memo, ReactNode } from 'react';
import classes from 'classnames';
import styles from './RadioCard.module.scss';

export type Props = {
  id?: string;
  className?: string;
  checked?: boolean;
  name?: string;
  dataTest?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  children?: ReactNode;
  checkmarkClassName?: string;
};

export default memo(function RadioCard({
  id,
  className,
  checked,
  name,
  dataTest,
  onChange,
  value,
  children,
  checkmarkClassName,
}: Props) {
  return (
    <Card
      className={classes(
        styles.container,
        checked && styles.checked,
        className,
      )}
      dataTest={dataTest}
    >
      <input
        className={styles.input}
        id={id}
        onChange={onChange}
        value={value}
        type="radio"
        name={name}
        checked={checked}
      />
      <div
        className={classes(styles['checkmark-container'], checkmarkClassName)}
      >
        <div className={classes(styles.checkmark, checked && styles.checked)}>
          {checked && <div className={styles['checkmark-filled']} />}
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </Card>
  );
});
