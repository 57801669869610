import { AnalyticsEvent } from './plugins/types';

async function fetchAnalytics(trackEvent: AnalyticsEvent) {
  //TODO: Test customAnalytics serapately
  if (window && (window as any).Cypress) {
    return;
  }

  const headers = {
    'Content-Type': 'application/json',
  };

  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(trackEvent),
  };

  const url = `${process.env.REACT_APP_ANALYTICS_API}`;

  try {
    await fetch(url, options);
  } catch (e) {}
}

export default fetchAnalytics;
