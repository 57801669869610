import { createContext, ReactNode, useContext, useMemo } from 'react';
import fetchData from '../../utils/fetchData';

type Props = {
  children: ReactNode;
};

type AffiliatesContextType = {
  registerAffiliateUser: (token: string) => Promise<void>;
};

const AffiliatesContext = createContext<AffiliatesContextType>(
  {} as AffiliatesContextType,
);

const useAffiliates = (): AffiliatesContextType =>
  useContext(AffiliatesContext);

const AffiliatesProvider = ({ children }: Props): JSX.Element => {
  const registerAffiliateUser = async (token: string): Promise<void> => {
    try {
      await fetchData(
        '/affiliate/events/redirect',
        {
          method: 'post',
          body: JSON.stringify({ token }),
        },
        true,
      );
    } catch {}
  };

  const activeCardContextValue = useMemo(
    () => ({
      registerAffiliateUser,
    }),
    [registerAffiliateUser],
  );

  return (
    <AffiliatesContext.Provider value={activeCardContextValue}>
      {children}
    </AffiliatesContext.Provider>
  );
};

export { useAffiliates as default, AffiliatesProvider };
