import * as R from '../../Router/routes';
import { memo, useEffect } from 'react';
import { useActiveLoan } from '../../pages/ActiveLoan/useActiveLoan';
import { useApplication } from '../../hooks/webapi';
import { useLocation } from '@reach/router';
import FooterDefault from './FooterDefault/FooterDefault';
import FooterOverdue from './FooterOverdue/FooterOverdue';
import isLogged from 'utils/isLogged';
import useClientDebtRestructuring from '../../hooks/webapi/useClientDebtRestructuring';

const DR_PAYMENT_ROUTES = [
  R.DR_PAYMENT,
  R.DR_PAYMENT_SCHEDULE,
  R.CARD_PAYMENT,
  R.CARD_PAYMENT_CONS_SUCCESS,
  R.CARD_PAYMENT_CONS_FAILURE,
  R.FULL_REPAYMENT,
  R.REPAYMENT,
  R.REPAYMENT_WIRE_TRANSFER,
];

export type Props = {
  className?: string;
  dataTest?: string;
};

export default memo(function Footer(props: Props) {
  const { pathname } = useLocation();
  const { isOverdue, isBrokenAgreement } = useActiveLoan();
  const { application, fetchApplication } = useApplication();
  const { isDebtRestructuring } = useClientDebtRestructuring();

  useEffect(() => {
    if (application === undefined && isLogged()) {
      fetchApplication();
    }
  }, [application, fetchApplication]);

  const showOverdueDRFooter =
    isDebtRestructuring && DR_PAYMENT_ROUTES.includes(pathname);

  return isOverdue || isBrokenAgreement || showOverdueDRFooter ? (
    <FooterOverdue {...props} />
  ) : (
    <FooterDefault {...props} />
  );
});
