import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ValidationError } from '../../utils';
import fetchData from '../../utils/fetchData';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

export type ApplicationProposal = {
  type: 'BEST' | 'DOCUMENT_UPLOAD' | 'OPTIONAL';
  amount: number;
  term: number;
  monthlyPayment: string;
  firstPaymentDueDate: string;
  expiryDate: string;
  productNumber: string;
};

type ClientApplicationProposalsData = {
  proposals: ApplicationProposal[];
  bestOfferProposal: ApplicationProposal;
  docUploadProposal: ApplicationProposal;
  offerFromProposalLink: string;
  applyFromProposalLink: string;
};

type ApplicationProposalsContextType = {
  applicationProposals?: ClientApplicationProposalsData | undefined;
  fetchApplicationProposals: () => Promise<ClientApplicationProposalsData | void>;
};

const ApplicationProposalsContext =
  createContext<ApplicationProposalsContextType>(
    {} as ApplicationProposalsContextType,
  );

const useApplicationProposals = (): ApplicationProposalsContextType =>
  useContext(ApplicationProposalsContext);

const ApplicationProposalsProvider = ({ children }: Props): JSX.Element => {
  const [applicationProposals, setApplicationProposals] =
    useState<ClientApplicationProposalsData>();
  const { showError } = useError();

  const fetchApplicationProposals =
    useCallback(async (): Promise<ClientApplicationProposalsData | void> => {
      try {
        const fetchedApplicationProposals = await fetchData(
          '/client/application/proposals',
        );
        setApplicationProposals(fetchedApplicationProposals);
        return fetchedApplicationProposals;
      } catch (e) {
        showError(e as ValidationError);
      }
    }, [showError]);

  const ApplicationProposalsContextValue = useMemo(
    () => ({
      applicationProposals,
      fetchApplicationProposals,
    }),
    [applicationProposals, fetchApplicationProposals],
  );

  return (
    <ApplicationProposalsContext.Provider
      value={ApplicationProposalsContextValue}
    >
      {children}
    </ApplicationProposalsContext.Provider>
  );
};

export { useApplicationProposals as default, ApplicationProposalsProvider };
