import { useCallback, useMemo } from 'react';
import fetchData from '../../utils/fetchData';
import useError from '../useError';

const useLegalDocuments = () => {
  const { showError } = useError();

  const fetchCurrentLegalTerms = useCallback(async () => {
    try {
      const fetchedLegalTerms = await fetchData('/client/legal-terms/current', {
        headers: {
          Accept: 'text/html',
          'Content-Type': 'text/html',
        },
      });

      return fetchedLegalTerms;
    } catch (e) {
      showError();
      throw e;
    }
  }, [showError]);

  const fetchLegalTerms = useCallback(
    async (amount: number, term: number, productNumber?: ProductNumber) => {
      try {
        const queryString = new URLSearchParams({
          amount: amount.toString(),
          term: term.toString(),
          ...(productNumber ? { productNumber } : {}), // Conditionally add productNumber
        });
        const fetchedLegalTerms = await fetchData(
          productNumber === '7' || productNumber === '8'
            ? `/client/legal-terms/product-number?${queryString}`
            : `/client/legal-terms?${queryString}`,
          {
            headers: {
              Accept: 'text/html',
              'Content-Type': 'text/html',
            },
          },
        );

        return fetchedLegalTerms;
      } catch (e) {
        showError();
        throw e;
      }
    },
    [showError],
  );

  return useMemo(
    () => ({
      fetchCurrentLegalTerms,
      fetchLegalTerms,
    }),
    [fetchCurrentLegalTerms, fetchLegalTerms],
  );
};

export default useLegalDocuments;
