import { memo, useCallback } from 'react';
import { numberToCurrency } from '../../utils/translate';
import { Placeholder, Touchable } from '..';
import { useTranslation } from 'react-i18next';
import CheckLottie from '../../assets/lottie/check.json';
import classes from 'classnames';
import Lottie from 'react-lottie-light';
import styles from './InstalmentCard.module.scss';

const pendingLottieOptions = {
  loop: false,
  autoplay: true,
  animationData: CheckLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export type Props = {
  className?: string;
  dataTest?: string;
  term: number;
  monthlyPayment?: number;
  totalRepayableAmount?: number;
  totalInterest?: number;
  active?: boolean;
  isOverLimit?: boolean;
  isUnderLimit?: boolean;
  amountFrom?: number;
  amountTo?: number;
  onClick?: (term: number) => void;
  isLoading?: boolean;
};

export default memo(function InstalmentCard({
  className,
  dataTest,
  term,
  monthlyPayment,
  totalRepayableAmount,
  totalInterest,
  active = false,
  isOverLimit = false,
  isUnderLimit = false,
  amountFrom,
  amountTo,
  onClick,
  isLoading,
}: Props) {
  const { t } = useTranslation('instalment_card');

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(term);
    }
  }, [term, onClick]);

  const disabled = isUnderLimit || isOverLimit;

  return (
    <div
      data-test={dataTest}
      className={classes(
        styles['term-card'],
        disabled && styles.disabled,
        active && styles.active,
        className,
      )}
    >
      {active && (
        <div className={styles.check}>
          <Lottie options={pendingLottieOptions} />
        </div>
      )}
      <Touchable
        onClick={handleClick}
        className={classes(
          styles['touchable-container'],
          active && styles.active,
        )}
        buttonClassName={styles['touchable']}
      >
        <div className={styles['term-card-duration']}>
          <span className={styles.amount}>{term}</span>
          <span className={styles.label}>
            {t('common:months', { count: term })}
          </span>
        </div>
        <div className={styles['term-card-payment']}>
          {disabled ? (
            <span className={styles.label}>
              {isUnderLimit &&
                t('available_from', {
                  amountFrom: numberToCurrency(amountFrom),
                })}
              {isOverLimit &&
                t('available_to', {
                  amountTo: numberToCurrency(amountTo),
                })}
            </span>
          ) : (
            <>
              <div className={classes(styles.row, styles.badge)}>
                <span className={classes(styles.label, styles.large)}>
                  {t('payment')}
                </span>
                <span className={classes(styles.amount, styles.large)}>
                  <Placeholder
                    dataTest={`instalment-${term}-monthly-payment`}
                    loading={isLoading}
                  >
                    {numberToCurrency(monthlyPayment)}
                  </Placeholder>
                </span>
              </div>
              <div className={styles.row}>
                <span className={styles.label}>{t('total_to_return')}</span>
                <span className={styles.amount}>
                  <Placeholder
                    dataTest={`instalment-${term}-total-repayable-amount`}
                    loading={isLoading}
                  >
                    {numberToCurrency(totalRepayableAmount)}
                  </Placeholder>
                </span>
              </div>
              <div className={styles.row}>
                <span className={styles.label}>{t('intrest')}</span>
                <span className={styles.amount}>
                  <Placeholder
                    dataTest={`instalment-${term}-total-interest`}
                    loading={isLoading}
                  >
                    {numberToCurrency(totalInterest)}
                  </Placeholder>
                </span>
              </div>
            </>
          )}
        </div>
      </Touchable>
    </div>
  );
});
