export const BEST_OFFER = '/best-offer';
export const LOGIN = '/login';
export const TWO_FACTOR_AUTH = '/2fa';
export const TWO_2FA_PHONE_MODIFIED = '/2fa/change-telephone/tink-modified';
export const CHANGE_PHONE_TWO_FACTOR_AUTH = '/2fa/change-telephone';
export const TWO_2FA_PHONE_ERROR = '/2fa/change-telephone/tink-error';
export const TWO_2FA_PHONE = '/2fa/change-telephone';
export const ONE_TIME_TOKEN_LOGIN = '/one-time-token-login';
export const ONLINE_LOGIN = '/online-login';
export const ACTIVE_LOAN = '/account';
export const PROFILE = '/account/my-data';
export const REPEATED_LOAN = '/account/repeated-loan';
export const LOAN_CONFIRM = '/account/repeated-loan/confirm';
export const LOAN_CHECK_DETAILS = '/account/repeated-loan/check-details';
export const LOAN_APPROVED = '/account/repeated-loan/approved-application';
export const LOAN_REJECTED = '/account/repeated-loan/rejected-application';
export const LOAN_FAILED = '/account/repeated-loan/rejected-try-later';
export const ADDITIONAL_AMOUNT_CHECK_DETAILS =
  '/account/ask-more-money/check-details';
export const REPAYMENT = '/account/pay-for-loan';
export const FULL_REPAYMENT = '/account/full-pay-for-loan';
export const REPAYMENT_WIRE_TRANSFER = '/account/pay-for-loan/wire-transfer';
export const EXTENSION_WIRE_TRANSFER =
  '/account/extend-loan-period/wire-transfer';
export const EXTENSIONS = '/account/extend-loan-period';
export const EXTENSION_PAYMENT = '/account/extend-loan-period/payment';
export const EXTENSION_SUCCESS = '/account/extend-loan-period/success';
export const EXTENSION_REJECTED =
  '/account/extend-loan-period/rejected-payment';
export const EXTENSION_LIMITS_REJECTED = '/account/extend-loan-period/rejected';
export const EXTENSION_CARD_PAYMENT_REJECTED_DUE_DUPLICATE =
  '/account/extend-loan-period/rejected-duplicate';
export const EXTENSION_WITH_CARD = '/account/extend-loan-period/card';
export const CARD_PAYMENT = '/account/pay-for-loan/card';
export const CARD_PAYMENT_CONS_SUCCESS = '/account/pay-for-loan/cons/success';
export const CARD_PAYMENT_MINI_SUCCESS = '/account/pay-for-loan/mini/success';
export const CARD_PAYMENT_FAILURE = '/account/pay-for-loan/failure';
export const CARD_PAYMENT_FAILURE_BY_CARD =
  '/account/pay-for-loan/card/failure';
export const CARD_PAYMENT_CONS_FAILURE = '/account/pay-for-loan/cons/failure';
export const CARD_PAYMENT_REJECTED_DUE_DUPLICATE =
  '/account/pay-for-loan/failure-duplicate';
export const CHECK_DOCUMENTS = '/account/check-documents';
export const ADDITIONAL_AMOUNT = '/account/ask-more-money';
export const ADDITIONAL_AMOUNT_CONFIRM = '/account/ask-more-money/confirm';
export const ADDITIONAL_AMOUNT_APPROVED = '/account/ask-more-money/approved';
export const ADDITIONAL_AMOUNT_REJECTED = '/account/ask-more-money/rejected';
export const ADDITIONAL_AMOUNT_FAILED =
  '/account/ask-more-money/rejected-try-later';
export const ALTERNATIVE_DOCUMENTS_UPLOAD =
  '/account/check-documents/alternative';
export const DOCUMENTS_PENDING = '/account/check-documents/pending';
export const DOCUMENTS_APPROVED = '/account/check-documents/approved';
export const FAQ = '/account/faq';
export const REPAYMENT_BROKEN_LOAN = '/account/pay-for-broken-loan';
export const PASSWORD_CHANGE = '/change-password';
export const PASSWORD_CHANGE_SUCCESS = '/account/change-password/success';
export const CHANGE_TEMPORARY_PASSWORD = '/account/change-temporary-password';
export const NEWS_CONSENT = '/account/news-consent';
export const PASSWORD_RECOVERY = '/password-recovery';
export const PASSWORD_RECOVERY_SUCCESS = '/password-recovery/success';
export const SIGN_DR_AGREEMENT = '/account/sign-dr-agreement';
export const SIGN_DR_AGREEMENT_SUCCESS = '/account/sign-dr-agreement/success';
export const SAVE_CARD = '/account/save-card';
export const AUTOREPAY = '/account/activate-autorepay';
export const BREAKDOWN_DEBT = '/account/installment-debt-detail';
export const INSTALLMENT_PAYMENT = '/account/installment-pay-for-loan';

export const DR_OFFER = '/account/dr/eligible';
export const DR_OFFER_CONS = '/account/dr/eligible-cons';
export const DR_OFFER_ACCEPTED =
  '/account/dr/agreement-and-payment-information';
export const DR_OVERDUE = '/dr-active';
export const DR_PAYMENT = '/dr-payment';
export const DR_PAYMENT_SCHEDULE = '/dr-payment-schedule';
export const DANA = '/dana';
