import { COMPANY_NAME_4FINANCE, PHONE, PHONE_LINK } from '../utils/constants';

const es = {
  common: {
    days_amount_one: '{{count}} día',
    days_amount_other: '{{count}} días',
    months_amount_one: '{{count}} mes',
    months_amount_other: '{{count}} meses',
    minutes_one: 'minuto',
    minutes_other: 'minutos',
    hours_one: 'hora',
    hours_other: 'horas',
    days_one: 'día',
    days_other: 'días',
    months_one: 'mes',
    months_other: 'meses',
    back: 'Volver',
    currency: '€',
    continue: 'Continuar',
    understood: 'Entendido',
    email: 'info@vivus.es',
    email_2: 'recibos@vivus.es',
    phone: '91 290 77 88',
    phone_2: '91 084 05 59',
    phone_link: '+34912907788',
    phone_2_link: '+34910840559',
    recommended: 'Recomendado',
  },
  error: {
    generic: 'Algo salió mal. Inténtalo de nuevo más tarde.',
    routerBanner:
      'Esta ruta es insegura. Un desarrollador debe añadir esta pantalla a la función "checkBEConditions" del componente "GuardRoute"',
    invalidLoan:
      'Ha habido un problema para obtener información sobre su préstamo. Por favor, inténtalo de nuevo',
    session_expired: 'Tu sesión ha finalizado debido a inactividad',
    register: 'Hubo un problema con el registro. Por favor, inténtalo de nuevo',
    duplicatePayment:
      'Petición de pago duplicada. Por favor, espera un momento para repetirlo',
    validation: {
      already_exists_in_db: 'Si ya eres cliente entra en tu área de cliente.',
      already_exists_in_db_phone: 'Teléfono ya en uso.',
      already_exists_in_db_phone_modal:
        'No es posible realizar el cambio con este número de teléfono',
      document_file_type_size:
        'El formato del archivo no es válido. Formatos aceptados: JPG, PNG, BMP, GIF, TIFF o PDF y tamaño máximo del fichero: 25 MB',
      empty_email_or_dni: 'Email o DNI/NIE es obligatorio.',
      empty_password: 'Este campo es obligatorio.',
      generic_2fa_error:
        'Algo salió mal durante la doble verificación. Inténtalo de nuevo más tarde.',
      incorrect_password: 'Contraseña incorrecta.',
      incorrect_code: 'Código incorrecto',
      session: `Ha surgido un problema.<br />Por favor, ponte en contacto con nosotros en el teléfono <span><a href="tel:{{phoneLink}}">{{phone}}</a>.</span>`,
      soldDebt: {
        prePhones: `Recuerda que tu préstamo fue cedido a la entidad {{entity}}. Para cualquier información deberás contactar en los siguientes teléfonos`,
        phoneEmailSeparator: '<br />o',
        preEmails: '<br />o direcciones de correo electrónico',
      },
      login_unavailable: `Ha surgido un problema.<br />Por favor, ponte en contacto con nosotros en el teléfono <a href="tel:${{
        PHONE_LINK,
      }}">${{
        PHONE,
      }}</a>.`,
      not_email: 'Email formato incorrecto.',
      not_email_nor_dni: 'Email o DNI/NIE Formato incorrecto.',
      password_contains_space: 'El carácter de espacio no está permitido',
      password_is_same_as_old:
        'La nueva contraseña debe ser diferente a la antigua.',
      new_passwords_is_different: 'Las contraseñas no coinciden',
      //TODO: hotfix/cwt-2823 take back when ready
      unauthorized:
        'Email, DNI/NIE o contraseña incorrectos. \nPor favor, comprueba tus datos e inténtalo de nuevo.',
      verification_id_not_created:
        'Ha habido un problema con el identificador del dispositivo.',
      email_not_found_by_client_number:
        'Email no encontrado, por favor, comprueba tus datos e inténtalo de nuevo',
      client_not_found_by_client_number:
        'No se ha encontrado el identificador del cliente.',
      client_number_not_found_by_verification_id:
        'No se ha encontrado un cliente relacionado con el identificador del dispositivo',
      verification_id_not_found_by_code_id:
        'No se ha encontrado un código relacionado con el identificador del dispositivo',
      code_not_matched: 'Código incorrecto',
      notification_sender_not_found:
        'No se encontró ningún remitente de notificación',
      client_number_not_found_by_code_id:
        'Número de cliente no encontrado con el código proporcionado',
      weak_password:
        'Se requiere un mínimo de 8 carácteres, un número o carácter especial (!@#%&*), una minúscula y una mayúscula.',
      not_valid:
        'Por favor, vuelve a intentarlo usando otro correo electrónico.',
      not_valid_phone:
        'No es posible realizar el cambio con este número de teléfono',
      too_many_attempts:
        'Se han realizado demasiados intentos. Por favor, espere unos minutos antes de intentarlo de nuevo.',
      rate_limit_exceeded:
        'Se han realizado demasiados intentos. Por favor, espere unos minutos antes de intentarlo de nuevo.',
      required_field: 'Campo obligatorio.',
      wrong_format: 'Formato incorrecto.',
      wrong_phone: 'Teléfono incorrecto',
    },
    user_not_identified: 'Envíanos tu documentación para continuar',
  },

  footer: {
    work_hours_weekdays:
      'Lunes – Viernes / 8:00 – 21:00, Sábado / 9:00 – 20:00',
    work_hours_weekends: 'Festivos nacionales / 9:00 – 14:00',
    payFullLoan: 'Pagar todo el préstamo',
    terms_and_conditions:
      '<a target="_blank" rel="noopener" href="https://www.vivus.es/condiciones/generales">Términos y condiciones</a>',
  },

  footerOverdue: {
    title: '¿Necesitas ayuda?',
    email: 'recibos@vivus.es',
    work_hours_weekdays: 'Lunes a Viernes de 8:00 a 18:00 horas',
    work_hours_weekends: 'Sábados de 9:00 a 13:00 horas',
    pay_full_loan: 'Pagar todo el préstamo',
  },

  document_upload: {
    additionalDocument: 'Documento adicional',
    id_front_title: 'Parte delantera de tu DNI / NIE',
    id_back_title: 'Parte trasera de tu DNI / NIE',
  },

  menu: {
    dashboard: 'Mi área personal',
    additional_amount: 'Ampliar mi préstamo',
    extention: 'Aplazar el pago',
    repayment: 'Realizar el pago',
    help: '¿Te ayudamos?',
    profile: 'Mis datos',
    logout: 'Cerrar sesión',
  },

  documentTypes: {
    frontSideDni: 'DNI (parte frontal)',
    backSideDni: 'DNI (parte trasera)',
    bankReceipt: 'Recibo bancario',
    idCard: 'Tarjeta de identificación',
    passport: 'Pasaporte',
    gunLicense: 'Licencia de armas',
    driversLicense: 'Licencia de conducir',
    photo: 'Foto',
    otherDocument: 'Otro documento',
    incomeDocument: 'Documento de ingreso',
  },

  dashboard: {
    overdueBanner: 'Tu préstamo venció hace',
    overdue7Banner: 'Tu cuota venció hace',
    profile: {
      html_title: 'Área personal - Vivus.es',
      welcome: 'Hola ',
      info_title_1: 'Tus datos personales',
      name: 'Nombre',
      date_of_birth: 'Fecha de nacimiento',
      bank_account: 'Cuenta bancaria',
      address: 'Dirección',
      password: 'Contraseña',
      password_placeholder: '● ● ● ● ● ● ●',
      email: 'E-mail',
      phone: 'Teléfono',
      info_title_2: 'Preferencias de comunicación',
      accept_news: `Autorizo a ${COMPANY_NAME_4FINANCE} y las Empresas del Grupo a que traten mis datos personales proporcionados con fines publicitarios y de marketing (<a href="https://www.vivus.es/condiciones/marketing-y-comunicacion" target="_blank" rel="noopener">más información</a>).`,
      via_email: 'Por via E-mail',
      via_sms: 'Por SMS',
      via_phone: 'Por Teléfono',
      accept_data_sharing: `Autorizo a que mis datos personales sean comunicados a partners con los que ${COMPANY_NAME_4FINANCE} hayan alcanzado acuerdos, para que puedan informarme sobre sus productos o servicio (<a href="https://www.vivus.es/condiciones/comunicaciones-a-terceros" target="_blank" rel="noopener">más información</a>).`,
      card_title: 'Tarjeta bancaria',
      add_card:
        '+ Añade tu tarjeta de crédito o débito para activar el pago automático de tu crédito.',
      add_card_more_info: 'Más información',
      add_auto_repayment: 'Activa el pago de tu crédito de manera automática.',
      auto_repayment_link: 'Más información',
      active_auto_repayment_1: 'Pago automático activado.',
      active_auto_repayment_2: 'El próximo pago será el {{date}}.',
      modal_auto_repayment: {
        header: 'Pago automático',
        title: '¿Quieres desactivar el pago automático?',
        description:
          'AVISO: Al desactivar este servicio tendrás la responsabilidad de realizar los pagos en las fechas correctas aumentando tu riesgo de impago',
        no: 'No',
        yes: 'Sí',
      },
      modal: {
        header_name: 'Cambiar nombre',
        header_date_of_birth: 'Cambiar fecha de nacimiento',
        header_bank_account: 'Cambiar cuenta bancaria',
        header_address: 'Cambiar dirección',
        header_email: 'Cambiar e-mail',
        header_phone: 'Cambiar teléfono',
        header_password: 'Modificar contraseña',
        content_header_name: 'Cómo cambiar tu nombre',
        content_header_date_of_birth: 'Cómo cambiar tu fecha de nacimiento',
        content_header_bank_account: 'Cambiar cuenta bancaria',
        content_header_address: 'Cómo cambiar tu dirección',
        content_header_email: 'Cómo cambiar tu e-mail',
        content_header_password: 'Introducir contraseña',
        content_name: `Para cambiar tu nombre contacta con nuestro servicio de atención al cliente en el <a href="tel:{{phoneLink}}">{{phone}}</a>.`,
        content_date_of_birth: `Para cambiar tu fecha de nacimiento contacta con nuestro servicio de atención al cliente en el <a href="tel:{{phoneLink}}">{{phone}}</a>.`,
        content_bank_account_open_loan:
          'No se puede modificar la cuenta bancaria con un préstamo abierto',
        content_bank_account:
          'Para modificar tu cuenta bancaria utilizamos los servicios de TINK AB que se conecta a tu banca on-line para extraer de forma segura tus datos identificativos e IBAN. <a href="https://www.vivus.es/faq/como-puedo-cambiar-mi-cuenta-bancaria" target="_blank">Más información</a>.<br /><a href="https://www.vivus.es/condiciones/politica-de-privacidad" target="_blank">Política de Privacidad</a>',
        content_address: `Para cambiar tu dirección contacta con nuestro servicio de atención al cliente en el <a href="tel:{{phoneLink}}">{{phone}}</a>.`,
        content_email: `Para cambiar tu e-mail contacta con nuestro servicio de atención al cliente en el <a href="tel:{{phoneLink}}">{{phone}}</a>.`,
        consents_bank_account: 'Quiero cambiar mi número de cuenta',
        content_tink_result_success: 'Cuenta bancaria cambiada con éxito.',
        content_tink_result_success_same_iban:
          'No ha sido posible el cambio. Has seleccionado la cuenta que ya tienes actualmente registrada.',
        content_tink_result_error:
          'No ha sido posible realizar el cambio. Será necesario que selecciones otro banco u otra cuenta bancaria.',
        old_password_placeholder: 'Contraseña antigua',
        new_password_placeholder: 'Nueva contraseña',
        confirm_btn: 'Entendido',
        continue_btn: 'continuar',
        button_change_password: 'Guardar contraseña',
      },
    },
  },
  repayment: {
    heading: 'Pagar deuda',
    heading_broken_loan: 'Hacer pago',
    html_title: 'Realizar el pago - Vivus.es',
    title: 'Importe a pagar',
    card_heading: 'Pagar con tarjeta bancaria',
    card_info: 'Esta es la opción más cómoda y rápida.',
    manual_transfer_heading: 'Transferencia bancaria',
    manual_transfer_info: 'De 24 a 48 horas hábiles para procesar el pago.',
    postpone_heading: '¿No puedes pagar hoy?',
    partial_payment: 'Pago parcial',
    postpone_info: '¡Gana tiempo! Pausa tu deuda pagando una pequeña cantidad.',
    postpone_info_mini8:
      'Elimina las penalizaciones generadas y pausa tu deuda pagando una pequeña cantidad.',
    postpone_info_6:
      '¡Gana tiempo! Pausa tu deuda pagando una pequeña cantidad.',
    early_repayment_total: 'Total a devolver hoy',
    early_repayment_principal: 'Importe',
    early_repayment_fee: 'Comisión de cancelación anticipada',
    early_repayment_text:
      'Vas a cancelar tu préstamo anticipadamente. <br />La comisión por cancelación anticipada es de <b>0,5%</b> del principal pendiente.',
    penalties_penalty_one:
      'El importe incluye {{value}} en concepto de penalizaciones al realizarse el pago con {{count}} día de retraso.',
    penalties_penalty_other:
      'El importe incluye {{value}} en concepto de penalizaciones al realizarse el pago con {{count}} días de retraso.',
    penalties_commission_one:
      'El importe incluye {{value}} en concepto de gastos asociados a realizar el pago con {{count}} día de retraso.',
    penalties_commission_other:
      'El importe incluye {{value}} en concepto de gastos asociados a realizar el pago con {{count}} días de retraso.',
    penalties_penalty_and_commission_one:
      'El importe incluye {{value}} en concepto de penalizaciones y gastos asociados a realizar el pago con {{count}} día de retraso.',
    penalties_penalty_and_commission_other:
      'El importe incluye {{value}} en concepto de penalizaciones y gastos asociados a realizar el pago con {{count}} días de retraso.',
    heading_full_repayment: 'Realizar pago',
    pay_now: 'Deuda pendiente',
    pay_now_overdue: 'Total deuda pendiente',
    pay_now_partial: 'Pago parcial',
    open_amount: 'Importe',
    monthly_payments: 'Mensualidad',
    payment_notification:
      'Podrás realizar el pago de tu mensualidad en tu fecha de pago: <b>{{date}}</b>.',
    bizum_heading_overview: 'Pagar con Bizum',
    bizum_heading_brokenloan: 'Pagar con Bizum',
    bizum_heading: 'Bizum',
    bizum_heading_DR: 'Pagar con Bizum',
    bizum_info: '¡La nueva opción más rápida!',
    summary_card: {
      title: 'Resumen del pago',
      price: 'Importe',
      contract: 'Contrato nº',
    },
    checkbox_overdue_card:
      'Activa el pago automático para futuros préstamos. Nosotros nos encargaremos de cobrar tu préstamo en la fecha correcta',
    checkbox_overdue_card_full_repayment_7_CONS:
      'Activa el pago automático para futuros préstamos. Nosotros nos encargaremos de cobrar tu préstamo en la fecha correcta.',
    checkbox_overdue_card_7_CONS:
      'Activa el pago automático para el cobro de tus próximas cuotas y préstamos. Nos encargaremos de hacer el cargo en la fecha de vencimiento. ',
    checkbox_dr:
      'Activa el pago automático y nos encargaremos de cobrar tus cuotas mensuales en las fechas acordadas.',
    check_box_overdue:
      'Activa el pago  automático y nos encargaremos de cobrar los {{amount}} € de tu préstamo en la nueva fecha de vencimiento {{date}}',
    check_box: 'Activa el pago automático de tus préstamos.',
    check_box_more_information: 'Más información',
    pay_button: 'PAGAR PRÉSTAMO',
    pay_button_7_cons: 'PAGAR CUOTA',
    pay_partial_button: 'HACER PAGO',
  },

  two_factor_auth: {
    html_title: '2FA - Vivus.es',
    heading: 'Verifica tu teléfono',
    text: 'Introduce el código SMS que te hemos enviado al teléfono ({{phone}}).',
    resend: 'Reenvíame el código',
    customer_service:
      '¿No has recibido el SMS a pesar de reintentarlo? Pulsa<a target="_blank" href="https://www.vivus.es/faq/como-puedo-cambiar-mi-numero-de-telefono-o-de-movil">aquí</a>',
    change_phone: '¿Has cambiado de teléfono? Pulsa ',
    here_link: 'aquí',
    cant_continue:
      'Si no puedes continuar con el proceso pulsa <a target="_blank" href="https://www.vivus.es/faq/como-puedo-cambiar-mis-datos">aquí</a>',
    cant_continue_tooltip:
      'Escríbenos un email a <a href="mailto:info@vivus.es">info@vivus.es</a> indicándonos el nuevo número de teléfono móvil que quieres registrar y adjuntando fotografía de tu DNI/NIE por ambas caras.',
  },

  two_factor_auth_change_phone: {
    html_title: '2FA change phone - Vivus.es',
    heading: 'Indica tu nuevo número de teléfono',
    text: 'Para modificar tu teléfono de forma segura necesitamos verificar tu identidad. Para ello utilizamos la herramienta TINK que se conecta con tu banca online y nos permite confirmar tu identidad. <a target="_blank" href="https://www.vivus.es/faq/como-puedo-cambiar-mi-numero-de-telefono-o-de-movil">Más información</a> . <a target="_blank" href="https://www.vivus.es/condiciones/politica-de-privacidad">Política de privacidad</a>.',
    checkbox: 'Quiero cambiar mi número de teléfono',
    error_already_in_use:
      'No es posible realizar el cambio con este número de teléfono',
  },

  login: {
    html_title: 'Inicio de sesión - Vivus.es',
    header: '¡Bienvenido!',
    email: 'Email o DNI/NIE',
    password: 'Contraseña',
    submit: 'Accede a tu área personal',
    forgot_password: 'Generar nueva contraseña',
    menu_blog: 'Blog',
    menu_help: '¿Te ayudamos?',
    menu_how_works: 'Cómo funciona',
    menu_offer: 'Oferta',
    help_title: '¿Necesitas ayuda?',
    ready_to_answer: '¡Resolvemos todas tus dudas!',
    help_text1: 'Consulta las',
    help_text2: 'preguntas más frecuentes',
    finance_group: `© ${COMPANY_NAME_4FINANCE}, sociedad de nacionalidad española, perteneciente a 4Finance Group`,
    working_hours: 'Horario de atención:',
    work_normal: 'Lunes – Viernes / 8:00 – 21:00',
    work_holidays: 'Festivos nacionales / 9:00 – 14:00',
    support_email: 'info@vivus.es',
    support_online_email: 'info@vivusonline.es',
    work_saturday: 'Sábado / 9:00 – 20:00',
    mobile_header: '¡Descárgate la app de Vivus!',
    mobile1:
      'Inicia sesión usando <strong>Reconocimiento facial</strong> o <strong>Huella dactilar</strong>',
    mobile2: '<strong>Gestiona tu préstamo</strong> desde donde tú quieras',
    mobile3: '<strong>De forma fácil, rápida y segura</strong>',
    affiliates:
      'Tu préstamo ha sido pre-aprobado. Entra en tu cuenta Vivus y finaliza la solicitud.',
    edit: 'Modificar',
    change_email_title: 'Correo electrónico',
    change_email_description:
      'Si quieres modificar tu correo electrónico contacta con nosotros:',
    change_email_close: 'Cerrar',
  },

  extensions: {
    html_title: 'Aplazar el pago — Vivus.es',
    heading_not_overdue_product: '¿Cuántos días quieres aplazarlo?',
    subtitle: 'Paga el importe correspondiente para aplazar la fecha de pago.',
    subtitle_not_overdue_product:
      'Paga el importe correspondiente para aplazar la fecha de pago.',
    heading: 'Pausa tu deuda',
    overdue: 'Paga {fee} € en una nueva fecha',
    overdueTo25_8:
      'Te condonamos {{penalties}} € de penalizaciones y paga <span>{{fee}} €</span> en una nueva fecha',
    overdueFrom26_8:
      'Te condonamos {{penalties}} € de penalizaciones y paga <span>{{fee}} €</span> en una nueva fecha',
    overdueWarning:
      'Evita <strong>{{penalties}}</strong> € de penalizaciones y paga <span>{{fee}} €</span> en una nueva fecha.',
    subtitle7_14: '¿Necesitas unos días más?',
    date: 'Pausa hasta el',
    extensionBtn: '¡LO QUIERO!',
    extensionSelectedBtn: 'ELEGIDO',
    termSeparatorMobile: '¿No sabes cuándo podrás pagar?',
  },

  extension_payment: {
    html_title: 'Aplazar el pago de préstamo — Vivus.es',
    mobile_header: 'Realizar pago',
    subtitle:
      'Una vez contratado el aplazamiento de {{term}} tu nueva fecha de pago será el {{date}}.',
    card_info: 'Esta es la opción más cómoda y rápida.',
    heading: 'Elige un método de pago',
    card_heading: 'Pagar con tarjeta bancaria',
    bank_heading: 'Transferencia bancaria',
    bank_info: 'De 24 a 48 horas hábiles para procesar el pago.',
    bizum_heading: 'Pagar con Bizum',
    payNowCard: {
      title: 'Paga ahora',
      description: 'y devuelve tu préstamo el',
    },
    pauseDebt: {
      title: '¿No puedes pagar hoy?',
      description:
        'Elimina las penalizaciones generadas y pausa tu deuda pagando una pequeña cantidad.',
    },
    bizum_info: '¡La nueva opción más rápida!',
  },

  extension_success: {
    html_title: 'Aplazar el pago de préstamo — Vivus.es',
    mobile_heading: 'Pago en proceso',
    heading: 'Pago en proceso',
    titleDescription: 'Una vez procesado el pago:',
    description:
      '<ul><li>Recibirás confirmación vía SMS (en unos minutos) con tu nueva fecha de pago</li><li>Actualizaremos la información de tu préstamo en tu área de cliente</li><li>Si has seleccionado el cargo automático, en la nueva fecha de vencimiento cargarmeos el pago de tu préstamo.</li></ul>',
  },
  card_payments: {
    html_title:
      'Ten lista tu tarjeta, necesitaremos que introduzcas la numeración - Vivus.es',
    title: 'Ten lista tu tarjeta, necesitaremos que introduzcas la numeración',
    mobile_heading: 'PAGO DEL PRÉSTAMO',
    check_box: 'Activa el pago automático de tus préstamos.',
    check_box_more_information: 'Más información',
    pay_button: 'Pagar {{amount}}',
    active_card: {
      title: 'Pago con tarjeta de débito o crédito',
      card_title: 'Tu tarjeta',
    },
    success: {
      mini: {
        html_title: 'Pago en proceso – Vivus.es',
        title: 'Pago en proceso',
        mobile_heading: 'Pago en proceso',
        info_title: 'Una vez procesado el pago:',
        info: '<ul><li>Recibirás confirmación vía SMS (en unos minutos) con tu nueva fecha de pago.</li><li>Actualizaremos la información de tu préstamo en tu área de cliente.</li><li>Si has seleccionado el cargo automático, en la nueva fecha de vencimiento cargaremos el pago de tu préstamo.</li></ul>',
      },
      cons: {
        html_title: 'Pago en proceso – Vivus.es',
        title: 'Pago en proceso',
        mobile_heading: 'Pago en proceso',
        titleInfo: 'Una vez procesado el pago de tu cuota:',
        info: '<ul><li>Recibirás confirmación vía SMS (en unos minutos)</li><li>Actualizaremos la información de tu préstamo en tu área de cliente</li><li>Si has seleccionado el cargo automático, en la nueva fecha de vencimiento cargarmeos el pago de tu préstamo.</li></ul>',
      },
    },
  },

  repayment_wire_transfer: {
    html_title: 'Pago de préstamo — Vivus.es',
    mobile_heading: 'Realizar pago',
    heading_mini: 'Transferencia bancaria',
    heading_cons_and_extension: 'Transferencia bancaria',
    choose_bank: 'Elige el banco',
    choose_bank_description: 'Banco al que haces la transferencia',
    select_bank: 'Seleccionar banco',
    bank_details: 'Realiza el pago a través de tu banca online',
    bank_details_description:
      '1. Accede a tu banca online <br /><br /> 2. Realiza una transferencia utilizando los siguientes datos',
    bank_details_info_repayment:
      'El importe de la cantidad total a pagar incluye una comisión de cancelación anticipada del 0,5% del principal. Las transferencias entre bancos pueden tardar hasta 48h. Te confirmaremos la recepción de tu pago por E-mail y SMS.',
    bank_details_info_extension:
      'Las transferencias entre bancos pueden tardar hasta 48h. Te confirmaremos la recepción de tu pago por E-mail y SMS',
    complete: 'Hecho',
    copy_label: 'Copiar',
    bank_account_label: 'Cuenta bancaria',
    contract_number_label: 'Número del contrato',
    contract_number_notification: 'Anótalo en el concepto de tu transferencia',
    payment_amount: 'Cantidad total a pagar',
    other_bank: 'Otro banco',
  },

  calculator: {
    amount_heading: '¿Cuánto dinero necesitas?',
    term_heading: '¿En cuántos días quieres devolverlo?',
    instalment_heading: '¿En cuántos meses quieres devolverlo?',
    term_unit: 'días',
    mini: 'En un pago',
    cons: 'A plazos',
  },

  instalment_card: {
    available_from: 'Disponible desde {{amountFrom}}',
    available_to: 'Disponible hasta {{amountTo}}',
    payment: 'Pago mensual',
    intrest: 'Intereses',
    total_to_return: 'Total a devolver',
  },

  repeated_loan: {
    html_title: 'Mi área personal - Vivus.es',
    welcome: 'Hola {{firstName}}',
    heading: 'Elige importe y plazo',
    payment_schedule: 'Plan de pago',
    requested_amount: 'Importe solicitado',
    interest: 'Intereses',
    total_to_return: 'Total a devolver',
    term: 'Plazo',
    last_payment_date: 'Fecha del último pago',
    repayment_date: 'Fecha del pago',
    apply: 'Solicitar mi préstamo',
    missing_personal_id_notification_text:
      'Facilítanos una fotografía de tu DNI / NIE por ambas caras para finalizar tu solicitud y transferirte los {{amount}} €.',
    missing_personal_id_button: 'Sube tu documentación',
    legal_terms: 'Información sobre el préstamo',
    cons_banner: '¡Pide tu préstamo desde 400€ y págalo a plazos!',
  },

  check_documents: {
    additionalDocuments:
      '+ Documentos adicionales si eres ciudadano/a de la UE (DNI o pasaporte de tu país)',
    cta_subtext: 'Puede tardar hasta 1 día',
    html_title: 'Sube tu DNI / NIE - Vivus.es',
    header: 'Sube tu DNI o NIE para seguir disfrutando Vivus',
    subheader:
      'En cumplimiento con el Real Decreto de 2014/14, necesitamos verificar tu identidad. Puede tardar hasta 1h.',
    confirm_button: 'ENVIAR PARA APROBACIÓN',
    email_button: 'ENVIAR DOCUMENTOS VÍA E-MAIL',
    modalInfoDoc: {
      text: 'Asegúrate de que las fotos de tu DNI/NIE sean legibles, nítidas y muestren el documento completo sobre un fondo de color neutro.',
      button: 'CONTINUAR',
    },
    modalNewDocument: {
      title: '¿Qué tipo de documento desea subir?',
      selectPlaceholder: 'Seleccione tipo de documento.',
      submitBtn: 'CONTINUAR',
      titleSecondStep: 'Subir {{documentType}}',
      selectFile: 'SELECCIONAR ARCHIVO',
    },
  },

  loan_check_details: {
    html_title: 'Comprobando tu información – Vivus.es',
    mobile_page_title: 'Comprobando tu información',
    title: 'Comprobando tu información',
    text: 'Por favor mantente a la espera y no cierres esta ventana. Este proceso puede tardar hasta 5 minutos.',
  },

  additional_amount: {
    html_title: 'Importe adicional – Vivus.es',
    heading: 'Puedes solicitar hasta {{maxAmount}} € adicionales',
    amount_heading: '¿Cuánto dinero necesitas?',
    info: 'Por favor, ten en cuenta que estás incrementando el importe de tu crédito pero manteniendo tu fecha de devolución actual.',
    apply: 'CONTINUAR',
    mobile_header: 'SOLICITA MÁS DINERO',
    additional_amount: 'Importe Adicional',
    interest: 'Interés',
    total_to_return: 'Total a devolver',
    repayment_date: 'Fecha de devolución',
  },

  loan_approved: {
    mobile_heading_main_loan: 'NUEVO PRÉSTAMO',
    mobile_heading_additional_amount: 'SOLICITA MÁS DINERO',
    html_title_main_loan: 'Préstamo concedido – Vivus.es',
    html_title_additional_amount:
      '¡Enhorabuena! Se ha aprobado la ampliación del importe de tu préstamo – Vivus.es',
    heading_additional_amount:
      '¡Enhorabuena! Se ha aprobado la ampliación del importe de tu préstamo',
    heading_main_loan: '¡Enhorabuena! ¡Tu préstamo ha sido concedido!',
    btn: 'Entendido',
    inner_card: {
      heading: 'Tiempo aproximado para recibir el dinero en tu cuenta',
      description:
        'Por favor, revisa el SMS y el E-mail que te hemos enviado donde podrás encontrar la documentación relativa a tu crédito.',
      bank_title: 'El dinero será transferido a esta cuenta:',
      a: 'a',
      hours: '48',
      h: 'h.',
      de: 'De',
      min: 'min.',
      time_min: '15',
    },
  },

  rejected_or_failure: {
    failure_by_card: {
      html_title: 'Lo sentimos, реrо el pago por tarjeta ha fallado - Vivus.es',
      title: '¿Tienes otra tarjeta?',
      mobile_heading: 'Pago no realizado',
      description:
        'Desafortunadamente, la tarjeta introducida no es válida para realizar este pago. Por favor, inténtalo con otra tarjeta bancaria.',
      button: 'Intentar con otra tarjeta',
    },
    loan_rejected_main: {
      mobile_heading: 'NUEVO PRÉSTAMO',
      html_title: 'Préstamo rechazado – Vivus.es',
      description:
        'Las causas más frecuentes por las que no somos capaces de ofrecerte un préstamo son: Haber tenido algún impago con nosotros en el pasado; Estar incluido en ficheros de morosidad; Tener algún otro crédito abierto en estos momentos. <br /><br /> <strong>Seguimos en contacto</strong> <br /> Nos gustaría poder ofrecerte un crédito en el futuro, por lo que no dejes de contactar de nuevo con nosotros pasado un tiempo.',

      title: 'Lo sentimos, en este momento no podemos ofrecerte un préstamo',
      button: 'entendido',
    },
    loan_rejected_additional: {
      mobile_heading: 'SOLICITA MÁS DINERO',
      html_title:
        'Lo sentimos, no puedes solicitar una cantidad adicional - Vivus.es',
      description: 'Has alcanzado tu límite de crédito',

      title: 'Lo sentimos, no puedes solicitar una cantidad adicional',
      desc: 'Las causas más frecuentes por las que no somos capaces de ofrecerte un préstamo son: Haber tenido algún impago con nosotros en el pasado; Estar incluido en ficheros de morosidad; Tener algún otro crédito abierto en estos momentos. <br /><br /> <strong>Seguimos en contacto</strong> <br /> Nos gustaría poder ofrecerte un crédito en el futuro, por lo que no dejes de contactar de nuevo con nosotros pasado un tiempo.',
      button: 'entendido',
    },
    loan_failed_main: {
      mobile_heading: 'NUEVO PRÉSTAMO',
      html_title: 'Préstamo fallido – Vivus.es',
      title: 'No ha sido posible cursar la solicitud para ampliar tu crédito',
      description: 'Por favor, inténtalo de nuevo en unos minutos',
      button: 'Entendido',
    },
    loan_failed_additional: {
      mobile_heading: 'SOLICITA MÁS DINERO',
      html_title: 'Préstamo fallido – Vivus.es',
      title: 'No ha sido posible cursar la solicitud para ampliar tu crédito',
      description: 'Por favor, inténtalo de nuevo en unos minutos',
      button: 'Entendido',
    },
    extension_rejected: {
      mobile_heading: 'Aplazar el pago de préstamo',
      html_title: 'Aplazar el pago de préstamo — Vivus.es',
      title: 'Tu pago no se ha realizado',
      description: `Puedes intentar de nuevo o elegir otro método de pago. Si tienes preguntas llámanos al <strong>{{phone}}</strong>.`,
      button: 'Intentar de nuevo',
      link: 'Elegir otro método de pago',
    },
    card_failure: {
      mobile_heading: 'Pago no realizado',
      html_title: 'Lo sentimos, реrо el pago por tarjeta ha fallado - Vivus.es',
      title: 'Tu pago no se ha realizado',
      description: `Puedes intentar de nuevo o elegir otro método de pago. Si tienes preguntas llámanos al <a href="tel:{{phoneLink}}">{{phone}}</a>`,
      button: 'Intentar de nuevo',
      link: 'Elige otro método de pago',
    },
    payment_duplicated_rejection: {
      mobile_heading: 'Aplazar el pago de préstamo',
      html_title: 'Aplazar el pago de préstamo — Vivus.es',
      title: 'Lo sentimos, el pago ha fallado',
      description:
        'Parece que ya has intentado realizar un pago. Por favor, espera unos minutos antes de intentarlo de nuevo.',
      button: 'Entendido',
    },
    extension_limits_rejected: {
      mobile_heading: 'Aplazar el pago de préstamo',
      html_title: 'Aplazar el pago de préstamo — Vivus.es',
      title: 'Lo sentimos, el pago por tarjeta ha fallado',
      description: `Puedes intentarlo de nuevo en unos minutos o utilizar otra tarjeta. Si tienes preguntas llámanos al <strong>{{phone}}</strong>`,
      button: 'Intentar de nuevo',
    },
  },

  alternative: {
    html_title: 'Envíanos tu DNI / NIE - Vivus.es',
    header: 'Envía tu DNI / NIE',
    subtitle:
      'Si eres ciudadano de la Unión Europea, envía tu Certificado de Registro de Ciudadano UE junto a tu Pasaporte o DNI de país de origen en vigor',
    subtitleEmail:
      'Si no puedes enviar las fotografías ahora, toma nota de nuestro E-mail para enviarlas más tarde.',
    id_front_text: 'DNI / NIE (DELANTERA)',
    id_back_text: 'DNI / NIE (TRASERA)',
    mail_title: 'Vía E-mail',
    mail: 'documentacion@vivus.es',
    mailVoluntary: 'dni@vivus.es',
    noThankYou: 'Hoy no, gracias.',
    confirm_button: 'He enviado los documentos',
    timer_text: 'Envíanos las fotografías antes de que tu solicitud expire:',
  },

  loan_confirm: {
    html_title: 'Revisa y confirma tu solicitud - Vivus.es',
    heading: 'Revisa y confirma tu solicitud',
    mobile_heading: 'NUEVO PRÉSTAMO',
    payment_schedule: 'Plan de pago',
    requested_amount_main: 'Importe solicitado',
    requested_amount_additional: 'Importe del nuevo préstamo',
    interest_main: 'Intereses',
    interest_additional: 'Interés total del préstamo',
    total_to_return: 'Total a devolver',
    term: 'Plazo',
    last_payment_date: 'Fecha del último pago',
    repayment_date: 'Fecha del pago',
    bank_account: 'Cuenta bancaria',
    bank_account_info: `Si has cambiado de cuenta o quieres recibir el dinero en otra, por favor, contacta con nosotros llamando al {{phone}} antes de confirmar tu solicitud.`,
    back_affiliates_button: 'MODIFICAR IMPORTE / PLAZO',
    confirm_button: 'Confirmar',
    confirm_button_additional: 'AMPLIAR MI PRÉSTAMO',
    consents:
      'He leído y acepto los <a href="https://www.vivus.es/condiciones/generales" target="_blank" rel="noopener">Términos y Condiciones</a>, la <a href="https://www.vivus.es/condiciones/politica-de-privacidad" target="_blank" rel="noopener">Política de Privacidad</a> y la ',
    consents_link: 'Información Normalizada Europea del Crédito.',
    additional_cesco:
      'Declaro estar informado/a de que un micropréstamo es una categoría específica de préstamos, caracterizada por solicitudes de cantidades pequeñas a corto plazo, y que el coste informado de este préstamo se ha confirmado como igual o inferior al promedio según los datos publicados por el Centro de Estudios de Consumo <a href="https://www.vivus.es/prestamos-responsables" target="_blank" rel="noopener">CESCO.</a>',
  },

  extension_card: {
    html_title:
      'Ten lista tu tarjeta, necesitaremos que introduzcas la numeración - Vivus.es',
    title: 'Importe a pagar',
    check_box_overdue:
      'Activa el pago  automático y nos encargaremos de cobrar los {{amount}} € de tu préstamo en la nueva fecha de vencimiento {{date}}',
    check_box: 'Activa el pago automático de tus préstamos.',
    check_box_more_information: 'Más información',
    pay_button: 'Hacer pago',
    active_card: {
      title: 'Pagar con tarjeta',
      html_title:
        'Ten lista tu tarjeta, necesitaremos que introduzcas la numeración - Vivus.es',
      card_title: 'Tu tarjeta',
    },
    summary_card: {
      title: 'Resumen del pago',
      price: 'Importe',
      debt_pause: 'Pausar deuda',
    },
    modal: {
      title: 'PAGO AUTOMÁTICO',
      description:
        'Para hacer tu experiencia con nosotros aún más cómoda, vamos a presentarte nuestra utilidad de pago automático.<br /> <br /> Al seleccionarlo en esta casilla, tu tarjeta bancaria quedará registrada de forma 100% segura y en ella se cargarán tus préstamos en la fecha de vencimiento y ¡ya está! Puedes actualizar tus preferencias en tu perfil en cualquier momento o consultar la sección de <a href="https://www.vivus.es/faq/que-es-y-como-se-usa-el-pago-automatico" target="_blank" rel="noopener">FAQS</a> para más detalles.',
      subtitle: 'Principales Beneficios:',
      list_1:
        'Garantiza tu pago en la fecha correcta y evitas cargos adicionales',
      list_2: 'Olvídate de acceder y procesar el pago manualmente.',
      list_3: 'Genera mejores condiciones para tu próximos préstamos',
    },
    overdueBrokenLoan: {
      title: 'Pago parcial',
      description:
        'Si no puedes afrontar el pago total, haz un pago parcial para reducir tu deuda.',
      table_title: '¿Cuánto puedes pagar?',
      table_amount: 'Importe',
      table_contract: 'Contrato nº',
      cto: 'hacer pago',
    },
  },

  approved_documents: {
    html_title: 'Documentos aprobados - Vivus.es',
    header: '¡Ya casi estamos!',
    subtitle:
      'En cumplimiento del Real Decreto 304/2014 de 5 de mayo, envíanos tu DNI/NIE en vigor por ambas caras',
    text: 'Una vez revisados tus documentos te enviaremos una notificación.',
    text2: 'Si lo necesitas, puedes enviarlos de nuevo al',
    text2Email: 'documentacion@vivus.es',
    text2EmailVoluntary: 'dni@vivus.es',
    text2BeforePhone: 'o llamar al',
    button: 'Entendido',
  },

  pending_documents: {
    html_title: 'Documentos aprobados - Vivus.es',
    header: '¡Necesitamos verificar tu identidad!',
    subtitle:
      'En cumplimiento del Real Decreto 304/2014 de 5 de mayo, envíanos tu DNI/NIE en vigor por ambas caras',
    text: 'Si ya lo has enviado, recibirás una notificación.',
    text2: 'Si no lo has hecho, continúa ',
    text2Link: 'aquí.',
  },
  active_loan_debt_restructuring: {
    calendar_section_7: {
      title: 'Disminuye tu cuota aumentando el plazo',
      subtitle:
        'Reduce el importe de tu cuota pagando tu deuda en 8 mensualidades',
      desc: 'Esta oferta expira el <strong>{{date}}</strong>',
    },
    calendar_section: {
      title: 'Paga tu deuda en 6 cuotas mensuales',
      desc: 'Esta oferta expira el <strong>{{date}}</strong>',
    },
    calendar_offer_ready: {
      title: 'Ya tenemos preparado tu plan de pagos',
      desc: 'Dispones hasta el <strong>{{date}}</strong> para firmar tu acuerdo y realizar el primer pago',
    },
    calendar_initiated_info_section: {
      title: 'Estamos preparando tu plan de pagos',
      desc: 'Cuando esté listo te enviaremos un email.',
    },
    debt_section: {
      title: 'Deuda pendiente',
      contract: 'Contrato nº {{contract}}',
      amount_desc: 'Importe a pagar <b>hoy</b> para cancelar tu préstamo',
    },
    payment_section: {
      card: 'Pagar con tarjeta bancaria',
      bizum: 'Pagar con Bizum',
    },
    support_section: {
      info: 'Estamos aquí para ayudarte. Si tienes cualquier duda, contacta con nosotros',
      work_hours_weekdays: 'Lunes a Viernes de 8:00 a 18:00 horas',
      work_hours_weekends: 'Sábados de 9:00 a 13:00 horas',
    },
    offer_cons: {
      bulletsCard: {
        title: '¿No puedes pagar tu cuota?',
        bullet1:
          'Queremos ayudarte con el pago de tu deuda <strong>reduciendo los intereses</strong>, aumentando el número de cuotas y <strong>disminuyendo el importe de las mismas.</strong>',
        bullet2:
          'Dividiremos el total de tu préstamo en <strong>8 cuotas</strong> y, una vez tengamos preparado tu nuevo plan de pagos, te enviaremos un mail para que puedas revisarlo y firmarlo.',
        bullet3:
          '<strong>Debes pagar a tiempo</strong> tus nuevas cuotas. Su incumplimiento implicará la reclamación de la totalidad de la deuda.',
        checkbox: 'Estoy conforme y quiero iniciar el proceso.',
      },
      stepsCard: {
        title: '¿Qué debo hacer?',
        step1: 'Iniciar el proceso',
        step2: 'Esperar 48 horas para recibir el acuerdo',
        step3: 'Revisar y firmar el acuerdo',
        step4: 'Realizar el primer pago',
        cto: 'INICIAR EL PROCESO',
      },
      loan_info_card: {
        contract_number: 'Contrato nº {{contract}}',
        title: 'Desglose de tu deuda',
      },
    },
    offer: {
      desc: 'Para facilitar el pago de tu deuda, te ofrecemos abonarla en 6 cuotas mensuales.',
      stepsCard: {
        title: '¿Qué debo hacer?',
        step1: 'Iniciar el proceso',
        step2: 'Esperar 48 horas para recibir el acuerdo',
        step3: 'Revisar y firmar el acuerdo',
        step4: 'Realizar el primer pago',
        cto: 'INICIAR EL PROCESO',
      },
      loan_info_card: {
        contract_number: 'Contrato nº {{contract}}',
        title: 'Desglose de tu deuda',
      },
    },
    offerAccepted: {
      calendarCard: {
        title: 'Ya tenemos preparado tu plan de pagos',
        desc: 'Dispones de 5 días para firmar tu acuerdo y realizar el primer pago. (Antes del 26/05/2024)',
      },
      stepsCard: {
        title: 'Siguientes pasos:',
        leftSide: {
          agreementSigned: 'Acuerdo firmado',
          title: 'Revisa y firma el nuevo acuerdo:',
          desc: 'Con la firma de tu nuevo acuerdo, transformas tu deuda en un plan de pagos de {{installments}} cuotas.',
          row1Debt: 'Deuda',
          row2Fee: 'Cuota',
          row3ManagementFee: 'Gastos de gestión',
          cto: 'revisar acuerdo',
        },
        rightSide: {
          title: 'Realiza el primer pago:',
          desc: 'El primer pago incluye la primera cuota de {{fee}} y los gastos de gestión {{managementFee}} ',
          cto: 'hacer pago',
          payment_in_process: 'Primer pago realizado',
          footer:
            'Los gastos de gestión se pagan una única vez en el primer pago que se realiza junto a la primera cuota. Ten en cuenta que el pago, una vez hecho, puede tardar unos minutos en procesarse.',
        },
      },
      waitingMsg:
        'Mañana podrás ver aquí, en tu área de cliente, todos los detalles de tu nuevo plan de pagos.',
    },
    modals: {
      offerRequested: {
        title: 'Plan de pagos solicitado',
        desc: 'En menos de 48 horas, tendrás disponible tu acuerdo para que puedas revisarlo y firmarlo.',
        cto: 'ENTENDIDO',
      },
      offerPending: {
        title: 'Tu acuerdo estará listo en:',
        desc: 'En breve tendrás preparado tu plan de pagos personalizado. Te avisaremos por SMS cuando esté listo.',
        cto: 'ENTENDIDO',
      },
      installmentPaid: {
        title: 'Plan de pagos aprobado',
        agreementSigned: 'Acuerdo firmado',
        paymentDone: 'Primer pago realizado',
        cto: 'PROCESO FINALIZADO',
      },
      newAgreement: {
        title: 'Nuevo acuerdo:',
        row1Debt: 'Deuda',
        row2Fee: 'Cuota',
        row3ManagementFee: 'Gastos de gestión',
        checkbox:
          'He leído y estoy conforme con las condiciones de reestructuración de mi préstamo Vivus vigente.',
        cto: 'aceptar y firmar',
      },
    },
    overdue: {
      card_heading: 'Tu plan de pagos',
      card_title: 'Cuota',
      notificationList: {
        row1: 'Tu cuota está impagada.',
        row2: 'Dispones hasta el <strong>{{date}}</strong> para hacer el pago.',
        row3: 'De no hacerlo te reclamaremos el total de la deuda.',
      },
    },
    payment: {
      heading: 'Elige un método de pago',
      title: 'Paga ahora',
      footerAmount: 'Para iniciar la reestructuración de tu deuda.',
      summary: 'Resumen',
      row1: 'Primera cuota',
      row2: 'Gastos de gestión',
      cardPayment: 'Pagar con tarjeta bancaria',
      bizumPayment: 'Pagar con Bizum',
    },
    paymentSchedule: {
      heading: 'Desglose de tu deuda',
      term: 'Plazo',
      total: 'Total pendiente',
      cardPayment: 'Pagar con tarjeta bancaria',
      bizumPayment: 'Pagar con Bizum',
      footerDesc:
        'Si adelantas una cuota, se descontará del mes en curso y, si pagas más, se descontarán del final del préstamo, acortando su duración. Es una amortización en plazo, así que el importe de la cuota no cambiará y deberás pagar las cuotas restantes mensualmente hasta finalizar el préstamo. Aunque procesaremos los pagos de inmediato y te enviaremos confirmación por SMS, tu plan de pagos no quedará actualizado hasta el día siguiente.',
    },
  },

  active_loan: {
    html_title: 'Mi área personal - Vivus.es',
    brokenloan_dpd_70:
      'Si no solucionas tu impago, tu deuda podría ser cedida a otro acreedor',
    heading: 'Hola {{name}}',
    amount: 'Mi préstamo activo',
    amount_7CONS: 'Mi próxima cuota',
    modal_button: 'ENTENDIDO',
    monthly_payment: 'Pago mensual',
    monthly_payment_7CONS: 'Fecha de pago',
    repayment_date: 'Fecha de devolución',
    contract_number: 'Contrato nº {{contract}}',
    amount_info: 'Total a devolver hoy',
    days_left_one: 'Falta {{count}} día',
    days_left_other: 'Faltan {{count}} días',
    loan_overview_not_overdue_product: 'Datos de tu préstamo',
    loan_overview: 'Desglose de tu deuda',
    loan_overview_7_cons: 'Información de tu préstamo',
    breakdown_debt: 'Desglose de tu deuda',
    schedule_amount_info: 'Fecha de pago {{date}}',
    payment_number: 'Pago {{number}}',
    make_repayment: 'HACER PAGO',
    days_overdue_one:
      '{{count}} Día de retraso <br />Fecha de devolución {{date}}',
    days_overdue_other:
      '{{count}} Días de retraso <br />Fecha de devolución {{date}}',
    loan_info: 'Información sobre el préstamo',
    invoice: 'Descargar la factura',
    additional_amount_unavailable: 'INDISPONIBLE',
    additional_amount_title: '¿Necesitas más dinero?',
    additional_amount_text: 'Tienes hasta {{amount}} € disponibles.',
    additional_amount_text_disabled:
      'Lamentamos comunicarte que en estos momentos esta opción no es posible. Te avisaremos por correo electrónico en cuanto esté disponible de nuevo.',
    extension_title: '¿Necesitas más tiempo?',
    extension_text:
      'Tienes la opción de aplazar la fecha de vencimiento del pago por un coste adicional.',
    overdue_title: 'No hemos recibido el pago',
    overdue_text:
      'Contacta con nosotros lo antes posible para evitar que el importe de tu deuda continúe incrementándose.',
    total_to_repay: 'Total a pagar',
    loan_summary: {
      requested_amount: 'Importe concedido',
      principal_amount: 'Principal pendiente',
      interest_amount: 'Intereses',
      penalty_amount: 'Penalizaciones',
      commission_amount: 'Comisión',
      total_invoiced_amount: 'Importe total pendiente',
      term: 'Duración del préstamo',
      payment_schedule: 'Plan de pagos',
      has_extension:
        'Has contratado un aplazamiento. Revisa la nueva fecha de pago.',
      repayment_date: 'Fecha del pago',
      last_repayment_date: 'Fecha de vencimiento',
      already_paid: 'Importe pagado',
    },
    broken_loan_summary: {
      requested_amount: 'Importe concedido',
      principal_amount: 'Principal pendiente',
      interest_amount: 'Intereses',
      penalty_amount: 'Penalizaciones',
      commission_amount: 'Comisiones',
      total_invoiced_amount: 'Importe total pendiente',
      term: 'Duración del préstamo',
      payment_schedule: 'Plan de pago',
      has_extension:
        'Has contratado un aplazamiento. Revisa la nueva fecha de pago.',
      repayment_date: 'Fecha del pago',
      last_repayment_date: 'Fecha de vencimiento',
      already_paid: 'Importe pagado',
    },
    overdue_custom_solution: {
      title: '¿Necesitas una solución personalizada?',
      info: 'Más información',
    },
    overdue_section: {
      overdue_error_notif:
        'Si no solucionas tu impago, tus datos podrían incluirse en ficheros de morosidad.',
      overdue_error_broken_notif:
        'Si no solucionas tu impago, tu deuda podrá ser cedida a otro acreedor.',
      penaltyDebtCard: {
        penaltiesSection: {
          title: 'Penalizaciones y Comisiones',
          descriptionWarning:
            'Van a ser añadidos a tu préstamo.<br /> <b>¡Aún estás a tiempo de evitarlo <br /> pagando o pausando tu deuda!</b>',
          descriptionWarning7CONS:
            'Van a ser añadidos a la mensualidad impagada.<br />',
          descriptionWarning7CONS_part_2:
            '<b>¡Aún estás a tiempo de evitarlo pagando tu cuota!</b>',
          description:
            'Han sido añadidos a tu deuda que seguirá incrementándose diariamente con otras penalizaciones hasta que se resuelva.',
          description_cons_7:
            'Han sido añadidos a tu cuota, que seguirá incrementándose diariamente con otras penalizaciones hasta que se haga el pago.',
        },
        debtSection: {
          contract: 'Contrato nº {{contract}}',
          description: 'Importe a pagar <b>hoy</b> para cancelar tu préstamo',
          submitBtn: 'PAGAR DEUDA',
          title: 'Tu deuda',
        },
        debtSection7Cons: {
          contract: 'Contrato nº {{contract}}',
          description: 'Importe a pagar <b>hoy</b> para regularizar tu deuda',
          submitBtn: 'PAGAR CUOTA',
          title: 'Cuota impagada',
        },
      },
      time: {
        title: 'Para que te reclamemos el total de tu deuda.',
        description: 'Última oportunidad de pausar tu deuda.',
        description_7:
          'Última oportunidad para evitar la reclamación del total de tu deuda si pagas la cuota impagada.',
        description_mini8:
          'Última oportunidad de pausar tu deuda y eliminar penalizaciones.',
      },
      extensionCard: {
        description:
          '¡Gana tiempo! Pausa tu deuda pagando una pequeña cantidad.',
        description_mini8:
          'Elimina las penalizaciones generadas y pausa tu deuda pagando una pequeña cantidad.',
        description_6:
          '¡Gana tiempo! Pausa tu deuda pagando una pequeña cantidad.',
        submitBtn: 'PAUSAR DEUDA',
        title: '¿No puedes pagar hoy?',
      },
    },
    overdue_broken_desc:
      'Importe a pagar <strong>hoy</strong> para cancelar tu préstamo',
    submitBtn: 'PAGAR DEUDA',
    custom_solution: {
      title: '¿Necesitas una solución  personalizada?',
      desc: 'Más información',
      desc_link: 'aquí',
    },
    overdueBrokenModal: {
      title: 'Llámanos y buscaremos la solución que más se adapte a ti',
      email: 'recibos@vivus.es',
      phone: '91 084 05 59',
      work_hours_weekdays: 'Lunes a Viernes de 8:00 a 18:00 horas',
      work_hours_weekends: 'Sábados de 9:00 a 13:00 horas',
    },
  },

  discount: {
    heading: '¡Tu descuento te está esperando!',
    text_carry_over:
      'Tienes un descuento del {{discount}} aplicado en tu préstamo actual.',
    text_repeated_loan:
      'Pide ahora tu préstamo y disfruta de un {{discount}} de dto.',
    text_additional_amount:
      'Pide ahora tu importe adicional y disfruta de un {{discount}} de dto.',
    validity_extension: 'Descuento válido en aplazamientos de {{term}} días.',
    validity_additional_amount:
      'Descuento válido hasta el {{date}} para importes de entre {{min}} a {{max}}.',
    validity_repeated_loan:
      'Descuento válido hasta el {{date}} para importes de entre {{min}} a {{max}}.',
    validity_carry_over:
      'Si necesitas más dinero ¡solicítalo ahora y sigue aprovechando tu descuento!',
    interest_before_discount: 'Sin descuento',
    discount_applied: 'Descuento aplicado',
    extension_header: '¡Aprovecha este descuentazo!',
    banner_discount_text: '{{discount}} descuento',
  },

  not_found: {
    html_title: '404 Pagina no encontrada – Vivus.es',
    title: '404',
    subtitle: 'Estás muy cerca de empezar a sentirte Vivus.',
    description: 'Pincha en alguno de estos enlaces:',
    how_it_works: 'Cómo funciona',
    offer: 'Ofertas',
    blog: 'Blog',
    faq: 'Ayuda',
    contacts: 'Contacto',
    about_us: 'Sobre nosotros',
  },

  extension_schedule: {
    new_date: 'Nuevo',
  },

  faq: {
    html_title: 'Ayuda – Vivus.es',
  },

  password_change_success: {
    html_title: 'Contraseña guardada – Vivus.es',
    heading: 'Contraseña guardada',
    continue: 'Entendido',
  },

  change_temporary_password: {
    html_title: 'Modificar mi contraseña – Vivus.es',
    heading: 'Modificar mi contraseña',
    new_password_placeholder: 'Nueva contraseña',
    text: 'La contraseña debe contener al menos 8 carácteres, letras y números o carácteres especiales.',
    submit: 'Guardar contraseña',
  },

  news_consents: {
    html_title:
      'No te pierdas nuestros descuentos y promociones especiales. – Vivus.es',
    heading: 'No te pierdas nuestros descuentos y promociones especiales.',
    description: 'Acepta recibir comunicaciones comerciales.',
    btn: 'Si, acepto',
    decline_btn: 'Lo haré la próxima vez',
  },

  password_recovery: {
    html_title: 'Recuperación contraseña - Vivus.es',
    heading: 'Introduce tu email',
    description: {
      point1:
        'Recibirás un email con los pasos a seguir para generar una nueva contraseña',
      point2:
        'Si no te deja seguir, es posible que tengamos otro email registrado.',
      point2_link: '¿Quieres cambiarlo?',
    },
    email: 'Email',
    continue: 'enviar a mi email',
    cancel: 'Cancelar',
  },

  password_change: {
    html_title: 'Cambio de contraseña - Vivus.es',
    heading: 'Introduce tu nueva contraseña',
    email: 'Email',
    password: 'Nueva contraseña',
    repeat_password: 'Repetir nueva contraseña',
    description:
      'La contraseña debe contener al menos ocho carácteres siendo al menos uno de ellos un número',
    continue: 'guardar contraseña',
    cancel: 'Cancelar',
  },

  password_recovery_success: {
    html_title: 'La contraseña ha sido recuperada con éxito - Vivus.es',
    heading: '¡Enviado! <br /> Comprueba tu email',
    description:
      'Acabamos de enviar un email a {{email}} con las instrucciones para establecer una nueva contraseña',
    continue: 'ENTENDIDO',
  },
  voluntaryBanner: {
    title: '¡Aumenta las posibilidades de incrementar tu limite!',
    description:
      'Solo envía tu DNI/NIE en vigor por ambas caras y si tienes NIE comunitario, también DNI de tu país o pasaporte',
    uploadBtn: 'ENVIAR',
  },
  best_offer: {
    title: 'Oferta personalizada',
    description:
      'Gracias a los datos que nos has facilitado, hemos podido generar una oferta que se ajusta a tus necesidades ',
    card: {
      request: 'Solicitaste',
      offer: 'Podemos ofrecer',
      table: {
        title: 'Datos de tu préstamo',
        row1: 'Plazo',
        row2: 'Importe solicitado',
        row3: 'Intereses',
        row4: 'Total a devolver',
        row5: 'Fecha de devolución',
      },
    },
    consents:
      'He leído y acepto los <a href="https://www.vivus.es/condiciones/generales" target="_blank" rel="noopener">Términos y Condiciones</a>, la <a href="https://www.vivus.es/condiciones/politica-de-privacidad" target="_blank" rel="noopener">Política de Privacidad</a> y la ',
    consents_link: 'Información Normalizada Europea del Crédito.',
    submitBtn: 'Confirmar',
    footer: {
      title: '¿Cómo se ha calculado el importe de mi préstamo?',
      description: `En línea con nuestra política de financiación responsable, hemos analizado tus datos detenidamente para calcular el importe idóneo para tu primer préstamo Vivus. El importe máximo disponible para tus siguientes préstamos podrá aumentar siempre y cuando, entre otras variables, se mantenga un buen comportamiento de pago. Si tienes alguna consulta no dudes en contactar con nosotros <a href="tel:{phoneLink}">{phone}</a> o mail <a href="mailto:info@vivus.es">info@vivus.es</a>`,
    },
    legal_terms: 'Información sobre el préstamo',
  },
  signDrAgreement: {
    html_title: 'Firmar acuerdo - Vivus.es',
    title: 'Firma tu acuerdo',
    description:
      'Es imprescindible para formalizar la reestructuración de tu préstamo.',
    continue_btn: 'CONTINUAR',
    modal: {
      title: 'Revisa y acepta los términos del acuerdo',
      checkConditions:
        'He leído y estoy conforme con las condiciones de reestructuración de mi préstamo Vivus vigente.',
      submitBtn: 'FIRMAR',
    },
    footer_link: 'Firmar después',
  },
  signDrAgreementSuccess: {
    title: 'Enhorabuena',
    description:
      'El acuerdo de reestructuración de tu préstamo ha sido firmado con éxito.',
    agree_btn: 'ENTENDIDO',
  },
  cardMissingModal: {
    footer: 'Hoy no, gracias.',
    subCard: '¡Solo tarda un minuto!',
    submitBtn: 'REGISTRAR MI TARJETA',
    title:
      '¡Registra tu tarjeta bancaria y activa el pago automático para decir adios a los intereses de demora!',
  },
  saveCard: {
    html_title: 'GUARDAR TARJETA',
    title: 'Registra tu tarjeta bancaria',
    description:
      'Para finalizar, registra tu tarjeta bancaria y activa el pago automático. Nosotros nos encargaremos de cobrar tu préstamo en la fecha correcta.',
    submit_btn: 'CONTINUAR',
    footer_card:
      '¡Di adios a los intereses de demora y disfruta de ventajas increíbles!',
    footer_link: 'No quiero registrar mi tarjeta ahora',
  },
  autorepay: {
    html_title: 'PAGO AUTOMÁTICO',
    title: 'Activa el pago automático',
    description:
      'Para finalizar, activa el pago automático. Nosotros nos encargaremos de cobrar tu préstamo en la fecha correcta. ',
    submit_btn: 'CONTINUAR',
    footer_card:
      '¡Di adios a los intereses de demora y disfruta de ventajas increíbles!',
    footer_link: 'No quiero activarlo ahora',
  },
  two_fa_phone_modified: {
    html_title: 'Teléfono modificado - Vivus.es',
    title: 'Verifica tu teléfono',
    description: 'Tu número de teléfono ha sido modificado correctamente.',
    button: 'ENTENDIDO',
  },
  two_fa_phone_error: {
    html_title: 'Error modificando teléfono - Vivus.es',
    title: 'Verifica tu teléfono',
    description:
      'Se ha producido un error y no ha sido posible modificar tu teléfono. Inténtalo de nuevo.',
    button: 'REINTENTAR',
    button_footer:
      'Si no puedes continuar con el proceso escríbenos un email a <a target="_blank" href="mailto:info@vivus.es">info@vivus.es</a> indicándonos el nuevo número de teléfono móvil que quieres registrar y adjuntando fotografía de tu DNI/NIE por ambas caras.',
  },
  breakdown_debt: {
    html_title: 'Desglose de tu deuda',
    title: 'Desglose de tu deuda',
    title_card: 'Cuota impagada',
    summary: {
      fee: 'Cuota',
      penalties: 'Penalizaciones',
      commission: 'Comisión',
    },
    card_payment: 'Pagar con tarjeta bancaria',
    bizum_payment: 'Pagar con bizum',
  },

  installment_payment: {
    html_title: 'Pago de instalación',
    title: 'Elige un método de pago',
    title_card: 'Paga ahora',
    footer_card: 'para regularizar tu deuda',
    summary: {
      fee: 'Cuota',
      penalties: 'Penalizaciones',
      commission: 'Comisión',
    },
    payment_schedule: {
      term: 'Plazo',
      pay: 'Importe total pendiente',
    },
    card_payment: 'Pagar con tarjeta bancaria',
    bizum_payment: 'Pagar con bizum',
  },
  dana: {
    html_title: 'DANA',
    title: 'Apoyo a los afectados por la DANA en España',
    description:
      'Sabemos que te encuentras en una de las zonas afectadas por la DANA. Entendemos que la situación es complicada y estamos aquí para apoyarte. Llámanos al 91 084 05 59 y buscaremos contigo una solución.',
    button: 'continuar',
  },
};

export default es;
