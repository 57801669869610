import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { memo, ReactNode } from 'react';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';
import classes from 'classnames';
import styles from './Notification.module.scss';

export type Props = {
  icon?: ReactNode;
  children: ReactNode;
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  dataTest?: string;
  type?: 'success' | 'error' | 'warning';
  className?: string;
  contentClassName?: string;
};

export default memo(function Notification({
  icon,
  children,
  onClose,
  dataTest = 'notification',
  type = 'success',
  className,
  contentClassName,
}: Props) {
  return (
    <div
      className={classes(styles.container, styles[type], className)}
      data-test={dataTest}
    >
      <div className={classes(styles.content, contentClassName)}>
        <div className={styles['type-icon']}>
          {(type === 'error' || type === 'warning') &&
            (icon ? icon : <ErrorIcon data-test={`${dataTest}-error-icon`} />)}
          {type === 'success' && (
            <SuccessIcon data-test={`${dataTest}-success-icon`} />
          )}
        </div>
        {children}
      </div>
      {onClose && (
        <button
          type="button"
          className={styles.close}
          onClick={onClose}
          data-test={`${dataTest}-button`}
        >
          <CloseIcon
            className={styles.icon}
            data-test={`${dataTest}-close-icon`}
          />
        </button>
      )}
    </div>
  );
});
