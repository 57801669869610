import { ReactNode } from 'react';
import classes from 'classnames';
import styles from './Placeholder.module.scss';

export type Props = {
  children: ReactNode;
  dataTest?: string;
  placeholder?: string;
  loading?: boolean;
  /** Replace current children with placeholder */
  replace?: boolean;
  className?: string;
  kind?: 'span' | 'div';
};

export default function Placeholder({
  children,
  loading,
  placeholder = 'Cargando...',
  dataTest = 'placeholder',
  replace = false,
  className,
  kind: Tag = 'span',
}: Props) {
  return (
    <Tag
      className={classes(loading && styles.wrapper, className)}
      data-test={dataTest}
    >
      {(children || children === 0) && (!replace || !loading)
        ? children
        : placeholder}
    </Tag>
  );
}
