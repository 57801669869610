import {
  ChangeEvent,
  Children,
  cloneElement,
  FC,
  Fragment,
  isValidElement,
  MouseEvent,
  PropsWithChildren,
} from 'react';
import { globalHistory } from '@reach/router';
import { NESTED_COMPONENT, NOT_TRACKED_CONTENT } from '../constants';
import useTracking from '../hooks/useTracking';

export type EventType = 'click' | 'change';

export interface ITrackedProps {
  contentOverride?: string;
  description?: string;
  events?: EventType[];
  isContentTracked?: boolean;
}

const Tracked: FC<PropsWithChildren<ITrackedProps>> = ({
  children,
  events = ['click'],
  description,
  contentOverride,
  isContentTracked = true,
}) => {
  const { handleTrack } = useTracking();

  return (
    <Fragment>
      {Children.map(children, (c) => {
        if (!isValidElement(c)) {
          return null;
        }

        return cloneElement(c, {
          // @ts-ignore
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            c.props.onChange?.(e);

            if (events.includes('change')) {
              handleTrack({
                description,
                checkboxChecked:
                  e.nativeEvent.type !== 'input' ? e.target.checked : undefined,
                eventType: e.nativeEvent.type,
                inputPlaceholder: c.props.placeholder,
                value: isContentTracked
                  ? contentOverride ?? e.target.value
                  : NOT_TRACKED_CONTENT,
              });
            }
          },
          onClick: (e: MouseEvent<Element>) => {
            c.props.onClick?.(e);

            // Wrapper prevents Link working, so this is a workaround
            if (c.props.to) {
              globalHistory.navigate(c.props.to);
            }

            if (events.includes('click')) {
              const content =
                typeof c.props.children === 'string'
                  ? c.props.children
                  : NESTED_COMPONENT;

              handleTrack({
                content: isContentTracked
                  ? contentOverride ?? content
                  : NOT_TRACKED_CONTENT,
                description,
                eventType: e.nativeEvent.type,
                linkTo: c.props.to,
                inputPlaceholder: c.props.placeholder,
              });
            }
          },
        });
      })}
    </Fragment>
  );
};

export default Tracked;
