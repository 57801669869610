import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { Heading, Paragraph, Placeholder } from '..';
import { memo, useMemo } from 'react';
import { numberToPercent } from '../../utils/translate';
import { useTranslation } from 'react-i18next';
import styles from './DiscountBanner.module.scss';

type Discount = {
  discount: number | undefined;
  dueDate: string | null | undefined;
  minValue: number | undefined;
  maxValue: number | undefined;
};

type Props = {
  discountObject?: Discount | null;
  handleDiscountClose: () => void;
  loading?: boolean | undefined;
  type: 'REPEATED_LOAN' | 'ADDITIONAL_AMOUNT';
  min: string;
  max: string | null;
  date?: string;
  isCarryOverDiscount?: boolean;
};

export default memo(function PenaltyNotification({
  discountObject,
  handleDiscountClose,
  loading,
  type,
  min,
  max,
  date,
  isCarryOverDiscount = false,
}: Props) {
  const { t } = useTranslation('discount');

  const discountText1 = useMemo(() => {
    if (isCarryOverDiscount) {
      return t('text_carry_over', {
        discount: numberToPercent(discountObject?.discount),
      });
    } else if (type === 'REPEATED_LOAN') {
      return t('text_repeated_loan', {
        discount: numberToPercent(discountObject?.discount),
      });
    } else if (type === 'ADDITIONAL_AMOUNT') {
      return t('text_additional_amount', {
        discount: numberToPercent(discountObject?.discount),
      });
    }
  }, [discountObject?.discount, isCarryOverDiscount, type]);

  const discountText2 = useMemo(() => {
    if (isCarryOverDiscount) {
      return t('validity_carry_over');
    } else if (type === 'REPEATED_LOAN') {
      return t('validity_repeated_loan', {
        date: date,
        min: min,
        max: max,
      });
    } else if (type === 'ADDITIONAL_AMOUNT') {
      return t('validity_additional_amount', {
        date: date,
        min: min,
        max: max,
      });
    }
  }, [date, isCarryOverDiscount, max, min, type]);

  return (
    <div className={styles['discount-banner']} data-test="discount-banner">
      <div className={styles['icon-wrapper']}>
        <ArrowDown className={styles.icon} data-test="discount-banner-arrow" />
        <Placeholder
          dataTest="discount-value"
          loading={loading}
          data-test="discount-banner-percent"
        >
          {numberToPercent(discountObject?.discount)}
        </Placeholder>
      </div>
      <div className={styles['text-wrapper']}>
        <Heading className={styles.heading}>
          <Placeholder loading={loading} dataTest="discount-banner-heading">
            {t('heading')}
          </Placeholder>
        </Heading>

        <Paragraph type="small">
          <Placeholder loading={loading} dataTest="discount-banner-text-1">
            {discountText1}
          </Placeholder>
        </Paragraph>

        <Paragraph type="small">
          <Placeholder loading={loading} dataTest="discount-banner-text-2">
            {discountText2}
          </Placeholder>
        </Paragraph>
      </div>
      <button
        type="button"
        className={styles.close}
        data-test="discount-banner-button"
        onClick={handleDiscountClose}
      >
        <Close />
      </button>
    </div>
  );
});
