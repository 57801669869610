import { datadogLogs } from '@datadog/browser-logs';
import { useEffect } from 'react';
import { ValidationError } from '../../utils';
import fetchData from '../../utils/fetchData';

interface JuicyInstance {
  getSessionId: () => string;
  getCompletedSessionId: (timeout: number) => string;
}

declare global {
  interface Window {
    juicyScoreApi: JuicyInstance;
  }
}

export const JUICY_SCRIPT = process.env.REACT_APP_JUICY_URL || '';

export const useJuicy = () => {
  useEffect(() => {
    const isUserLogged = Boolean(sessionStorage.getItem('token')); // wait the first one/two screens to have everything loaded

    if (!window.juicyScoreApi && isUserLogged) {
      try {
        const juicyScript = document.createElement('script');
        juicyScript.src = JUICY_SCRIPT;
        juicyScript.async = true;
        juicyScript.id = 'juicyScript';
        // @ts-ignore - Property only valid for chrome or edge browser
        juicyScript.fetchpriority = 'high';
        document.head.appendChild(juicyScript);
      } catch {}
    }
  }, []);

  const generateJuicyApplicationSession = async () => {
    try {
      let juicySessionId;

      const fetchedApplication = await fetchData('/client/application', {
        headers: {
          Accept: 'application/vnd.4finance.web.v1.hal+json',
        },
      });

      // Full method to get the completed session id
      try {
        juicySessionId = await window.juicyScoreApi.getSessionId(); // start raw data collection
        const juicyCompleteSessionId =
          await window.juicyScoreApi.getCompletedSessionId(10000); // getting the completed session id
        if (juicyCompleteSessionId) juicySessionId = juicyCompleteSessionId;
      } catch (error) {
        // TODO: Remove "any" after checking logs on Prod to know why juicy script fails and which type of error returns
        if (!juicySessionId) {
          datadogLogs.logger.error(
            `juicy failed - applicationId: ${
              fetchedApplication.id
            } - sessionId - error message: ${
              (error as ValidationError)?.message
            }`,
            {},
          );
        } else {
          datadogLogs.logger.error(
            `juicy failed - applicationId: ${
              fetchedApplication.id
            } - completedSessionId - error message: ${
              (error as ValidationError)?.message
            }`,
            {},
          );
        }
      }

      if (fetchedApplication.id) {
        await fetch(`${process.env.REACT_APP_ANALYTICS_API}/juicyscore`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            application_id: fetchedApplication.id,
            session_id: juicySessionId || 'No session id',
          }),
        });
      }
    } catch {
      // Catch juicy error to hide any possible error
    }
  };

  return {
    generateJuicyApplicationSession,
  };
};
