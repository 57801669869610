import * as R from '../../../Router/routes';
import { ReactComponent as EnvelopeIcon } from '../../../assets/icons/envelope.svg';
import { Link, Paragraph } from '../..';
import { memo } from 'react';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg';
import { useActiveLoan } from '../../../pages/ActiveLoan/useActiveLoan';
import { useLatestLoan } from '../../../hooks/webapi';
import { useTranslation } from 'react-i18next';
import classes from 'classnames';
import getPhone from '../../../utils/getPhone';
import styles from './FooterDefault.module.scss';

export type Props = {
  className?: string;
  dataTest?: string;
};

export default memo(function FooterDefault({ className, dataTest }: Props) {
  const { t } = useTranslation('footer');

  const { isOverdue } = useActiveLoan();
  const { latestLoan } = useLatestLoan();

  const { phone, phoneLink } = getPhone(isOverdue);

  const showPaymentLink =
    Boolean(latestLoan?.productCode === 'CONS') &&
    window.location.pathname === R.REPAYMENT;

  return (
    <footer data-test={dataTest} className={classes(styles.footer, className)}>
      <div className={styles['first-container']}>
        <div className={styles.contacts}>
          <div className={styles.contact}>
            <EnvelopeIcon
              className={styles.envelope}
              data-test={`${dataTest}-envelope-icon`}
            />
            <a
              data-test="footer-mail"
              href={`mailto:${t('common:email')}`}
              className={styles.link}
              type="small"
            >
              {t('common:email')}
            </a>
          </div>
          <div className={styles.contact}>
            <PhoneIcon
              className={styles.phone}
              data-test={`${dataTest}-phone-icon`}
            />
            <a
              data-test="footer-number"
              href={`tel:${phoneLink}`}
              className={styles.link}
              type="small"
            >
              {phone}
            </a>
          </div>
        </div>
        <Paragraph
          className={styles['work-hours']}
          type="small"
          data-test={`${dataTest}-work-hours`}
        >
          {t('work_hours_weekdays')}
          <br />
          {t('work_hours_weekends')}
        </Paragraph>
      </div>
      <div
        className={classes([styles['second-container'], styles['helper-text']])}
      >
        {showPaymentLink && (
          <>
            <p
              className={classes([
                styles['helper-text'],
                styles['helper-text-link'],
              ])}
              data-test={`${dataTest}-helper-link-wrapper`}
            >
              <Link to={R.FULL_REPAYMENT} dataTest="full-repayment">
                {t('payFullLoan')}
              </Link>
            </p>
            <span className={styles['helper-separator']}>|</span>
          </>
        )}
        <p
          className={classes([
            styles['helper-text'],
            styles['helper-text-link'],
          ])}
          dangerouslySetInnerHTML={{
            __html: t('terms_and_conditions'),
          }}
          data-test={`${dataTest}-helper-terms-conditions`}
        />
      </div>
    </footer>
  );
});
