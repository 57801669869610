/** Layout for dashboard type pages with sidebar for navigation */
import { ReactComponent as ErrorIcon } from '../../assets/icons/error-triangle-rounded.svg';
import { getOverdueType } from '../../utils/overdue';
import { memo } from 'react';
import { useActiveLoan } from '../../pages/ActiveLoan/useActiveLoan';
import { useLatestLoan } from '../../hooks/webapi';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning-triangle-rounded.svg';
import classes from 'classnames';
import styles from './OverdueBanner.module.scss';

export default memo(function OverdueBanner() {
  const { t } = useTranslation('dashboard');
  const { latestLoan } = useLatestLoan();
  const { isOverdue, isBrokenAgreement } = useActiveLoan();

  const overdueType = getOverdueType(latestLoan);

  if (!isOverdue) return <></>;

  const isCons7 =
    latestLoan?.productCode === 'CONS' && latestLoan?.productNumber === '7';

  const overdueText =
    isCons7 && !isBrokenAgreement ? t('overdue7Banner') : t('overdueBanner');

  return (
    <div
      className={classes(
        styles['overdue-banner'],
        styles[`overdue-banner-${getOverdueType(latestLoan)}`],
      )}
      data-test="overdue-banner"
    >
      {overdueType === 'warning' ? (
        <WarningIcon
          className={styles['overdue-banner-icon']}
          width="23px"
          data-test="overdue-banner-warning-icon"
        />
      ) : (
        <ErrorIcon
          className={styles['overdue-banner-icon']}
          width="23px"
          data-test="overdue-banner-error-icon"
        />
      )}
      {overdueText}
      <span
        className={classes(
          styles['overdue-banner-days'],
          styles[`overdue-${overdueType}-banner-days`],
        )}
        data-test="overdue-banner-days"
      >
        {latestLoan?.overDueDays}{' '}
        {t('common:days', {
          count: latestLoan?.overDueDays || 0,
        })}
      </span>
    </div>
  );
});
