import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import fetchData from '../../utils/fetchData';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

export type Banks = {
  templates: [
    {
      clientIdentificationNumber: string;
      clientPersonalId: string;
      bankName: string;
      registrationNumber: string;
      accountNumber: string;
      bic: string;
      homePageUrl: string;
      branchPageUrl: string;
      receiver: string;
      masterAccount: boolean;
      shortName: string;
    },
  ];
};

export type Bank_status =
  | 'IN_PROGRESS'
  | 'SUCCESS'
  | 'SUCCESS_SAME_IBAN_SELECTED'
  | 'ACCOUNT_NOT_VALID'
  | 'ERROR'
  | undefined;

type BanksContextType = {
  banks?: Banks;
  fetchBanks: () => Promise<Banks | void>;
  fetchTinkBankStatus: () => Promise<Bank_status | void>;
  tinkBankStatus: Bank_status;
  setTinkBankStatus: (status: Bank_status) => void;
};

const BanksContext = createContext<BanksContextType>({} as BanksContextType);

const useBanks = (): BanksContextType => useContext(BanksContext);

const BanksProvider = ({ children }: Props): JSX.Element => {
  const [banks, setBanks] = useState<Banks>();
  const [tinkBankStatus, setTinkBankStatus] = useState<Bank_status>();
  const { showError } = useError();

  const fetchBanks = useCallback(async (): Promise<Banks | void> => {
    try {
      const fetchedBanks = await fetchData('/client/banks');

      setBanks(fetchedBanks);

      return fetchedBanks;
    } catch (e) {
      showError();
      throw e;
    }
  }, [showError]);

  const fetchTinkBankStatus =
    useCallback(async (): Promise<Bank_status | void> => {
      try {
        const { status: nextTinkBankStatus } = await fetchData(
          '/client/tink/bank-update-status',
        );

        setTinkBankStatus(nextTinkBankStatus);

        return nextTinkBankStatus;
      } catch (e) {
        showError();
        throw e;
      }
    }, [showError]);

  const banksContextValue = useMemo(
    () => ({
      banks,
      tinkBankStatus,
      fetchBanks,
      fetchTinkBankStatus,
      setTinkBankStatus,
    }),
    [banks, fetchBanks, fetchTinkBankStatus, setTinkBankStatus, tinkBankStatus],
  );

  return (
    <BanksContext.Provider value={banksContextValue}>
      {children}
    </BanksContext.Provider>
  );
};

export { useBanks as default, BanksProvider };
