import { isNil } from 'lodash';
import { TargetData, TrackOptions } from '../plugins/types';
import { useAnalytics } from 'use-analytics';
import { useCallback, useMemo } from 'react';
import { useLocation } from '@reach/router';

interface IData {
  content?: string;
  checkboxChecked?: boolean;
  description?: string;
  eventType: string;
  inputPlaceholder?: string;
  linkTo?: string;
  value?: string | number | Record<string, string | number>;
}

const useTracking = () => {
  const { track } = useAnalytics();
  const location = useLocation();

  const handleTrack = useCallback(
    ({
      description,
      content,
      checkboxChecked,
      linkTo,
      inputPlaceholder,
      eventType,
      value,
    }: IData) => {
      const { href, origin, pathname, search } = location;

      const options: TrackOptions = {
        location: {
          href,
          origin,
          pathname,
          search,
        },
      };

      const data: TargetData = {
        ...(!isNil(content) && { content }),
        ...(!isNil(checkboxChecked) && { checkboxChecked }),
        ...(!isNil(description) && { description }),
        ...(!isNil(inputPlaceholder) && { inputPlaceholder }),
        ...(!isNil(linkTo) && { linkTo }),
        ...(!isNil(value) && { value }),
      };

      track(eventType, data, options);
    },
    [location, track],
  );

  return useMemo(
    () => ({
      handleTrack,
    }),
    [handleTrack],
  );
};

export default useTracking;
