/** Layout for flow type pages */

import { DesktopHeader, Footer, MobileHeader } from '../../../components';
import { memo, ReactNode, useCallback, useState } from 'react';
import { useAuth } from '../../../hooks/webapi';
import classes from 'classnames';
import Notifications from '../Notifications/Notifications';
import OverdueBanner from '../../../components/OverdueBanner/OverdueBanner';
import styles from './Flow.module.scss';
import useMenuItems from '../../../hooks/useMenuItems';

type Props = {
  children: ReactNode;
  backUrl?: string;
  backUrlDisabled?: boolean;
  withLogout?: boolean;
  mobileHeading?: string;
  className?: string;
  dataTest?: string;
  withMenu?: boolean;
  withMenuDisabled?: boolean;
  logoLinkUrl?: string;
  withFooter?: boolean;
};

export default memo(function Flow({
  children,
  backUrl,
  backUrlDisabled,
  withLogout,
  mobileHeading,
  className,
  dataTest,
  withMenu = true,
  withMenuDisabled = false,
  logoLinkUrl,
  withFooter = true,
}: Props) {
  const { logout } = useAuth();
  const menuItems = useMenuItems({
    shouldFetchMenu: withMenu,
    menuDisabled: withMenuDisabled,
  });
  const [pending, setPending] = useState(false);

  const handleLogout = useCallback(async () => {
    try {
      setPending(true);
      await logout();
    } catch {
      setPending(false);
    }
  }, [logout]);

  return (
    <div className={styles.container} data-test={dataTest}>
      <OverdueBanner />
      <div className={styles.app}>
        <DesktopHeader
          className={styles['desktop-header']}
          backUrl={backUrl}
          backUrlDisabled={backUrlDisabled}
          onLogout={handleLogout}
          withLogout={withLogout}
          pending={pending}
          logoLinkUrl={logoLinkUrl}
        />

        <MobileHeader
          className={styles['mobile-header']}
          backUrl={backUrl}
          backUrlDisabled={backUrlDisabled}
          menuItems={menuItems}
          mobileHeading={mobileHeading}
          onLogout={handleLogout}
          pending={pending}
          withMenu={withMenu}
        />

        <main className={classes(styles.main, className)}>
          <Notifications className={styles.notifications} />
          {children}
        </main>
        {withFooter && <Footer className={styles.footer} />}
      </div>
    </div>
  );
});
