import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import fetchData from '../../utils/fetchData';
import isLogged from 'utils/isLogged';
import pMemoize from 'p-memoize';
import useError from '../useError';
import ValidationError from '../../utils/ValidationError';

type Props = {
  children: ReactNode;
};

type DR_INSTALLMENT = {
  amount: number;
  dueDate: string;
  startDate: string;
  status: 'DELAYED' | 'PAID' | 'CANCELLED' | 'WRITTEN_OFF';
};

type LoanDR = {
  expirationDate: string;
  installments: DR_INSTALLMENT[];
};

export type ClientDebtRestructuringData = {
  status:
    | 'ELIGIBLE'
    | 'ELIGIBLE_INITIATED'
    | 'IN_PROCESS'
    | 'IN_PROCESS_SIGNED'
    | 'IN_PROCESS_PAID'
    | 'ACTIVE'
    | 'ACTIVE_KEPT'
    | 'NOT_ELIGIBLE';
  isPaymentReceived: boolean;
  isSigned: boolean;
  RepaymentProposalInfo: any;
  commissionAmount: number;
  latestRepaymentProposal: LoanDR;
};

type ClientDebtRestructuringContextType = {
  debtRestructuringData?: ClientDebtRestructuringData | undefined | null;
  fetchDebtRestructuringData: () => Promise<ClientDebtRestructuringData | void>;
  noActionNeeded: boolean;
  isSigned: boolean;
  isPaymentReceived: boolean;
  isDebtRestructuring: boolean;
};

const ClientDebtRestructuringContext =
  createContext<ClientDebtRestructuringContextType>(
    {} as ClientDebtRestructuringContextType,
  );

const memoFetchData = pMemoize(fetchData, { maxAge: 1000 });

const useClientDebtRestructuring = (): ClientDebtRestructuringContextType =>
  useContext(ClientDebtRestructuringContext);

const ClientDebtRestructuringProvider = ({ children }: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [debtRestructuringData, setDebtRestructuringData] = useState<
    ClientDebtRestructuringData | undefined | null
  >();
  const { showError } = useError();

  const isSigned =
    debtRestructuringData?.isSigned ||
    debtRestructuringData?.status === 'IN_PROCESS_SIGNED';

  const isPaymentReceived =
    debtRestructuringData?.isPaymentReceived ||
    debtRestructuringData?.status === 'IN_PROCESS_PAID';

  const noActionNeeded =
    isPaymentReceived &&
    isSigned &&
    (debtRestructuringData?.status || '').includes('ACTIVE');

  useEffect(() => {
    if (debtRestructuringData === undefined && !loading && isLogged()) {
      fetchDebtRestructuringData();
    }
  }, [debtRestructuringData, loading]);

  const fetchDebtRestructuringData =
    useCallback(async (): Promise<ClientDebtRestructuringData | void> => {
      try {
        setLoading(true);
        const fetchedClientDebtRestructuring = await memoFetchData(
          '/client/loans/latest/debt-restructuring',
        );
        setDebtRestructuringData(fetchedClientDebtRestructuring);
        return fetchedClientDebtRestructuring;
      } catch (e) {
        setDebtRestructuringData(null);
        if ((e as ValidationError)?.message !== '404') {
          showError(e as ValidationError);
        }
      } finally {
        setLoading(false);
      }
    }, [showError]);

  const isDebtRestructuring =
    Boolean(debtRestructuringData) &&
    debtRestructuringData?.status !== 'NOT_ELIGIBLE';

  const ClientDebtRestructuringContextValue = useMemo(
    () => ({
      debtRestructuringData,
      fetchDebtRestructuringData,
      noActionNeeded,
      isSigned,
      isPaymentReceived,
      isDebtRestructuring,
    }),
    [
      debtRestructuringData,
      fetchDebtRestructuringData,
      noActionNeeded,
      isSigned,
      isPaymentReceived,
      isDebtRestructuring,
    ],
  );

  return (
    <ClientDebtRestructuringContext.Provider
      value={ClientDebtRestructuringContextValue}
    >
      {children}
    </ClientDebtRestructuringContext.Provider>
  );
};

export {
  useClientDebtRestructuring as default,
  ClientDebtRestructuringProvider,
};
