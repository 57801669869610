import { datadogLogs } from '@datadog/browser-logs';
import { getProcessEnvUrl } from './getUrl';
import { getShiftedDatetimeHeader } from './shiftedDateTime';
import {
  LOGIN,
  ONLINE_LOGIN,
  PASSWORD_RECOVERY,
  PASSWORD_RECOVERY_SUCCESS,
} from '../Router/routes';
import isLogged from 'utils/isLogged';
import ValidationError, { FieldError } from './ValidationError';

// Routes excluded because they manage the 401 error (custom messages and other behaviours)
export const ROUTES_EXCLUDED_401_VALIDATION = [
  LOGIN,
  ONLINE_LOGIN,
  PASSWORD_RECOVERY,
  PASSWORD_RECOVERY_SUCCESS,
];

type Options = {
  [key: string]: any;
};

export const fetchRetry = require('fetch-retry')(fetch);

export const defaultOptions = {
  retryOn: [503, 500],
  retries: (window as any).Cypress ? 0 : 3,
  retryDelay: 1500,
};

type CustomOptionsType = {
  retryOn?: number[];
  retries?: number;
  retryDelay?: number;
  headers: RequestInit;
};

async function fetchData(
  path: string,
  customOptions: RequestInit | CustomOptionsType = {},
  /**
    don't log 404 status for some endpoints
    as BE return this status for non active state and it will clog logging data
   */
  ignoreNotFoundLogger?: boolean,
  ignoreDefaultHeaders?: boolean,
) {
  const token = isLogged() && {
    Authorization: `Basic ${sessionStorage.getItem('token')}`,
  };

  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...token,
  };

  const headersDefault = ignoreDefaultHeaders ? token : defaultHeaders;

  const options: Options = {
    ...defaultOptions,
    ...customOptions,
    headers: {
      ...headersDefault,
      ...getShiftedDatetimeHeader(),
      ...customOptions.headers,
    },
  };

  const url = `${getProcessEnvUrl('WEB_API')}${path}`;
  const response = await fetchRetry(url, options);

  if (
    (response.status === 401 || response.status === 403) &&
    (window as any).Cypress
  ) {
    // Return undefined during cypress tests when a request hasn't been intercepted in tests
    return undefined;
  }

  if (!response.ok && !(response.status === 404 && ignoreNotFoundLogger)) {
    try {
      const data = await response.clone().json();

      datadogLogs.logger.error(`request: ${path}`, {
        request_url: url,
        error: data,
      });
    } catch (exception) {
      datadogLogs.logger.error(`request: ${path} response parse`, {
        request_url: url,
        error: exception,
      });
    }
  }

  const isRouteExcluded401 = ROUTES_EXCLUDED_401_VALIDATION.includes(
    window.location.pathname,
  );

  // 403 status code gets returned when client has been blocked via COLA
  if (
    (response.status === 401 || response.status === 403) &&
    !isRouteExcluded401
  ) {
    const errorMessage =
      response.status === 401
        ? 'session_expired'
        : 'validation.login_unavailable';

    const queryString = window.location.search
      ? `${window.location.search}&errorMessage=${errorMessage}`
      : `?errorMessage=${errorMessage}`;
    sessionStorage.removeItem('token');
    window.location.replace(`${LOGIN}${queryString}`);
    return;
  }

  if (response.status === 400) {
    const { errors }: { errors: FieldError[] } = await response.json();
    throw new ValidationError(errors);
  }

  if (!response.ok) {
    throw new Error(response.status.toString());
  }

  try {
    let data;
    if (options.headers['Content-Type'] === 'text/html') {
      data = await response.text();
    } else if (options.headers['Content-Type'] === 'application/pdf') {
      data = await response.blob();
    } else {
      data = await response.json();
    }
    return data;
  } catch {
    // If response doesn't have content return undefined;
    return;
  }
}

export default fetchData;
