import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import fetchData from '../../utils/fetchData';
import useError from '../useError';

type Props = {
  children: ReactNode;
};

export type ClientOffer = {
  term: number;
  monthlyPayment: number;
  totalPrincipal: number;
  totalInterest: number;
  totalRepayableAmount: number;
  agreementEndDate: string;
  newPrincipal: number;
  newAmountWithInterestAndInitialCommission: number;
  newPrincipalWithAllCapitalizableFees: number;
  newAmountInterest: number; // Used for additional amount
  newInterest: number; // Used for additional amount
  newInterestBeforeDiscount: number;
  discountPercent: number;
  discounts: Array<{
    expirationDate: string;
  }>;
  preliminarySchedule?: Array<{
    commissionAmount: number;
    discountAmount: number;
    dueDate: string;
    initialCommissionAmount: number;
    initialCommissionDiscountAmount: number;
    interestAmount: number;
    interestDiscountAmount: number;
    invoicedCommissionAmount: number;
    invoicedInitialCommissionAmount: number;
    invoicedInterestAmount: number;
    invoicedPrincipalAmount: number;
    number: number;
    penaltyAmount: number;
    principalAmount: number;
    remainingPrincipal: number;
    totalAmount: number;
    totalDiscountAmount: number;
  }>;
};

type ClientOfferContextType = {
  clientOffers?: ClientOffer[];
  fetchClientOffer: (
    productNumber: ProductNumber | undefined,
    amount: number,
    term: number,
  ) => Promise<ClientOffer | void>;
  fetchMultipleTermClientOffers: (
    productNumber: ProductNumber,
    amount: number,
    terms: number[],
  ) => Promise<ClientOffer[] | void>;
  setClientOffers: React.Dispatch<
    React.SetStateAction<ClientOffer[] | undefined>
  >;
};

const ClientOfferContext = createContext<ClientOfferContextType>(
  {} as ClientOfferContextType,
);

const useClientOffer = (): ClientOfferContextType =>
  useContext(ClientOfferContext);

const ClientOfferProvider = ({ children }: Props): JSX.Element => {
  const [clientOffers, setClientOffers] = useState<ClientOffer[]>();
  const { showError } = useError();

  const fetchClientOffer = useCallback(
    async (
      productNumber: ProductNumber | undefined,
      amount: number,
      term: number,
    ): Promise<ClientOffer | void> => {
      try {
        const fetchedClientOffer = await fetchData(
          productNumber
            ? `/client/application/offer/product-number/${productNumber}?amount=${amount}&term=${term}`
            : `/client/application/offer?amount=${amount}&term=${term}`,
          {
            headers: {
              Accept: 'application/json',
            },
          },
        );

        setClientOffers([fetchedClientOffer]);

        return fetchedClientOffer;
      } catch (e) {
        showError();
        throw e;
      }
    },
    [showError],
  );

  /** Fetch offers for multiple terms. Handy for CONS calculator */
  const fetchMultipleTermClientOffers = useCallback(
    async (productNumber: ProductNumber, amount: number, terms: number[]) => {
      try {
        const clientOffers = await Promise.all(
          terms.map((term) =>
            fetchData(
              `/client/application/offer/product-number/${productNumber}?amount=${amount}&term=${term}`,
            ),
          ),
        );

        setClientOffers(clientOffers);

        return clientOffers;
      } catch (e) {
        showError();
        throw e;
      }
    },
    [showError],
  );

  const clientOfferContextValue = useMemo(
    () => ({
      clientOffers,
      fetchClientOffer,
      fetchMultipleTermClientOffers,
      setClientOffers,
    }),
    [
      clientOffers,
      fetchClientOffer,
      fetchMultipleTermClientOffers,
      setClientOffers,
    ],
  );

  return (
    <ClientOfferContext.Provider value={clientOfferContextValue}>
      {children}
    </ClientOfferContext.Provider>
  );
};

export { useClientOffer as default, ClientOfferProvider };
