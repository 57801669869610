import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import fetchData from '../../utils/fetchData';

type Props = {
  children: ReactNode;
};

export type PaymentPreferences = {
  autoRepay: boolean;
};

type ActiveCardContextType = {
  paymentPreferences: PaymentPreferences | undefined;
  fetchPaymentPreferences: () => Promise<PaymentPreferences | void>;
  fetchingPaymentPreferences: boolean;
  updateAutorepay: (autoRepay: boolean) => Promise<PaymentPreferences | void>;
};

const ActiveCardContext = createContext<ActiveCardContextType>(
  {} as ActiveCardContextType,
);

const usePaymentPreferences = (): ActiveCardContextType =>
  useContext(ActiveCardContext);

const PaymentPreferencesProvider = ({ children }: Props): JSX.Element => {
  const [paymentPreferences, setPaymentPreferences] = useState<
    PaymentPreferences | undefined
  >();
  const [fetchingPaymentPreferences, setFetchingPreferences] =
    useState<boolean>(true);

  const fetchPaymentPreferences =
    useCallback(async (): Promise<PaymentPreferences | void> => {
      try {
        setFetchingPreferences(true);
        const fetchedPaymentsPreferences = await fetchData(
          '/client/payment-preferences',
          {},
          true,
        );
        setPaymentPreferences(fetchedPaymentsPreferences);
        return fetchedPaymentsPreferences;
      } catch (e) {
      } finally {
        setFetchingPreferences(false);
      }
    }, []);

  const updateAutorepay = useCallback(
    async (autoRepay: boolean): Promise<PaymentPreferences | void> => {
      try {
        setFetchingPreferences(true);
        const fetchedPaymentsPreferences = await fetchData(
          '/client/payment-preferences/auto-repay',
          {
            method: 'put',
            body: JSON.stringify({ autoRepay }),
          },
          true,
        );
        setPaymentPreferences(fetchedPaymentsPreferences);
        return fetchedPaymentsPreferences;
      } catch (e) {
      } finally {
        setFetchingPreferences(false);
      }
    },
    [],
  );

  const activeCardContextValue = useMemo(
    () => ({
      paymentPreferences,
      fetchPaymentPreferences,
      fetchingPaymentPreferences,
      updateAutorepay,
    }),
    [
      paymentPreferences,
      fetchingPaymentPreferences,
      fetchPaymentPreferences,
      updateAutorepay,
    ],
  );

  return (
    <ActiveCardContext.Provider value={activeCardContextValue}>
      {children}
    </ActiveCardContext.Provider>
  );
};

export { usePaymentPreferences as default, PaymentPreferencesProvider };
