import * as R from '../Router/routes';
import {
  PHONE,
  PHONE_LINK,
  PHONE_ONLINE,
  PHONE_ONLINE_LINK,
  PHONE_OVERDUE,
  PHONE_OVERDUE_LINK,
} from './constants';

export default function getPhone(isOverdue: boolean): {
  phone: string;
  phoneLink: string;
} {
  if (window.location.pathname === R.ONLINE_LOGIN)
    return { phone: PHONE_ONLINE, phoneLink: PHONE_ONLINE_LINK };
  if (isOverdue) {
    return { phone: PHONE_OVERDUE, phoneLink: PHONE_OVERDUE_LINK };
  }
  return { phone: PHONE, phoneLink: PHONE_LINK };
}
