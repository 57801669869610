import { fetchData } from '../../utils';
import { useCallback, useMemo } from 'react';

export interface GetClientApplicationCheckStatusResponse {
  completed: boolean;
}

const useClientApplicationCheckStatus = () => {
  const getClientApplicationCheckStatus = useCallback(async () => {
    try {
      const data: GetClientApplicationCheckStatusResponse = await fetchData(
        '/client/application/check-status',
      );
      return data;
    } catch (e) {
      throw e;
    }
  }, []);

  return useMemo(
    () => ({
      getClientApplicationCheckStatus,
    }),
    [getClientApplicationCheckStatus],
  );
};

export default useClientApplicationCheckStatus;
