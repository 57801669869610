import { Base64 } from 'js-base64';
import { fetchData } from '../../utils';
import { useCallback, useMemo } from 'react';

type CreateTemporaryPasswordPayload = {
  email: string;
  returnUrl: string;
};

const usePasswordRecovery = () => {
  /** Sends a temporary password to client's email */
  const createTemporaryPassword = useCallback(
    async (data: CreateTemporaryPasswordPayload) => {
      try {
        const returnUrlEncoded = encodeURIComponent(data.returnUrl);
        const emailEncoded = Base64.encode(data.email);
        await fetchData(
          `/client/password/recovery/email/${emailEncoded}?returnUrl=${returnUrlEncoded}`,
          {
            method: 'GET',
          },
        );
      } catch (e) {
        throw e;
      }
    },
    [],
  );

  return useMemo(
    () => ({
      createTemporaryPassword,
    }),
    [createTemporaryPassword],
  );
};

export default usePasswordRecovery;
