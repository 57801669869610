import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { memo, useCallback, useState } from 'react';
import Button from '../Button/Button';
import classes from 'classnames';
import copy from 'copy-to-clipboard';
import delay from '../../utils/delay';
import Placeholder from '../Placeholder/Placeholder';
import styles from './CopyValue.module.scss';

export type Props = {
  label: string;
  value: string | undefined;
  copyValue: string | undefined;
  copyLabel: string;
  className?: string;
  labelMessageClassName?: string;
  buttonClassName?: string;
  dataTest?: string;
  labelMsg?: string;
};

export default memo(function CopyValue({
  label,
  value,
  copyLabel,
  className,
  dataTest = 'copyValue',
  labelMsg,
  copyValue,
  labelMessageClassName,
  buttonClassName,
}: Props) {
  const [copied, setCopied] = useState(false);

  const handleClick = useCallback(async () => {
    if (copyValue) {
      try {
        setCopied(true);
        copy(copyValue);
      } catch {
      } finally {
        await delay(2000);
        setCopied(false);
      }
    }
  }, [copyValue]);

  return (
    <div className={classes(styles.wrapper, className)} data-test={dataTest}>
      <div className={styles.info}>
        <div
          className={classes(
            styles.label,
            labelMsg && styles['label-with-msg'],
          )}
          data-test={`${dataTest}-label`}
        >
          {label}
          {labelMsg && (
            <span className={classes(styles.msg, labelMessageClassName)}>
              {labelMsg}
            </span>
          )}
        </div>
        <div className={styles.value}>
          <Placeholder loading={!value} dataTest={`${dataTest}-value`}>
            {value}
          </Placeholder>
        </div>
      </div>
      <Button
        dataTest={`${dataTest}-button`}
        disabled={!value && !copyValue}
        className={styles.button}
        buttonClassName={buttonClassName}
        outline={true}
        size="s"
        onClick={handleClick}
      >
        {copied ? (
          <CheckIcon
            className={styles.icon}
            data-test={`${dataTest}-check-icon`}
          />
        ) : (
          copyLabel
        )}
      </Button>
    </div>
  );
});
